import { Checkbox, DirectionalHint, SearchBox, Tooltip, TooltipDelay, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ProductsAPI from '../../../api/productsAPI';
import { Pagination } from 'components/Pagination/Pagination';
import { Table } from 'components/Table/Table';
import { TableAction } from 'components/TableAction/TableAction';
import {
  bgColor,
  lightBlue,
  moreInfoIcon,
  orderStatuses,
  pairedOrderStatuses,
  orderStatuses20
} from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { cropText, getDateFromTimestamp, getStatusById, tableCellWidth } from '../../../functions';
import { useCheckboxFilter, usePaginationAndSearch } from '../../../hooks';
import { TableItemWithTooltip } from '../../Reports/components/TableItemWithTooltip';
import { useAlert } from '../../../hooks';
import { Alert } from 'components/Alert/Alert';
import s from './Orders.module.scss';
import g from 'assets/scss/Main.module.scss';
import clsx from 'clsx';
import infoIcon from '../../../assets/img/Info.svg';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { useTableFeatures, useTableSort } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Продукт',
    width: '0.20',
    // columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Код заказа',
    width: '0.2',
    // columnId: 'suzId',
    sortable: true
  },
  {
    title: 'Дата заказа',
    width: '0.14',
    // columnId: 'creationDate',
    sortable: true
  },
  {
    title: 'Статус заказа',
    width: '0.2',
    // columnId: 'status',
    sortable: true
  },
  {
    title: 'Кол-во КМ',
    width: '0.12',
    // columnId: 'markingCodesQuantity',
    sortable: true
  },
  {
    title: 'Действия',
    width: '0.14',
    sortable: false
  }
];
const ordersTableHeader = [
  {
    title: 'Продукт',
    width: '0.20',
    // columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Код заказа',
    width: '0.25',
    // columnId: 'suzId',
    sortable: true
  },
  {
    title: 'Дата заказа',
    width: '0.14',
    // columnId: 'creationDate',
    sortable: true
  },
  {
    title: 'Статус заказа',
    width: '0.12',
    // columnId: 'status',
    sortable: true
  },
  {
    title: 'Продукт',
    width: '0.24',
    // columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Количество КМ',
    width: '0.12',
    // columnId: 'markingCodesQuantity',
    sortable: true
  },
  {
    title: 'Действия',
    width: '0.13',
    sortable: false
  }
];

const searchBoxStyles = {
  root: { width: '28.75rem', background: bgColor, borderColor: lightBlue }
};

export const Orders = ({ status, statusParamName }) => {
  const { setCrumbs } = useCrumbsContext();
  const { params } = useLocation();
  const [orders, setOrders] = useState([]);
  const [orderInfo, setOrderInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  const orderWidth = status ? tableCellWidth(ordersTableHeader) : tableCellWidth(tableHeader);

  useEffect(() => {
    if (params) {
      setQuery((prev) => {
        return { ...prev, productId: params.id };
      }, 'replace');
    } else {
      if (!status) {
        // setQuery({})
      }
    }
    return async () => {
      // await setQuery({})
    };
  }, []);

  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    quantityOnPage: NumberParam,
    productId: NumberParam,
    orderStatus: StringParam,
    searchValue: StringParam
  });

  const getOrders = async (query) => {
    setLoading(true);
    let res = await ProductsAPI.getOrders(query);
    const { list, quantity, info } = res;
    await setOrders(list);
    await setOrderInfo(info);
    await setTotalItems(quantity);
    await setLoading(false);
  };

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const errorInfoHandler = (msg) => {
    showAlert('Текст ошибки', msg);
  };

  // useEffect(() => {
  //   if (!params) {
  //     if (!status) {
  //       // getBack()
  //     }
  //   } else {
  //     if (params.status && !query.orderStatus) {
  //       setQuery((prev) => ({ ...prev, orderStatus: params.status }), 'replace');
  //     }
  //   }
  // }, [params]);

  useEffect(() => {
    if (query.productId) {
      setCrumbs(['/products', 'Заказы КМ']);
    }
  }, [query]);

  useEffect(() => {
    if (status && !query.orderStatus) {
      setQuery((prev) => ({ ...prev, orderStatus: status }), 'replace');
    }
  }, [status]);

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    pairedOrderStatuses,
    'orderStatus',
    setQuery,
    query
  );
  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getOrders,
      query,
      setQuery,
      status: status || params?.status,
      statusParamName: statusParamName || params?.statusParamName
    });

  const {
    sort: { getSortDirection, toggleColumnSort },
  } = useTableFeatures(
    {
      columns: status ? ordersTableHeader : tableHeader,
      items: orders,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'creationDate', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    toggleColumnSort(event, columnId);
    const direction = currentDirection === 'ascending' ? 1 : -1;

    const sortedOrders = [...orders].sort((a, b) => {
      // Для вложенных свойств
      if (columnId?.includes('.')) {
        const [parent, child] = columnId?.split('.');
        const valueA = a[parent]?.[child] ?? '';
        const valueB = b[parent]?.[child] ?? '';
        return String(valueA).localeCompare(String(valueB)) * direction;
      }

      // Для дат
      if (columnId === 'creationDate') {
        return (new Date(a[columnId] || 0) - new Date(b[columnId] || 0)) * direction;
      }

      // Для чисел
      if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
        return (a[columnId] - b[columnId]) * direction;
      }

      // Для строк
      const valueA = a[columnId] ?? '';
      const valueB = b[columnId] ?? '';
      return String(valueA).localeCompare(String(valueB)) * direction;
    });

    setOrders(sortedOrders);
  };

  return (
    <div style={{ height: '100%' }}>
      <div className={g.titleWrapper} >
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            className={g.search}
            value={query.searchValue}
            placeholder={
              status ? 'Поиск по коду заказа, продукту или gtin' : 'Поиск по коду заказа'
            }
            onClear={() => onSearchTermChanged('')}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={clsx(g.headerFilters)} >
          <ButtonMenu
            title={'Статус'}
            dataArray={pairedOrderStatuses}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            isCheckbox={true}
            optionSize={selectedFilters.size}
          />
        </div>
      </div>
      <div className={g.tableWrapper}>
        <Table
          headerItems={status ? ordersTableHeader : tableHeader}
          loading={loading}
        // onSort={handleSort}
        // sortState={{ getSortDirection }}
        >
          {isAlertShowed && (
            <Alert
              title={alertTitle}
              text={alertText}
              onClose={() => {
                hideAlert();
              }}
            />
          )}
          {status
            ? orders?.map((item) => {
              return (
                <div key={item.id} className={g.tableItem}>
                  <div className={g.tableCell} style={{ width: orderWidth(0) }}>
                    {item.product?.name}
                  </div>
                  <div className={g.tableCell} style={{ width: orderWidth(1) }}>
                    {item.suzId}
                  </div>
                  <div className={g.tableCell} style={{ width: orderWidth(2) }}>
                    {getDateFromTimestamp(item.creationDate)}
                  </div>
                  <div
                    className={g.tableCell}
                    style={{ width: orderWidth(3), alignItems: 'center' }}
                  >
                    {item.errorMessage ? (
                      <span style={{ color: 'red' }}>Обработан с ошибкой</span>
                    ) : (
                      getStatusById(item.status, [
                        ...orderStatuses,
                        ...orderStatuses20,
                        ...pairedOrderStatuses
                      ])
                    )}
                    {item.errorMessage && (
                      <div style={{ display: 'flex', marginLeft: '5px', alignSelf: 'start' }}>
                        <TableAction
                          isButton
                          onClick={() => errorInfoHandler(item.errorMessage)}
                          tooltipText={'Текст ошибки'}
                          icon={infoIcon}
                        />
                      </div>
                    )}
                  </div>
                  <TableItemWithTooltip tooltipValue={item.product?.name}>
                    <div
                      style={{ width: orderWidth(4) }}
                      className={clsx(g.tableRow && g.tableCell)}
                    >
                      {cropText(item.product?.name)}
                    </div>
                  </TableItemWithTooltip>
                  <div className={g.tableCell} style={{ width: orderWidth(5) }}>
                    {item.markingCodesQuantity}
                  </div>
                  <div
                    className={s.tableActionCell}
                    style={{ width: orderWidth(6), display: 'flex', alignItems: 'center' }}
                  >
                    <TableAction
                      link={`/products/codesList`}
                      params={{
                        orderId: item.id,
                        status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                        statusParamName: 'status',
                        prevRoute: '/orders'
                      }}
                      text="Подробнее"
                      icon={infoIcon}
                    />
                  </div>
                </div>
              );
            })
            : orders?.map((item) => {
              return (
                <div key={item.id} className={g.tableItem}>
                  <div className={s.tableCell} style={{ width: orderWidth(0), padding: '0 10px' }}>
                    <TooltipHost
                      content={item.product?.name}
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {item.product?.name}
                      </div>
                    </TooltipHost>
                  </div>

                  <div className={s.tableCell} style={{ width: orderWidth(1) }}>
                    <TooltipHost
                      content={item.suzId}
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {item.suzId}
                      </div>
                    </TooltipHost>
                  </div>
                  <div className={s.tableCell} style={{ width: orderWidth(2) }}>
                    <TooltipHost
                      content={getDateFromTimestamp(item.creationDate)}
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {getDateFromTimestamp(item.creationDate)}
                      </div>
                    </TooltipHost>
                  </div>
                  <div
                    className={s.tableCell}
                    style={{
                      width: orderWidth(3),
                      display: 'flex',
                      alignItems: 'center',
                      gap: '7px'
                    }}
                  >
                    <TooltipHost
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {item.errorMessage
                          ? 'Обработан с ошибкой'
                          : getStatusById(item.status, [
                            ...orderStatuses,
                            ...orderStatuses20,
                            ...pairedOrderStatuses
                          ])}
                      </div>
                    </TooltipHost>
                    {item.errorMessage && (
                      <TableAction
                        isButton
                        onClick={() => errorInfoHandler(item.errorMessage)}
                        tooltipText={'Текст ошибки'}
                        icon={infoIcon}
                      />
                    )}
                  </div>
                  <div className={s.tableCell} style={{ width: orderWidth(4) }}>
                    <TooltipHost
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {item.markingCodesQuantity}
                      </div>
                    </TooltipHost>
                  </div>
                  <div className={s.tableCell} style={{ width: orderWidth(5) }}>
                    <TooltipHost
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <TableAction
                          link={`/products/codesList`}
                          params={{
                            orderId: item.id,
                            status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                            id: params?.id,
                            productName: params?.productName,
                            templateId: params?.templateId,
                            productGtin: params?.productGtin,
                            orderCode: item.suzId,
                            orderStatus: item.status
                          }}
                          text="Подробнее"
                        />
                      </div>
                    </TooltipHost>
                  </div>
                </div>
              );
            })}
        </Table>
        <div className={g.footer}>
          {totalPages > 1 && (
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
