import React from 'react';
import { Field } from 'formik';
import { TextField } from '@fluentui/react';

const Input = (props) => {
  const { label = '', type, name, className, disabled } = props;

  return (
    <Field {...props}>
      {({ field, meta }) => {
        const isError = !!(meta.error && meta.touched);
        return (
          <TextField
            {...field}
            value={field.value || ''}
            className={className}
            label={label}
            name={name}
            autoComplete="off"
            errorMessage={isError && meta.error}
            disabled={disabled}
            onWheel={(e) => e.target.blur()}
          />
        );
      }}
    </Field>
  );
};

export default Input;
