import React from 'react';
import s from './Confirm.module.scss';
import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';

export const SimpleConfirm = ({ onSend, subText, okText }) => {
  const dialogContentProps = {
    type: DialogType.normal,
    subText: subText
  };

  return (
    <div className={s.container}>
      <div className={s.confirm}>
        <Dialog hidden={false} dialogContentProps={dialogContentProps}>
          <DialogFooter>
            <PrimaryButton onClick={onSend} text={okText} />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};
