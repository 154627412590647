// bibs
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, TextField, PrimaryButton, DatePicker, DayOfWeek } from '@fluentui/react';
import { Checkbox } from '@fluentui/react';
import moment from 'moment';
import { DayPickerStrings } from '../../../constants';
import clsx from 'clsx';
import ProductsAPI from '../../../api/productsAPI';
// components
import { Alert } from 'components/Alert/Alert';
import { WaitingScreen } from 'components/WaitingScreen/WaitingScreen';
import { SyncStatus } from 'components/SyncStatus/SyncStatus';

// selectors
import { printersSelector } from 'redux/Printers/PrintersSelectors';

// functions
import { validateField, validateForm } from '../../../functions';

// hooks
import { useAlert, useSendRequest } from '../../../hooks';

// styles
import g from '../../../assets/scss/Main.module.scss';
import s from './PreliminaryPrintForm.module.scss';
import ModalPrint from './ModalPrint';

const productOptions = [
  {
    key: 'DataMatrix',
    text: 'Коды маркировки'
  },
  {
    key: 'GS1128',
    text: 'Коды агрегации'
  }
];

export const PreliminaryPrintForm = (props) => {
  const { id, submitCallback, isProduct = false, gtin, productId, setRemainingMarkCodes } = props;
  const { printers } = useSelector(printersSelector);

  const [form, setForm] = useState({
    id,
    quantity: 1,
    copyQuantity: 1,
    printerName: '',
    startIndex: 1
  });
  const [formErrors, setFormErrors] = useState(new Set([]));
  const [loading, setLoading] = useState(false);
  const [printStatus, setPrintStatus] = useState('');
  const [isDisablePrint, setIsDisablePrint] = useState(true);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [printingInfo, setPrintingInfo] = useState([]);
  const [printParamsExist, setPrintParamsExist] = useState(false);
  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const { afterSending } = useSendRequest(setLoading);
  const [isPrintDateEnbld, setIsPrintDateEnbld] = useState(false);
  const [shouldPrintCurrentDate, setShouldPrintCurrentDate] = useState(false);
  const [shouldPrintCustomDate, setShouldPrintCustomDate] = useState(false);
  const [printDate, setPrintDate] = useState('');

  useEffect(() => {
    if (isPrintDateEnbld && shouldPrintCurrentDate) {
      setPrintDate(new Date().toISOString());
    }
    if (!shouldPrintCurrentDate && !shouldPrintCustomDate) {
      setPrintDate(null);
    }
  }, [isPrintDateEnbld, shouldPrintCurrentDate]);

  const handleDateChange = (e) => {
    if (e === null) setPrintDate('');
    else setPrintDate(e);
  };

  const generateOptionsFromArray = (array) => {
    return [{ key: '', text: 'Не выбрано' }, ...array?.map((item) => ({ key: item, text: item }))];
  };

  const printerOptions = isProduct ? productOptions : generateOptionsFromArray(printers);
  const printerLabel = isProduct ? 'Тип кода' : 'Способ печати';
  const handleChange = (name) => (e, item) => {
    let value = e.target.value || item.key;

    let newErrors = new Set(formErrors);

    if (!validateField(name, value)) {
      newErrors.add(name);
    } else {
      newErrors.delete(name);
    }
    setForm((prev) => ({ ...prev, [name]: value }));
    setFormErrors(newErrors);
  };

  const onSubmit = async () => {
    const onSuccess = () => {
      showAlert('Предварительная печать кодов', 'Печать завершена');
    };

    const isCorrect = validateForm(
      {
        ...form
      },
      formErrors,
      setFormErrors
    );
    if (isCorrect) {
      setLoading(true);
      setPrintStatus('progress');

      const baseUrlFromLS = localStorage.getItem('BASE_URL');
      try {
        if (isProduct) {
          form.date = null;
          if (printDate) {
            form.date = printDate;
          }
        }
        const res = await submitCallback(form);
        const remMarkCodes = await ProductsAPI.getRemainingMarcCodes(productId);
        setRemainingMarkCodes(remMarkCodes);
        afterSending(res, onSuccess);
        setPrintStatus('success');
        if (res?.relativePath) {
          await window.open(
            `${baseUrlFromLS || process.env.REACT_APP_BASE_URL || ''}${res.relativePath}`
          );
        }
      } catch (e) {
        setPrintStatus('error');
      }
      await setLoading(false);
    }
  };

  const getPrintingParameters = async () => {
    if (gtin) {
      setLoading(true);
      const res = await ProductsAPI.getPrintingParameters(gtin);

      if (!res.statusCode) {
        await setPrintingInfo(res);
        await setIsOpenModal(true);
      }
      await setLoading(false);
      return res;
    } else {
      setIsOpenModal(true);
    }
  };
  useEffect(() => {
    if (form?.printerName === 'DataMatrix') {
      setIsDisablePrint(false);
    } else {
      setIsDisablePrint(true);
    }
  }, [form]);

  useEffect(() => {
    const getParams = async () => {
      setLoading(true);

      const res = await ProductsAPI.getPrintingParamsAvailability(gtin);
      await setLoading(false);
      if (res.statusCode === 200) setPrintParamsExist(true);
    };

    if (gtin) getParams();
    return () => setPrintParamsExist(false);
  }, []);

  return (
    <div className={s.preliminaryPrint}>
      {isAlertShowed && <Alert title={alertTitle} text={alertText} onClose={hideAlert} />}
      <h1 className={g.title}>Предварительная печать</h1>
      <div className={s.infoRow}>
        <TextField
          label="Количество кодов"
          errorMessage={formErrors.has('quantity') && 'Обязательное поле'}
          required
          value={form.quantity}
          name={'quantity'}
          type={'number'}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onChange={handleChange('quantity')}
        />
      </div>
      <div className={s.infoRow}>
        <TextField
          label="Количество копий"
          errorMessage={formErrors.has('copyQuantity') && 'Обязательное поле'}
          required
          value={form.copyQuantity}
          name={'copyQuantity'}
          type={'number'}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onChange={handleChange('copyQuantity')}
        />
      </div>
      <div className={s.infoRow}>
        <TextField
          label="Стартовый индекс"
          errorMessage={formErrors.has('startIndex') && 'Обязательное поле'}
          required
          value={form.startIndex}
          name={'startIndex'}
          type={'number'}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          onChange={handleChange('startIndex')}
        />
      </div>
      <div className={clsx([s.infoRow, !!formErrors.has('printerName') ? g.errorMessage : ''])}>
        <Dropdown
          style={{ marginBottom: 25 }}
          label={printerLabel}
          selectedKey={form.printerName}
          options={printerOptions}
          onChange={handleChange('printerName')}
        />
        {!!formErrors.has('printerName') && (
          <span className={g.errorMessageText}>Обязательное поле</span>
        )}
      </div>

      {isProduct && (
        <div className={s.chekboxContainer}>
          <Checkbox
            label="Выводить дату печати"
            onChange={() => {
              if (isPrintDateEnbld) {
                setPrintDate(null);
                setShouldPrintCurrentDate(false);
                setShouldPrintCustomDate(false);
              }
              setIsPrintDateEnbld((p) => !p);
            }}
            checked={isPrintDateEnbld}
          />
          <Checkbox
            disabled={!isPrintDateEnbld || shouldPrintCustomDate}
            label="Печатать текущую дату"
            onChange={() => {
              setShouldPrintCurrentDate((p) => {
                return !p;
              });
            }}
            checked={shouldPrintCurrentDate}
          />
          <Checkbox
            disabled={!isPrintDateEnbld || shouldPrintCurrentDate}
            label="Печатать определенную дату"
            onChange={() => setShouldPrintCustomDate((p) => !p)}
            checked={shouldPrintCustomDate}
          />

          <DatePicker
            placeholder="Выберете дату"
            className={s.date}
            firstDayOfWeek={DayOfWeek.Monday}
            label={'Дата на печать'}
            allowTextInput={true}
            formatDate={(date) => {
              return !date ? null : moment(date).format('DD-MM-YYYY');
            }}
            strings={DayPickerStrings}
            defaultValue={'Выберете дату документа'}
            value={shouldPrintCustomDate ? printDate : ''}
            onSelectDate={handleDateChange}
            disabled={!isPrintDateEnbld || !shouldPrintCustomDate}
          />
        </div>
      )}

      <div style={{ display: 'flex' }}>
        <PrimaryButton
          disabled={loading}
          className={s.preliminaryButton}
          text={'Печать'}
          onClick={onSubmit}
          style={{ flexGrow: 1 }}
        />
      </div>

      {printParamsExist && (
        <PrimaryButton
          disabled={loading}
          className={s.preliminaryButton}
          text="Печать с параметрами"
          onClick={getPrintingParameters}
          style={{ marginTop: 30, width: '100%' }}
        />
      )}
      {printStatus?.includes('progress') && (
        <WaitingScreen
          title={'Выполняется печать'}
          text={
            'Пожалуйста, подождите. Это может занять несколько минут. Не закрывайте эту страницу.'
          }
        />
      )}

      <ModalPrint
        isOpen={isOpenModal}
        onDismiss={() => setIsOpenModal(false)}
        data={printingInfo}
        gtin={gtin}
        productId={productId}
      />
    </div>
  );
};
