import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { accent, lightBlue } from '../../constants';
import { useAppContext } from '../../context/AppContext';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { getAccessLevel } from '../../redux/Auth/authSelectors';
import { navLinkGroups, navLinkGroupsNoAggregation, pathWithState } from './navLinkGroups';
import { getIsDbfromStore } from 'redux/App/appSelectors';
import s from './Menu.module.scss';

import {
  MenuItem,
  MenuList,
  ToggleButton,
  createPresenceComponent,
  makeStyles,
  motionTokens,
  tokens,
} from "@fluentui/react-components";

import {
  ChevronLeft20Filled,
  ChevronRight20Filled,
  MegaphoneLoud20Filled,
  MegaphoneLoud20Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { setFilteredNavRed } from 'redux/Nav/NavAction';
import { keyframes } from '@fluentui/react';

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    display: "flex",
    height: "5rem",
  },
  content: {
    flex: "1",
    padding: "1rem",
    display: "grid",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  field: {
    display: "flex",
    marginTop: "0.25rem",
    marginLeft: "0.5rem",
    flexDirection: "column",
    gridRowGap: tokens.spacingVerticalS,
  },
  selectedMenuItem: {
    backgroundColor: lightBlue,
    borderLeftColor: accent,
    borderLeft: `0.20rem solid ${accent}`,
    borderRadius: '0px',
    color: accent,
  },
  menuBlock: {
    '& div': {
      maxWidth: '80px',
      height: '67px',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '10px',
      gap: 0,
      textAlign: 'center'
    },

  },
  
  toggleMenu: {
    animation: '$width 1s ease-in-out',
    borderRadius: '50%',
    maxWidth: '24px',
    minHeight: '24px',
    backgroundColor: '#C7E0F4',
    border: 'none',
  },
  toggleMenuContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90px',
  }
});
const Fade = createPresenceComponent({
  enter: {
    keyframes: [{ transform: "rotate(180deg)" }, { transform: "rotate(0)" }],
    duration: motionTokens.durationGentle,
  },
  exit: {
    keyframes: [{ transform: "rotate(0)" }, { transform: "rotate(180deg)" }],
    duration: motionTokens.durationGentle,
  },
});
export const Menu = () => {
  const [selectedKey, setSelectedKey] = useState('/products');
  const history = useHistory();
  const location = useLocation();
  const accessLevel = useSelector(getAccessLevel);
  const [filteredNav, setFilteredNav] = useState([]);
  const isAggregationIncluded = useSelector((state) => state.app.aggregationIncluded);
  const shouldShowDbPage = useSelector(getIsDbfromStore);
  const dispatch = useDispatch();
  const [toggleMenu, setToggleMenu] = useState(true);

  const styles = useStyles();

  useEffect(() => {
    let pathname = location.pathname;

    if (pathname) {
      setSelectedKey(pathname);
    } else {
      setSelectedKey('/products');
    }
  }, [location]);

  useEffect(() => {
    if (accessLevel) {
      let _nav = isAggregationIncluded
        ? navLinkGroups[0].links.filter((item) => item.access.includes(accessLevel))
        : navLinkGroupsNoAggregation[0].links.filter((item) => item.access.includes(accessLevel));

      if (typeof shouldShowDbPage !== 'undefined' && !shouldShowDbPage) {
        const settingsItem = _nav.find((item) => item.name === 'Настройки');
        if (settingsItem) {
          const newSettingsLinks = settingsItem.links.filter((link) => link.name !== 'База данных');
          settingsItem.links = newSettingsLinks;
        }
      }
      setFilteredNav(_nav)

    }
  }, [accessLevel, isAggregationIncluded, shouldShowDbPage, dispatch]);

  const { crumbs, setCrumbs } = useCrumbsContext();
  const { isMenuDisabled } = useAppContext();

  const onLinkClick = useCallback((e, item) => {
    if (!item?.links) {
      setSelectedKey(item?.key);
      dispatch(setFilteredNavRed())
      if (pathWithState.includes(`${item?.key}`)) {
        history.replace({ pathname: item?.key, state: { prevPath: location.pathname } });
      } else {
        history.replace(item?.key);
      }
    } else {
      setSelectedKey(item?.key);
      dispatch(setFilteredNavRed(item.links))
      history.replace(item?.links[0].key);
    }
    setCrumbs(prev => [...prev, item.key]);
  }, [history, location.pathname, setCrumbs]);

  useEffect(() => {
    if (crumbs.length) {
      setSelectedKey(crumbs[0]);
    }
  }, [crumbs]);

  const Announcements = bundleIcon(MegaphoneLoud20Filled, MegaphoneLoud20Regular);

  const [checkedValues, setCheckedValues] = useState({ font: ["calibri"] });
  const onChange = (
    e,
    { name, checkedItems }
  ) => {
    setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
  };

  const isSelected = (key) => {
    return location.pathname.startsWith(key);
  };

  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    const matchingItem = filteredNav.find(item => item.key == `/${currentPath}`);
    if (matchingItem) {
      onLinkClick(null, matchingItem);
    }
  }, [filteredNav]);

  useEffect(() => {
    setToggleMenu(localStorage.getItem('toggleMenu') === 'true');
  }, []);

  const toggleMenuHandle = () => {
    setToggleMenu((prev) => {
      const newValue = !prev;
      localStorage.setItem('toggleMenu', newValue);
      return newValue;
    });
  }

  return (
    <div className={`${s.nav} ${toggleMenu ? '' : s.closeMenu} ${isMenuDisabled && s.disabledNav}`}>
      <div className={`${s.container} ${toggleMenu ? styles.menuBlockPaddingTop : ''}`}>
        <MenuList className={`${styles.menuBlock}`} checkedValues={checkedValues} onCheckedValueChange={onChange}>
          {filteredNav?.map((item, i) => {
            return (
              <MenuItem
                key={item.key}
                icon={item.icon ?? <Announcements/>}
                hasIcons={false}
                onClick={(e) => onLinkClick(e, item)}
                value={i}
                className={`${isSelected(item.key) ? styles.selectedMenuItem : ''}`}
              >
                {item.name}
              </MenuItem>
            )
          })}
        </MenuList>
        <div className={`${styles.toggleMenuContainer} ${toggleMenu ? '' : styles.closeMenu}`}>
          <Fade visible={toggleMenu}>
            <ToggleButton onClick={() => toggleMenuHandle()} className={styles.toggleMenu} icon={<ChevronRight20Filled />} />
          </Fade>
        </div>
      </div>
    </div>
  );
};