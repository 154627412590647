import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import s from './Alert.module.scss';

export const Alert = ({ type = 'success', title, text, onClose, btnText = 'Закрыть' }) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: title
  };
  const [useComponent, setUseComponent] = useState(false);
  useEffect(() => {
    if (text?.includes('\r\n')) {
      setUseComponent(true);
    }
  }, [text]);

  return (
    <div className={s.container}>
      <div className={s.confirm}>
        <Dialog
          hidden={false}
          dialogContentProps={dialogContentProps}
          onDismiss={onClose}
          maxWidth={'33rem'}
        >
          {useComponent ? <TextComponent text={text} /> : text}
          <DialogFooter>
            <PrimaryButton onClick={onClose} text={btnText} />
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};

const TextComponent = ({ text }) => {
  const list = text.split('\r\n').filter((r) => !!r);

  return (
    <main className={s.mainWrapper}>
      {list?.map((r) => (
        <div className={s.row}>{r}</div>
      ))}
    </main>
  );
};
