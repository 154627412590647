import { useId } from '@fluentui/react-hooks';
import { Icon, TooltipHost } from '@fluentui/react';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import s from './TableAction.module.scss';

const calloutProps = { gapSpace: 0 };
const hostStyles = { root: s.mainContainer };

export const TableAction = ({
  link,
  iconName,
  text,
  isButton = false,
  onClick,
  params,
  disabled,
  tooltipText,
  type = 'default',
  addStyles,
  getProductBalance,
  icon
}) => {
  const tooltipId = useId('tooltip');

  if (isButton) {
    return (
      <TooltipHost
        content={tooltipText}
        id={tooltipId}
        calloutProps={calloutProps}
        styles={hostStyles}
      >
        <button
          onClick={onClick}
          disabled={disabled}
          aria-describedby={tooltipId}
          className={`${s.action} ${text && `${s.largeAction}`} ${
            type === 'danger' && `${s.danger}`
          } ${type === 'accent' && `${s.accent}`}`}
          style={{ ...addStyles }}
        >
          {iconName && <Icon iconName={iconName} className={s.icon} />}
          {icon && <img src={icon} alt="info icon" className={s.infoIcon} />}
          {text && <span className={s.text}>{text}</span>}
        </button>
      </TooltipHost>
    );
  }

  return (
    <TooltipHost
      content={tooltipText}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
    >
      <NavLink
        to={{
          pathname: link,
          state: params
        }}
        aria-describedby={tooltipId}
        className={`${s.action} ${text && `${s.largeAction}`}`}
        style={{ ...addStyles }}
      >
        {iconName && <Icon iconName={iconName} className={s.icon} onClick={getProductBalance} />}
        {icon && <img src={icon} alt="info icon" className={s.infoIcon} />}
        {text && <span className={s.text}>{text}</span>}
      </NavLink>
    </TooltipHost>
  );
};
