import { Toggle } from '@fluentui/react';
import React from 'react';

export const ToggleCheckbox = ({ label, onChange, value, onText = 'вкл', offText = 'выкл' }) => {
  return (
    <Toggle
      label={label}
      onText={onText}
      defaultChecked={value}
      offText={offText}
      onChange={onChange}
    />
  );
};
