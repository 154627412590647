import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'redux/LegalFaces/legalFacesSelectors';
import { Checkbox } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react';
import { useField } from 'formik';
import s from './options.module.scss';

const SingleRow = ({ groupName, groupNumber }) => {
  const [field, _, helpers] = useField('productGroups');
  const [isOn, setIsOn] = useState(field.value.includes(groupNumber));

  const onToggle2 = () => {
    setIsOn((p) => !p);
    const currentVals = field.value;
    let result;
    if (isOn) {
      result = currentVals.filter((val) => val !== groupNumber);
    } else {
      result = [...currentVals, groupNumber];
    }
    helpers.setValue(result);
  };

  return (
    <div className={s.singleRow}>
      <Checkbox onChange={onToggle2} checked={isOn} />
      <span>{groupName}</span>
    </div>
  );
};

const ProductGroupOptions = ({ onClick }) => {
  const { allProductGroups } = useSelector(getCompanyInfo);
  const [productGroups, setProductGroups] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [field, _, helpers] = useField('productGroups');

  useEffect(() => {
    if (allProductGroups?.length) {
      setProductGroups([...allProductGroups]);
      const values = allProductGroups?.map((group) => group?.productGroup);
      helpers.setValue(values);
    }
  }, [allProductGroups?.length]);

  useEffect(() => {
    if (Array.isArray(field.value)) {
      const values = field?.value?.map((group) => group?.productGroup);
      helpers.setValue(values);
    }
  }, []);

  return (
    <main className={s.fieldWrapper}>
      <button type="button" className={s.fieldLabel} onClick={() => setIsOpen((p) => !p)}>
        {isOpen ? 'Свернуть товарные группы' : 'Посмотреть товарные группы'}
      </button>
      {isOpen && productGroups.length && (
        <section className={s.optionsWrapper}>
          {productGroups?.map((group) => (
            <SingleRow groupName={group.name} groupNumber={group.productGroup} key={group.name} />
          ))}
        </section>
      )}
    </main>
  );
};

export default ProductGroupOptions;
