import React, { useEffect, useRef, useState, useMemo } from 'react';
import ProductsAPI from '../../../api/productsAPI';
import { mapItemsForDropdown } from '../../../functions';
import { Button, Combobox, makeStyles, Option, tokens, useId } from '@fluentui/react-components';
import g from '../../../assets/scss/Main.module.scss';
import { Dismiss12Regular } from '@fluentui/react-icons';
import { LinkButton } from 'components/LinkButton/LinkButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '12px',
    margin: '8px 0'
  },
  scrollableMenuList: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
  },

  remains: {
    display: 'flex',
    alignItems: "center",
  },

  inputBlock: {
    display: 'flex',
    alignItems: 'center',
    "> span": {
      width: '4.438rem',
    }
  },

  categoryBlock: {
    display: "flex",
    minWidth: '730px',
    justifyContent: "space-between"
  },

  brandInputWidth: {
    minWidth: "auto",
    "::after": {
      display: "none"
    },
    border: 'none',
    "> input": {
      width: "calc(11.688rem - 35px)",
    },
    "> span:nth-child(4) ": {
      display: 'none'
    }
  },

  otherInputWidth: {
    minWidth: "auto",
    "::after": {
      display: "none"
    },
    border: 'none',
    "> input": {
      width: "calc(13.9rem)",
      cursor: 'pointer'
    },
    "> span:nth-child(4)": {
      display: 'none'
    }
  },

  line: {
    margin: '0 8px',
  },

  error: {
    color: 'red',
    marginTop: '8px',
  },

  tag: {
    "& button": {
      borderRadius: '4px',
      backgroundColor: '#DEECF9',
      height: '32px',
    }
  }
});

export const LinesFilterNew = ({ query, setQuery, selectedOptions, setSelectedOptions, lineStatuses, productsForDropdown }) => {
  const [filterObj, setFilterObj] = useState(
    {
      status: query.status,
      productId: query.productId,
    }
  );
  const styles = useStyles();

  const comboId = useId("id");
  const selectedListId = `${comboId}-selection`;
  const selectedListRef = useRef(null);
  const comboboxInputRef = useRef(null);
  const labelledBy =
    selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

  const displayTags = useMemo(() => selectedOptions, [selectedOptions]);

  const onOptionSelect = (data) => {
    const updatedFilterObj = { ...filterObj, [data.optionValue.category]: data.optionValue.key };
    const category = data?.optionValue?.category;
    const key = data?.optionValue?.key;

    if (category && key) {
      setFilterObj(updatedFilterObj);

      const updatedOptions = selectedOptions?.filter(option => option?.optionValue?.category !== category);
      setSelectedOptions([...updatedOptions, data]);
    }

    setQuery(
      (prev) => ({
        ...prev,
        ...updatedFilterObj
      }),
      'replace'
    );
  }

  const onTagClick = (option, index) => {
    const updatedFilterObj = { ...filterObj, [option.optionValue.category]: undefined };
    setSelectedOptions(selectedOptions.filter((o) => o !== option));
    setFilterObj(updatedFilterObj);

    const indexToFocus = index === 0 ? 0 : index - 1;
    const optionToFocus = selectedListRef.current?.querySelector(
      `#${comboId}-remove-${indexToFocus}`
    );
    if (optionToFocus) {
      optionToFocus.focus();
    } else {
      comboboxInputRef.current?.focus();
    }
    setQuery(
      (prev) => ({
        ...prev,
        ...updatedFilterObj
      }),
      'replace'
    );
  };
  console.log(selectedOptions);
  
  const resetAllHandler = () => {
    setQuery({
      pageNumber: 1
    }, 'replace');
    setFilterObj({});
    setSelectedOptions([]);
  };
  
  return (
    <div >
      <div className={styles.root + ' ' + g.filtersMainWrapper}>
        <Combobox
          className={styles.otherInputWidth}
          aria-labelledby={labelledBy}
          value='Статус'
          readOnly
          selectedOptions={selectedOptions}
          onOptionSelect={(_, item) => onOptionSelect(item)}
          ref={comboboxInputRef}
        >
          <div className={styles.scrollableMenuList}>
            {lineStatuses.map((option) => (
              <Option
                value={{ key: option.key, text: option.text, category: 'status' }}
                key={option.key}
              >
                {option.text}
              </Option>
            ))}
          </div>
        </Combobox>

        <Combobox
          className={styles.otherInputWidth}
          aria-labelledby={labelledBy}
          value='Продукт'
          readOnly
          selectedOptions={selectedOptions}
          onOptionSelect={(_, item) => onOptionSelect(item)}
          ref={comboboxInputRef}
        >
          <div className={styles.scrollableMenuList}>
            {productsForDropdown.map((option) => (
              <Option
                value={{ key: option.key, text: option.text, category: 'productId' }}
                key={option.key}
              >
                {option.text}
              </Option>
            ))}
          </div>
        </Combobox>


      </div>
      {displayTags.length ? (
        <ul
          id={selectedListId}
          className={styles.tagsList}
          ref={selectedListRef}
        >
          <span id={`${comboId}-remove`} hidden>
            Remove
          </span>
          {displayTags.map((option, i) => (
            <li className={styles.tag} key={i}>
              <Button
                size="small"
                shape="circular"
                icon={<Dismiss12Regular />}
                iconPosition="after"
                onClick={() => onTagClick(option, i)}
                id={`${comboId}-remove-${i}`}
                aria-labelledby={`${comboId}-remove${comboId}-remove-${i}`}
              >
                {option.optionValue ?
                  `${option.optionValue.label || ''}: ${option.optionValue.text}` :
                  `${option.label}: ${option.value}`
                }
              </Button>
            </li>
          ))}
          <LinkButton isButton={true} text={'Сбросить всё'} onClick={() => resetAllHandler()}/>
        </ul>
      ) : null}
    </div>
  );
};
