import { useId, Label, makeStyles, Combobox, Input, Text, Option, tokens, Button } from '@fluentui/react-components';
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Dismiss12Regular } from '@fluentui/react-icons';
import g from '../../../assets/scss/Main.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '12px',
    margin: '8px 0',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  scrollableMenuList: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
    flexWrap: 'wrap',
  },
  inputBlock: {
    display: 'flex',
    alignItems: 'center',
    "> span": {
      width: '4.438rem',
    }
  },
  label: {
    marginRight: '8px',
  },
  categoryBlock: {
    display: "flex",
    minWidth: '730px',
    gap: '12px',
  },
  combobox: {
    minWidth: "200px",
    maxWidth: "300px",
    "::after": {
      display: "none"
    },
    border: 'none',
    "> input": {
      width: "calc(11.688rem - 35px)",
      cursor: 'pointer'
    },
    "> span:nth-child(4)": {
      display: 'none'
    }
  },
  tag: {
    "& button": {
      borderRadius: '4px',
      backgroundColor: '#DEECF9',
      height: '32px',
    }
  },
  error: {
    color: 'red',
    marginTop: '8px',
  },
  line: {
    margin: '0 8px',
  }
});

export const ProductsFilterNew = ({
  query,
  setQuery,
  selectedOptions,
  setSelectedOptions,
  brandsForDropdown,
  categoriesForDropdown,
  subCategoriesForDropdown
}) => {
  const styles = useStyles();
  const comboId = useId("id");
  const selectedListId = `${comboId}-selection`;
  const comboboxInputRef = useRef(null);
  const selectedListRef = useRef(null);

  const [MCError, setMCError] = useState(false);
  const labelledBy = selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

  const [filterObj, setFilterObj] = useState({
    brandId: query.brandId,
    categoryId: query.categoryId,
    subCategoryId: query.subCategoryId,
    minRemainderOfMarkingCodes: query.minRemainderOfMarkingCodes,
    maxRemainderOfMarkingCodes: query.maxRemainderOfMarkingCodes
  });

  const dataFilter = useMemo(() => [
    {
      key: 'brandId',
      label: 'Бренд',
      type: 'combobox',
      options: brandsForDropdown,
    },
    {
      key: 'categoryId',
      label: 'Категория',
      type: 'combobox',
      options: categoriesForDropdown,
    },
    {
      key: 'subCategoryId',
      label: 'Подкатегория',
      type: 'combobox',
      options: subCategoriesForDropdown,
      disabled: !filterObj.categoryId
    }
  ], [brandsForDropdown, categoriesForDropdown, subCategoriesForDropdown, filterObj.categoryId]);

  const onOptionSelect = useCallback((data) => {
    const { optionValue } = data;
    if (optionValue) {
      const categoryId = optionValue.categoryId;
      const key = optionValue.key;

      if (categoryId === 'categoryId') {
        setFilterObj(prev => ({
          ...prev,
          [categoryId]: key,
          subCategoryId: undefined
        }));
        setQuery(prev => ({
          ...prev,
          [categoryId]: key,
          subCategoryId: undefined,
          pageNumber: 1
        }), 'replace');
      } else {
        setFilterObj(prev => ({
          ...prev,
          [categoryId]: key
        }));
        setQuery(prev => ({
          ...prev,
          [categoryId]: key,
          pageNumber: 1
        }), 'replace');
      }

      setSelectedOptions(prev => {
        const filtered = prev.filter(opt =>
          opt.optionValue?.categoryId !== categoryId &&
          (categoryId === 'categoryId' ? opt.optionValue?.categoryId !== 'subCategoryId' : true)
        );
        return [...filtered, data];
      });
    }
  }, [setQuery, setSelectedOptions]);

  const onTagClick = useCallback((option) => {
    const categoryId = option.optionValue?.categoryId;
    if (categoryId) {
      setFilterObj(prev => {
        const newObj = { ...prev };
        newObj[categoryId] = undefined;
        if (categoryId === 'categoryId') {
          newObj.subCategoryId = undefined;
        }
        return newObj;
      });

      setQuery(prev => {
        const newQuery = { ...prev };
        newQuery[categoryId] = undefined;
        if (categoryId === 'categoryId') {
          newQuery.subCategoryId = undefined;
        }
        newQuery.pageNumber = 1;
        return newQuery;
      }, 'replace');

      setSelectedOptions(prev =>
        prev.filter(opt =>
          opt.optionValue?.categoryId !== categoryId &&
          (categoryId === 'categoryId' ? opt.optionValue?.categoryId !== 'subCategoryId' : true)
        )
      );
    }
  }, [setQuery, setSelectedOptions]);

  const resetAllHandler = () => {
    setQuery({
      pageNumber: 1
    }, 'replace');
    setFilterObj({});
    setSelectedOptions([]);
  };

  const handleInputChange = useCallback((value, type) => {
    // Удаляем все нецифровые символы
    const cleanValue = value.replace(/\D/g, '');

    if (cleanValue === value) {
      const numValue = Number(value);
      const min = type === 'minRemainderOfMarkingCodes' ? numValue : filterObj.minRemainderOfMarkingCodes;
      const max = type === 'maxRemainderOfMarkingCodes' ? numValue : filterObj.maxRemainderOfMarkingCodes;

      const isError = (min !== undefined && min !== '') &&
        (max !== undefined && max !== '') &&
        Number(min) > Number(max);

      setMCError(isError);

      setFilterObj(prev => ({
        ...prev,
        [type]: cleanValue
      }));

      if (!isError) {
        setQuery(prev => ({
          ...prev,
          [type]: cleanValue === '' ? undefined : cleanValue,
          pageNumber: 1
        }), 'replace');

        setSelectedOptions(prev =>
          prev.filter(opt => opt.optionValue?.categoryId !== type)
        );

        if (cleanValue !== '') {
          const tagText = type === 'minRemainderOfMarkingCodes'
            ? ` ${cleanValue}`
            : ` ${cleanValue}`;

          setSelectedOptions(prev => [
            ...prev,
            {
              optionValue: {
                categoryId: type,
                key: cleanValue,
                text: tagText,
              }
            }
          ]);
        }
      }
    }
  }, [setQuery, setSelectedOptions, filterObj, selectedOptions]);

  const renderCombobox = useCallback((label, categoryId, options, disabled = false) => (
    <div key={categoryId}>
      <Combobox
        className={styles.combobox}
        value={label}
        readOnly
        disabled={disabled}
        aria-labelledby={labelledBy}
        ref={comboboxInputRef}
        onOptionSelect={(_, data) => onOptionSelect({
          optionValue: {
            categoryId,
            key: data.optionValue?.key || data.value,
            text: data.optionValue?.text || data.text
          }
        })}
      >
        <div className={styles.scrollableMenuList}>
          {options?.map((option) => (
            <Option
              key={option.key}
              value={option}
              text={option.text}
            >
              {option.text}
            </Option>
          ))}
        </div>
      </Combobox>
    </div>
  ), [styles, labelledBy, onOptionSelect]);

  return (
    <div>
      <div className={styles.root + ' ' + g.filtersMainWrapper}>
        <div className={styles.categoryBlock}>
          {dataFilter.map(filter =>
            renderCombobox(
              filter.label,
              filter.key,
              filter.options,
              filter.disabled
            )
          )}
        </div>

        <div className={styles.inputBlock}>
          <Label className={styles.label}>Остаток КМ (тыс. шт)</Label>
          <div className={styles.inputBlock}>
            <Input
              value={filterObj.minRemainderOfMarkingCodes || ''}
              type="number"
              onChange={(e) => handleInputChange(e.target.value, 'minRemainderOfMarkingCodes')}
              onWheel={(e) => e.currentTarget.blur()}
              onKeyDown={(e) => {
                if (e.key === '.' || e.key === ',') {
                  e.preventDefault();
                }
              }}
              step="1"
            />
          </div>
          <div className={styles.line}>—</div>
          <div className={styles.inputBlock}>
            <Input
              value={filterObj.maxRemainderOfMarkingCodes || ''}
              type="number"
              onChange={(e) => handleInputChange(e.target.value, 'maxRemainderOfMarkingCodes')}
              onWheel={(e) => e.currentTarget.blur()}
              onKeyDown={(e) => {
                if (e.key === '.' || e.key === ',') {
                  e.preventDefault();
                }
              }}
              step="1"
            />
          </div>
          {MCError && <Text className={styles.error}>Неправильный диапазон</Text>}
        </div>
      </div>

      {selectedOptions?.length > 0 && (
        <ul id={selectedListId} className={styles.tagsList} ref={selectedListRef}>
          {selectedOptions.map((option, i) => (
            <li className={styles.tag} key={i}>
              <Button
                size="small"
                shape="circular"
                icon={<Dismiss12Regular />}
                iconPosition="after"
                onClick={() => onTagClick(option)}
                id={`${comboId}-remove-${i}`}
                aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
              >
                {`${option.optionValue?.label}: ${option.optionValue?.text}`}
              </Button>
            </li>
          ))}
          <LinkButton isButton={true} text={'Сбросить всё'} onClick={() => resetAllHandler()} />
        </ul>
      )}
    </div>
  );
};
