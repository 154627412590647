import clsx from 'clsx';
import { Checkbox, DirectionalHint, SearchBox, TooltipDelay, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import ConsignmentsAPI from '../../api/consignmentsAPI';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { addIcon, consignmentStatuses, moreInfoIcon, searchBoxStyles } from '../../constants';
import { getDateFromTimestamp, getStatusById, mapItemsForDropdown, mapProductsForDropdown } from '../../functions';
import { useCheckboxFilter, usePaginationAndSearch, useDataFilter } from '../../hooks';
import g from '../../assets/scss/Main.module.scss';
import s from './Consignments.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { ConsignmentsFilterNew } from 'components/Filters/ConsignmentsFilterNew/ConsignmentsFilterNew';
import productsAPI from 'api/productsAPI';
import linesAPI from 'api/linesAPI';
import legalAPI from 'api/legalAPI';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import { useTableFeatures, useTableSort } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Номер',
    width: "0.1",
    columnId: 'taskNumber',
    sortable: false
  },
  {
    title: 'Дата производства',
    width: "0.2",
    columnId: 'productCreationDate',
    sortable: true
  },
  {
    title: 'Продукт',
    width: "0.1",
    columnId: 'product.name',
    sortable: true
  },
  {
    title: 'Производственная линия',
    width: "0.2",
    columnId: 'productLine.name',
    sortable: true
  },
  {
    title: 'План/факт',
    width: "0.1",
    columnId: 'plannedValue',
    sortable: true
  },
  {
    title: 'Статус',
    width: "0.1",
    columnId: 'status',
    sortable: true
  },
  {
    title: 'Действия',
    width: "0.1",
    sortable: false
  }
];

export const Consignments = ({ status, statusParamName }) => {
  const [consignments, setConsignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('consignments');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productsForDropdown, setProductsForDropdown] = useState([]);
  const [linesForDropdown, setLinesForDropdown] = useState([]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(() => {
    if (status && !query.status) {
      setQuery((prev) => ({ ...prev, status: status }), 'replace');
    }
  }, [status]);

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    consignmentStatuses,
    'status',
    setQuery,
    query
  );

  useEffect(() => {
    productsAPI.getAllProducts().then((res) => setProductsForDropdown(mapProductsForDropdown(res)));
    linesAPI.getAllLines().then((res) => setLinesForDropdown(mapItemsForDropdown(res)));
    legalAPI.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  useEffect(() => {
    const filterData = [
      { label: 'Продукт', key: 'productId', options: productsForDropdown },
      { label: 'Производственная линия', key: 'lineId', options: linesForDropdown },
      { label: 'Компания', key: 'companyId', options: companiesForDropdown },
      {
        label: 'Дата производства от',
        key: 'minProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства до',
        key: 'maxProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, label: filter.label }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, companiesForDropdown, productsForDropdown, linesForDropdown]);

  const getConsignments = async (query) => {
    setLoading(true);
    let res = await ConsignmentsAPI.getConsignments(query);

    if (!res.statusCode) {
      const { list, quantity } = res;
      await setConsignments(list);
      await setTotalItems(quantity);
    }

    await setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getConsignments, query, setQuery, status, statusParamName, headerHeight: 200 });

  const {
    sort: { getSortDirection, toggleColumnSort },
  } = useTableFeatures(
    {
      columns: tableHeader,
      items: consignments,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'taskNumber', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    toggleColumnSort(event, columnId);

    const direction = currentDirection === 'ascending' ? -1 : 1;

    const sortedConsignments = [...consignments].sort((a, b) => {
      if (columnId.includes('.')) {
        const [parent, child] = columnId.split('.');
        const valueA = a[parent]?.[child] ?? '';
        const valueB = b[parent]?.[child] ?? '';
        return String(valueA).localeCompare(String(valueB)) * direction;
      }

      if (columnId === 'productCreationDate') {
        return (new Date(a[columnId] || 0) - new Date(b[columnId] || 0)) * direction;
      }

      if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
        return (a[columnId] - b[columnId]) * direction;
      }

      const valueA = a[columnId] ?? '';
      const valueB = b[columnId] ?? '';
      return String(valueA).localeCompare(String(valueB)) * direction;
    });

    setConsignments(sortedConsignments);
  };

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            iconProps={''}
            styles={searchBoxStyles}
            value={query.searchValue}
            placeholder="Поиск по номеру задания"
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => {
              onSearchTermChanged(newValue);
            }}
          />
        </div>

        <div className={clsx(g.headerFilters)} >
          <ButtonMenu
            title={'Статус'}
            dataArray={consignmentStatuses}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            isCheckbox={true}
            optionSize={selectedFilters.size}
          />
          <ButtonToggle
            text="Фильтры"
            value={isOpenFilters}
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            optionSize={selectedOptions.length}
          />
        </div>
      </div>

      <div className={s.mainContent}>
        <div style={{ margin: '0.75rem 0 0.75rem 1rem' }}>
          <LinkButton
            link={`consignments/add`}
            isButton={true}
            text="Создать задание"
            primary={true}
          />
        </div>

        {isOpenFilters && (
          <div className={g.filtersWrapper}>
            <ConsignmentsFilterNew
              query={query}
              setQuery={setQuery}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              productsForDropdown={productsForDropdown}
              linesForDropdown={linesForDropdown}
              companiesForDropdown={companiesForDropdown}
              setCompaniesForDropdown={setCompaniesForDropdown}
            />
          </div>
        )}
        <div className={s.tableWrapper}>
          {!consignments.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>
              Не найдено. Измените параметры фильтрации
            </div>
          ) : (
            <Table
              headerItems={tableHeader}
              loading={loading}
              onSort={handleSort}
              sortState={{ getSortDirection }}
            >
              {consignments?.map((item) => {
                return (
                  <div key={item.id} className={g.tableItem}>
                    <div
                      className={s.tableCell}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})`,
                        minWidth: 'auto'
                      }}
                    >
                      <TooltipHost
                        content={item.taskNumber}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item.taskNumber}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})`,
                        minWidth: 'auto'
                      }}
                    >
                      <TooltipHost
                        content={getDateFromTimestamp(item.productCreationDate, null, { withoutTime: true })}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {getDateFromTimestamp(item.productCreationDate, null, { withoutTime: true })}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCellProduct}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})`,
                        minWidth: 'auto'
                      }}
                    >
                      <TooltipHost
                        content={item.product?.name}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item.product?.name}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})`,
                        minWidth: 'auto'
                      }}
                    >
                      <TooltipHost
                        content={item.productLine?.name}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item.productLine?.name}{' '}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[4].width})`,
                        minWidth: 'auto'
                      }}
                    >
                      <TooltipHost
                        // content={`${item.plannedValue}/${item.actualValue}`}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item.plannedValue}/{item.actualValue}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[5].width})`,
                        minWidth: 'auto'
                      }}
                    >
                      <TooltipHost
                        // content={getStatusById(item.status, consignmentStatuses)}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {getStatusById(item.status, consignmentStatuses)}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableActionCell}
                      style={{
                        width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[6].width})`,
                        minWidth: '100px',
                        flexShrink: 0
                      }}
                    >
                      <TableAction
                        link={`/consignments/${item.id}`}
                        text={'Подробнее'}
                        tooltipText={'Подробнее'}
                        iconName={moreInfoIcon}
                      />
                    </div>
                  </div>
                );
              })}
            </Table>
          )}
        </div>
      </div>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};
