import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import settingsAPI from 'api/settingsAPI';
import ReportsAPI from '../../api/reportsAPI';
import g from '../../assets/scss/Main.module.scss';
import { Alert } from '../../components/Alert/Alert';
import { Pagination } from '../../components/Pagination/Pagination';
import { TableAction } from '../../components/TableAction/TableAction';
import { WaitingScreen } from '../../components/WaitingScreen/WaitingScreen';
import { searchBoxStyles, reportStatuseForPrinting } from '../../constants';
import { formatTwoPartDate, isShipped, getDateFromTimestamp, mapItemsForDropdown } from '../../functions';
import { useAlert, usePaginationAndSearch, useSendRequest, useDataFilter } from '../../hooks';
import { getGeneralSettings, getGlobalErrorText } from '../../redux/App/appSelectors';
import s from './Reports.module.scss';
import { getTypesInfo } from './constants';
import { SyncStatus } from '../../components/SyncStatus/SyncStatus';
import ProductsAPI from '../../api/productsAPI';
import PrintReportModal from 'pages/Report/SendEnteringReportModal';
import { setGenSettings, setPrintReports } from 'redux/App/appReducer';
import { getShouldCheckExpDateForPrintRep } from '../../redux/App/appSelectors';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { ReportsFilterNew } from 'components/Filters/ReportsFilterNew/ReportsFilterNew';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import LegalApi from '../../api/legalAPI';

import СreatedIcon from '../../assets/img/СreatedIcon.svg';
import CreatedAndKmArchivedIcon from '../../assets/img/CreatedAndKmArchivedIcon.svg';
import ProcessedWithErrorKmArchivedIcon from '../../assets/img/ProcessedWithErrorKmArchivedIcon.svg';
import SuccessfullyProcessedAndKmArchivedIcon from '../../assets/img/SuccessfullyProcessedAndKmArchivedIcon.svg';
import ProcessedWithErrorIcon from '../../assets/img/ProcessedWithErrorIcon.svg';
import SuccessfullyProcessedIcon from '../../assets/img/SuccessfullyProcessedIcon.svg';
import FragmentedIcon from '../../assets/img/FragmentedIcon.svg';
import SendedIcon from '../../assets/img/SendedIcon.svg';

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  useTableFeatures,
  useTableSort,
  useTableSelection,
  TableSelectionCell,
  Spinner,
  Tooltip,

} from "@fluentui/react-components";
import { ProductsFilterNew } from 'components/Filters/ProductsFilterNew/ProductsFilterNew';
import { LinkELement } from 'components/LinkELement/LinkELement';
import clsx from 'clsx';
import { ArrowDownRegular } from '@fluentui/react-icons';
import { FixedSizeList as List } from "react-window";


export const Reports = ({ type, children }) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportStatus, setreportStatus] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);
  const [selectedReports, setSelectedReports] = useState(new Set());
  const globalErrorText = useSelector(getGlobalErrorText);
  const [query, setQuery] = useDataFilter('reports', type);
  const typesInfo = getTypesInfo();

  const [isPrintRepModal, setIsPrintModal] = useState(false);
  const dispatch = useDispatch();
  const shouldCheckExpDateForPrintRepFromRdx = useSelector(getShouldCheckExpDateForPrintRep);
  const [shouldCheckExpDateForPrintRep, setShouldCheckExpDateForPrintRep] = useState(false);
  const genSettingsFromRedx = useSelector(getGeneralSettings);
  const [selectedRows, setSelectedRows] = useState(() => new Set([]));
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  useEffect(() => {
    if (globalErrorText) {
      setreportStatus(null);
    }
  }, [globalErrorText]);

  const getGeneralSetting = async () => {
    const res = await settingsAPI.getGeneralSettings();
    dispatch(setGenSettings(res));
  };

  useEffect(() => {
    getGeneralSetting();
  }, []);


  useEffect(() => {
    if (typeof shouldCheckExpDateForPrintRepFromRdx === 'boolean') {
      setShouldCheckExpDateForPrintRep(shouldCheckExpDateForPrintRepFromRdx);
    }
  }, [shouldCheckExpDateForPrintRepFromRdx]);

  const getReports = async (query = {}) => {
    setLoading(true);
    const getReportsTypes = {
      printing: (queryString) => ReportsAPI.getUtilisationReports(queryString),
      aggregation: (queryString) => ReportsAPI.getAggregationReports(queryString),
      entering: (queryString) => ReportsAPI.getRolloutReports(queryString),
      rejection: (queryString) => ReportsAPI.getRejectReports(queryString),
      sets: (queryString) => ReportsAPI.getSetsReport(queryString),
      withrdIntrnlConsmpt: (queryString) => ReportsAPI.getInternalWithdrowalReport(queryString),
      writeOff: (queryString) => ReportsAPI.getInternalWriteOffReport(queryString)
    };
    let res;
    res = await getReportsTypes[type](query);
    if (type === 'printing') {
      if (
        genSettingsFromRedx &&
        'checkProductionAndExpirationDatesWhenSubmittingUtilisationReports' in genSettingsFromRedx
      )
        setShouldCheckExpDateForPrintRep(
          genSettingsFromRedx.checkProductionAndExpirationDatesWhenSubmittingUtilisationReports
        );
    }

    if (res) {
      if ('list' in res && 'quantity' in res) {
        setReports(res.list);
        setTotalItems(res.quantity);
        if (type === 'printing') {
          dispatch(setPrintReports(res.list));
        }
      }
    }
    setLoading(false);
  };

  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();

  const { afterSending } = useSendRequest(setLoading);

  //============================= send reports =============================

  const sendReports = async () => {
    const sendReportsTypes = {
      // printing: (arrayArg) => ReportsAPI.sendUtilisationReports(arrayArg),
      printing: shouldCheckExpDateForPrintRep
        ? () => setIsPrintModal(true)
        : (arrayArg) => ReportsAPI.sendUtilisationReports(arrayArg),
      aggregation: (arrayArg) => ReportsAPI.sendAggregationReports(arrayArg),
      entering: (arrayArg) => ReportsAPI.sendRolloutReports(arrayArg),
      rejection: (arrayArg) => ReportsAPI.sendRejectReports(arrayArg),
      sets: (arrayArg) => ReportsAPI.sendSetsReports(arrayArg),
      withrdIntrnlConsmpt: (arrayArg) => ReportsAPI.sendInternalWithdrwlReports(arrayArg),
      writeOff: (arrayArg) => ReportsAPI.sendInternalWriteOffReports(arrayArg)
    };

    if (type === 'printing' && shouldCheckExpDateForPrintRep) {
      sendReportsTypes[type]();
    } else {
      setreportStatus(`$inProgress`);
      setSelectedReports(new Set());
      const res = await sendReportsTypes[type](Array.from(selectedReports));

      const onSuccess = () => {
        if (res?.errors?.length) {

          const errorString = res?.errors
            .reduce((acc, elem, i) => {
              return [...acc, [res?.notSendedRollouts[i], elem]];
            }, [])
            .map((item) => item.join(': '))
            .map((str) => <p style={{ margin: '5px 0' }}>{str}</p>);

          showAlert('Ошибки отправки отчетов', errorString);
        } else {
          showAlert('Отправка отчётов', 'Отчёты успешно отправлены');
        }
        getReports(query);
        setreportStatus('success');
      };

      const onError = async () => {
        setreportStatus('error');
        setSelectedReports(new Set())
        setSelectedRows(new Set())
      };

      afterSending(res, onSuccess, onError);
    }
  };

  //============================= send reports =============================

  const syncProducts = async (type) => {
    setSyncStatus(`${type}InProgress`);
    let syncRes;

    if (type === 'suz') {
      syncRes = await ProductsAPI.setGetReportStatus();
    }

    if (syncRes.statusCode === 200) {
      await getReports(query);
      setSyncStatus('success');
    } else {
      setSyncStatus('error');
    }
  };

  // ======= ON SELECT REPORT ==========================
  const onSelectReport = (report) => {
    if (!isShipped(report?.status)) {
      const newSelectedReports = new Set(selectedReports);
      if (selectedReports.has(report.id)) {
        newSelectedReports.delete(report.id);
      } else {
        newSelectedReports.add(report.id);
      }
      setSelectedReports(newSelectedReports);
    }
  };

  // ======= ON SELECT REPORT ==========================

  const onSubmitPrintReport = async (data) => {
    const resData = data.map((rep) => ({
      id: rep.id,
      productCreationDate: rep.productCreationDate,
      expirationDate: rep.expirationDate
    }));
    await ReportsAPI.senPrinRepsParams(resData);
    await getReports(query);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getReports,
      query,
      setQuery,
      headerHeight: 200
    });

  const showButton = false;

  //проверяет какие заголовки и конвертирует даты
  const items = reports.map((report) => {
    const item = {};
    typesInfo[type].tableHeader.forEach(header => {

      const fieldName = header.name;
      let fieldValue = report[fieldName];

      switch (fieldName) {
        case 'creationDate':
        case 'sendingDate':
        case 'productCreationDate':
        case 'actionDate':
        case 'sourceDocDate':
          item[fieldName] = {
            label: fieldValue ? formatTwoPartDate(fieldValue).mainPart : null,
            id: report.id,
          };
          break;
        case 'status':
          item[fieldName] = { label: fieldValue || 'UNKNOWN', id: report.id, errorMessage: report?.errorMessage };
          break;
        case 'numberMarkingCodes':
          item[fieldName] = { label: fieldValue !== undefined ? String(fieldValue) : null, id: report.id };
          break;
        default:
          item[fieldName] = { label: fieldValue, id: report.id };
      }
    });
    return item;
  });

  //создает колонки с заголовками, настраивает данные под сортировку и фильтрует массив
  const columns = typesInfo[type].tableHeader?.filter(column => {
    if (!genSettingsFromRedx?.specifySeriesWhenCreatingShiftTask) {
      return column.name !== 'serialNumber'
    }
    return true
  }).map((column) => ({
    id: column.id,
    columnId: column.name,
    label: column.title,
    compare: (a, b) => {
      // Обработка числовых значений
      if (column.name === 'numberMarkingCodes') {
        return Number(a[column.name].label) - Number(b[column.name].label);
      }

      // Обработка дат
      const dateFields = ['creationDate', 'sendingDate', 'productCreationDate', 'actionDate', 'sourceDocDate'];
      if (dateFields.includes(column.name)) {
        // Преобразуем строки дат в формате "DD.MM.YYYY" в timestamp
        const parseDateStr = (dateStr) => {
          if (!dateStr) return null;
          const [day, month, year] = dateStr.split('.');
          return new Date(year, month - 1, day).getTime();
        };

        const timestampA = parseDateStr(a[column.name]?.label);
        const timestampB = parseDateStr(b[column.name]?.label);

        // Обработка null значений
        if (!timestampA && !timestampB) return 0;
        if (!timestampA) return 1;
        if (!timestampB) return -1;

        return timestampA - timestampB;
      }

      // Обработка остальных строковых значений
      return (a[column.name]?.label ?? '').localeCompare(b[column.name]?.label ?? '');
    },
  }));

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
    selection: {
      allRowsSelected,
      someRowsSelected,
      toggleAllRows,
      toggleRow,
      isRowSelected,
    },
  } = useTableFeatures(
    {
      columns,
      items,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: columns[0].columnId, sortDirection: "ascending" },
      }),
      useTableSelection({
        selectionMode: "multiselect",
        selectedItems: selectedRows,
        onSelectionChange: (e, data) => {
          setSelectedRows(data.selectedItems);
        }
      }),
    ]
  );

  const headerSortProps = (columnId) => ({
    onClick: (e) => {
      toggleColumnSort(e, columnId);
    },
    sortDirection: getSortDirection(columnId),
  })

  const rows = sort(getRows((row) => {
    const selected = isRowSelected(row.rowId);
    return {
      ...row,
      onClick: (e) => {
        if (!isShipped(reports[row.rowId]?.status)) {
          onSelectReport(reports[row.rowId])
          toggleRow(e, row.rowId);
        }
      },
      onKeyDown: (e) => {
        if (e.key === " ") {
          e.preventDefault();
          toggleRow(e, row.rowId);
        }
      },
      selected,
      appearance: selected ? ("brand") : ("none"),
    };
  }));

  const renderRows = (column, item) => {

    switch (item[column.columnId]?.label && column.columnId) {
      case 'status':
        switch (item[column.columnId]?.label) {
          case 'CREATED':
            return <img src={СreatedIcon} alt="Создан" />;
          case 'SENDED':
            return <img src={SendedIcon} alt="Отправлен" />;
          case 'DISAGGREGATED':
            return <img src={FragmentedIcon} alt="Разагрегирован" />;
          case 'ACCEPTED':
            return <img src={SuccessfullyProcessedIcon} alt="Успешно обработан" />;
          case 'REJECTED':
            return (
              <Tooltip content={`Текст ошибки: ${item[column.columnId]?.errorMessage}`}>
                <img src={ProcessedWithErrorIcon} alt="Обработан с ошибкой" />
              </Tooltip>
            );
          case 'ACCEPTED_ARCHIVED':
            return <img src={SuccessfullyProcessedAndKmArchivedIcon} alt="Успешно обработан и КМ архивированы" />;
          case 'CREATED_ARCHIVED':
            return <img src={CreatedAndKmArchivedIcon} alt="Создан и КМ архивированы" />;
          case 'REJECTED_ARCHIVED':
            return <img src={ProcessedWithErrorKmArchivedIcon} alt="Обработан с ошибкой и КМ архивированы" />;
          default:
            return item[column.columnId]?.label;
        }
      case 'productName':
        return <LinkELement
          addStyles={clsx(g.tableRow, s.firstRow)}
          link={`/reports/printing/${item?.productName?.id}`}
          text={item?.productName?.label}
          params={{
            id: item?.productName?.id,
            type: 'printing'
          }}
          appearance={'subtle'}
        >
        </LinkELement>
      default:
        return <p>{item[column.columnId]?.label}</p>
    }
  };

  useEffect(() => {
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  //создает query для фильтров
  useEffect(() => {
    const filterData = [
      { label: 'Компания', key: 'companyId', options: companiesForDropdown },
      { label: 'Статус', key: 'status', options: reportStatuseForPrinting },
      {
        label: 'Дата создания от',
        key: 'minCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата создания до',
        key: 'maxCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата отправки от',
        key: 'minSendingDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата отправки до',
        key: 'maxSendingDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства КМ от',
        key: 'minProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        label: 'Дата производства КМ до',
        key: 'maxProductCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, label: filter.label }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, companiesForDropdown]);

  const renderSortIcon = (columnId) => {
    const sortDirection = getSortDirection(columnId);
    if (sortDirection === 'ascending') {
      //у библиотеки есть дефолтная иконка
    }
    else if (sortDirection === 'descending') {
      //у библиотеки есть дефолтная иконка
    } else {
      return (
        <div>
          <ArrowDownRegular style={{ height: '12px', width: '12px' }} />
        </div>
      );
    }
  };

  const RenderRow = ({ index, style, data }) => {
    const { item, selected, appearance, onClick, onKeyDown } = data[index];

    return (
      <TableRow
        key={item.id}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-selected={selected}
        className={clsx(s.tableRow, !isShipped(item?.status?.label) ? (selected && s.tableSelectionCell) : s.disabledCheckbox)}
        appearance={appearance}
      >
        <TableSelectionCell
          checked={selected}
          style={{ width: '44px' }}
          checkboxIndicator={{ "aria-label": "Select row" }}
          className={isShipped(item?.status?.label) ? s.disabledCheckbox : ''}
        />
        {columns.map((column) => (
          <TableCell
            id={s[column.columnId]}
            key={column.columnId}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {column.columnId !== "status" ? (
              <Tooltip showDelay={700} hideDelay={0} content={item[column.columnId]?.label}>
                <div>{renderRows(column, item)}</div>
              </Tooltip>
            ) : (
              <div>{renderRows(column, item)}</div>
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <div className={s.tableWrapper}>
      {isAlertShowed && (
        <Alert
          title={alertTitle}
          text={alertText}
          onClose={() => {
            hideAlert();
          }}
        />
      )}
      {isPrintRepModal ? (
        <PrintReportModal
          isOpen={isPrintRepModal}
          setIsOpen={setIsPrintModal}
          reports={reports.filter((r) => selectedReports.has(r.id))}
          onDismiss={() => {
            setIsPrintModal(false);
            setSelectedReports(new Set());
            setSelectedRows(new Set());
          }}
          onSubmit={onSubmitPrintReport}
        />
      ) : null}
      <>
        <div className={g.titleWrapper}>
          <div className={` ${g.searchBlockWrapper}`}>
            {type !== 'sets' && (
              <SearchBlock
                iconProps={''}
                styles={searchBoxStyles}
                value={query.searchValue}
                placeholder="Поиск по коду отчёта, названию товара, gtin или номеру задания"
                onClear={() => onSearchTermChanged('')}
                onSearch={(newValue) => onSearchTermChanged(newValue)}
              />
            )}
          </div>
          <div isFiltersDisplayed>
            <ButtonToggle
              text="Фильтры"
              value={isOpenFilters}
              onClick={() => setIsOpenFilters(!isOpenFilters)}
              optionSize={selectedOptions.length}
            />
          </div>
        </div>
        {isOpenFilters && (
          <div className={g.filtersWrapper}>
            <ReportsFilterNew
              query={query}
              setQuery={setQuery}
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              type={type}
              companiesForDropdown={companiesForDropdown}
              setCompaniesForDropdown={setCompaniesForDropdown}
            />
          </div>
        )}

        <div className={g.footer} style={{ margin: ' 0.75rem 0  0.75rem 1rem' }}>
          <LinkButton
            isButton={true}
            text="Отправить выбранные"
            primary={true}
            onClick={() => sendReports()}
            disabled={!selectedReports.size}
          />
        </div>

        <div className={s.tableBlock}>
          {!rows.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
          ) : (
            <div className={s.tableContainer} style={{ padding: '0 0.325rem' }}>
              {!loading ? (
                <Table
                  sortable
                  aria-label="Table with sort and selection"
                  className={s.table}
                  noNativeElements
                >
                  <TableHeader className={s.tableHeader}>
                    <TableRow style={{ display: 'flex', width: '100%' }}>
                      <div style={{ width: '44px' }}></div>
                      {columns?.map((column) => (
                        <TableHeaderCell
                          id={s[column.columnId]}
                          key={column.columnId}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          {...headerSortProps(column.columnId)}
                        >
                          {column.label}
                          {renderSortIcon(column.columnId)}
                        </TableHeaderCell>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <List
                      height={window.innerHeight - 420}
                      itemCount={rows.length}
                      itemSize={44}
                      width="100%"
                      itemData={rows}
                      className={s.ReactWindow}
                      overscanCount={5}
                    >
                      {RenderRow}
                    </List>
                  </TableBody>
                </Table>
              ) : (
                <div className={s.spinner}>
                  <Spinner size="huge" />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={g.footer}>
          {totalPages > 1 && (
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
        </div>
        {/* <div className={s.syncButtons}>
          {showButton && (
            <TableAction
              iconName={'Sync'}
              addStyles={{ width: '18.75rem', marginTop: '10px' }}
              disabled={syncStatus === 'suzInProgress'}
              isButton={true}
              onClick={() => syncProducts('suz')}
              text="Синхронизировать СУЗ"
              type={'accent'}
            />
          )}
        </div> */}
      </ >
      {
        reportStatus?.includes('inProgress') && (
          <WaitingScreen
            title={'Выполняется отправка отчётов'}
            text={
              'Пожалуйста, подождите. Это может занять несколько минут. Не закрывайте эту страницу.'
            }
          />
        )
      }
      {
        ['success', 'error'].includes(syncStatus) && (
          <SyncStatus syncStatus={syncStatus} hide={() => setSyncStatus(null)} />
        )
      }
    </div >
  );
};
