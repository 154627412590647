import { useState, useCallback } from 'react';
import BalanceDetails from './BalanceDetails';
import { returntbelanceDetails } from './mockData';
import { getUpdatedMinBalances } from 'redux/App/appSelectors';
import productsAPI from 'api/productsAPI';
import { BottomButtons } from 'components/BottomButtons/BottomButtons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetMinBalance } from 'redux/App/appReducer';
import { Alert } from 'components/Alert/Alert';
import { useEffect } from 'react';
import { setLowBalance, sendMinBalances } from 'redux/App/appReducer';
import s from './balance.module.scss';
import g from 'assets/scss/Main.module.scss';
import {
  getChestZBalances,
  getIsAppLoading,
  getIsStillSendingBalance,
  getGlobalErrorText
} from 'redux/App/appSelectors';
import { setIsStillSendingBalance } from 'redux/App/appReducer';
import { Loader } from 'components/Loader/Loader';

const Balance = () => {
  const allBalances = useSelector(getChestZBalances);
  const [showConfirmModal, setShowModal] = useState(false);
  const getBalance = () => allBalances;
  const updatedBalances = useSelector(getUpdatedMinBalances);
  const isLoading = useSelector(getIsAppLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendingBalanceStarted, setSending] = useState(false);
  const isStillSendingBalance = useSelector(getIsStillSendingBalance);
  const globalErrorText = useSelector(getGlobalErrorText);

  useEffect(() => {
    if (globalErrorText) {
      setShowModal(false);
    }
  }, [globalErrorText]);

  useEffect(() => {
    return () => {
      if (!!updatedBalances.length) dispatch(resetMinBalance());
    };
  }, []);

  const updateMinBalance = async () => {
    if (!!updatedBalances.length) {
      const arr = [];
      updatedBalances.forEach((bal) => {
        const isCompanyIdObj = !!arr.find((obj) => obj.companyId === bal.companyId);
        const companyIdObj = isCompanyIdObj
          ? arr.find((obj) => obj.companyId === bal.companyId)
          : {
            companyId: bal.companyId,
            items: []
          };
        const minBalanceObj = {
          productGroup: Number(bal.productGroup),
          minimumBalance: Number(bal.minBalance)
        };
        companyIdObj.items.push(minBalanceObj);

        if (!isCompanyIdObj) arr.push(companyIdObj);
      });
      dispatch(sendMinBalances(arr));
      dispatch(setIsStillSendingBalance(true));
      setShowModal(true);
    } else {
      return;
    }
  };

  const onCloseModal = () => {
    if (isLoading === false) dispatch(resetMinBalance());
    setShowModal(false);
  };

  const onGoingBack = () => {
    dispatch(resetMinBalance());
    history.goBack();
  };

  return (
    <div className={g.contentWrapper}>
      <h1 className={g.title}>Баланс денежных средств УОТ в системе Честный знак.</h1>
      {allBalances?.map((details) => (
        <BalanceDetails key={details?.companyId} data={details} getBalance={getBalance} />
      ))}
      <section className={s.footer}>
        <BottomButtons
          okText={'Сохранить'}
          okHandler={updateMinBalance}
          closeText={'Назад'}
          closeHandler={onGoingBack}
        // disabledButtons={[updatedBalances.length === 0, false]}
        />
      </section>{' '}
      {showConfirmModal && !isStillSendingBalance && !isLoading && !globalErrorText && (
        <Alert
          title={'Изменение параметров уведомлений'}
          text={'Сумма остатка успешна изменена'}
          onClose={onCloseModal}
        />
      )}
    </div>
  );
};

export default Balance;
