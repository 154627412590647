import { Pagination } from 'components/Pagination/Pagination';
import { Table } from 'components/Table/Table';
import { TableAction } from 'components/TableAction/TableAction';
import { Checkbox, SearchBox } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ProductsAPI from '../../../api/productsAPI';
import { codeFilters, orderCodesIcon, orderStatuses, searchBoxStyles } from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { getDateFromTimestamp, getStatusById } from '../../../functions';
import { useCheckboxFilter, usePaginationAndSearch } from '../../../hooks';
import { cellWidth } from '../../../functions';
import g from '../../../assets/scss/Main.module.scss';
import s from './CodesList.module.scss';

const tableHeader = [
  {
    title: 'Код маркировки',
    width: '0.3'
  },
  {
    title: 'Заказан',
    width: '0.14'
  },
  {
    title: 'Нанесён',
    width: '0.14'
  },
  {
    title: 'Добавлен в агрегат',
    width: '0.14'
  },
  {
    title: 'Введён в оборот',
    width: '0.14'
  },
  {
    title: 'Код агрегата',
    width: '0.14'
  }
];

export const CodesList = ({ isOpenFilters, setIsOpenFilters, status, statusParamName }) => {
  const { setCrumbs } = useCrumbsContext();
  const { state: params, pathname, href } = useLocation();
  const [_status, _setStatus] = useState(status);
  const [_statusParamName, _setStatusParamName] = useState(statusParamName);
  const [codes, setCodes] = useState([]);
  const [_codesFilters] = useState(codeFilters);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(null);

  const [savedFilterValues, setSavedFilterValues] = useState({
    minAggregationDate: null,
    maxAggregationDate: null,
    minUtilisationDate: null,
    maxUtilisationDate: null,
    minCreationDate: null,
    maxCreationDate: null,
    minRolloutDate: null,
    maxRolloutDate: null
  });

  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    quantityOnPage: NumberParam,
    productId: NumberParam,
    orderId: NumberParam,
    status: StringParam,
    searchValue: StringParam,
    minAggregationDate: NumberParam,
    maxAggregationDate: NumberParam,
    minUtilisationDate: NumberParam,
    maxUtilisationDate: NumberParam,
    minCreationDate: NumberParam,
    maxCreationDate: NumberParam,
    minRolloutDate: NumberParam,
    maxRolloutDate: NumberParam
  });

  useEffect(() => {
    if (query.productId) {
      setCrumbs(['/products', 'Список КМ продукта']);
    }

    if (query.orderId) {
      setCrumbs(['/orders', 'Список КМ в заказе']);
    }
  }, [query]);

  useEffect(() => {
    if (params) {
      if (params.status && !query.status) {
        setQuery((prev) => ({ ...prev, status: params.status }), 'replace');
      }

      if (params.orderId) {
        setQuery((prev) => ({ ...prev, productId: undefined, orderId: params.orderId }), 'replace');
      }

      if (params.productId) {
        setQuery(
          (prev) => ({ ...prev, orderId: undefined, productId: params.productId }),
          'replace'
        );
      }
    } else {
      // setQuery({})
    }
  }, [params]);

  const getCodes = async (query) => {
    setLoading(true);
    const res = await ProductsAPI.getCodesForProduct(query);
    const { list, quantity, info } = res;
    setCodes(list);
    setTotalItems(quantity);
    setInfo(info);
    await setLoading(false);
  };

  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    _codesFilters,
    'status',
    setQuery,
    query
  );
  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getCodes,
      query,
      setQuery,
      status: status || params?.status,
      statusParamName: statusParamName || params?.statusParamName
    });

  return (
    <>
      {/*{params ?*/}
      <div className={g.contentWrapper}>
        <div className={g.titleWrapper}>
          {query.orderId && info && (
            <h1 className={g.title}>
              {`Заказ ${info?.suzId || 'без номера'} (${getStatusById(info?.status, orderStatuses) || ''
                })` || ''}
            </h1>
          )}
          {query.productId && info && <h1 className={g.title}>{info?.name || ''}</h1>}
          {query.productId && info && (
            <TableAction
              iconName={orderCodesIcon}
              link="/products/orderCode"
              params={{
                productName: info?.name,
                templateId: info?.templateId,
                productGtin: info?.gtin,
                prevRoute: `${pathname}${href}`
              }}
              text="Заказать КМ"
            />
          )}
        </div>
        {info?.gtin && (
          <span className={g.code}>
            {params?.orderId ? `${info?.name} (GTIN: ${info?.gtin})` : `GTIN: ${info?.gtin}`}
          </span>
        )}
        <div className={s.header} style={{ marginTop: 20 }}>
          <div className={s.headerSearch}>
            <span className={g.headerTitleWrapper}>Коды маркировки</span>
            <SearchBox
              styles={searchBoxStyles}
              className={s.search}
              placeholder="Поиск по коду маркировки"
              onClear={() => {
                onSearchTermChanged('');
              }}
              onSearch={(newValue) => onSearchTermChanged(newValue)}
            />
          </div>
          <div className={g.headerFilters} style={{ marginTop: 20 }}>
            {codeFilters?.map((item) => {
              return (
                <Checkbox
                  key={item.id}
                  label={item.title}
                  checked={selectedFilters.has(item.key)}
                  onChange={() => onChangeFilter(item.key)}
                />
              );
            })}
          </div>
        </div>
        <Table headerItems={tableHeader} maxHeight="60vh" loading={loading}>
          {codes?.map((item) => {
            return (
              <div key={item.id} className={g.tableItem}>
                <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 0) }}>
                  {item.value}
                </div>
                <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 1) }}>
                  {getDateFromTimestamp(item.creationDate)}
                </div>
                <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 2) }}>
                  {getDateFromTimestamp(item.utilisationDate)}
                </div>
                <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 3) }}>
                  {getDateFromTimestamp(item.aggregationDate)}
                </div>
                <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 4) }}>
                  {getDateFromTimestamp(item.rolloutDate)}
                </div>
                <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 5) }}>
                  {item?.unitSerialNumber}
                </div>
              </div>
            );
          })}
        </Table>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
      {/*: <Redirect to='/products'/>}*/}
    </>
  );
};
