import s from './AutoUpdate.module.scss';
import React, { memo, useEffect, useState } from 'react';
import { Alert } from 'components/Alert/Alert';
import { Confirm } from 'components/Confirm/Confirm';
import { NewAlert } from './Autoupdate.components/NewAlert';
import { Loader } from 'components/Loader/Loader';
import { BasePicker } from '@fluentui/react';
import releaseApi from 'api/ReleaseApi';
import CountDownModal from './Autoupdate.components/CoundownModal';
import { useDispatch, useSelector } from 'react-redux';
import { setShouldRetry } from 'redux/App/releaseActionCreators';
import {
  getShouldRetryWS,
  getReleaseVersion,
  getReleaseIntallStatus
} from 'redux/App/appSelectors';
//import { getLineStatus } from "redux/Line/lineSelectors";
import EmtpyDiv from './Autoupdate.components/EmptryDiv';
import { handleErrorFunc } from 'App';
import { getStatusInfoText } from './Autoupdate.constants';

let retryTimeout;

const AutoUpdateButton = ({ isUpdate }) => {
  const shouldRetry = useSelector(getShouldRetryWS);
  const releaseVersion = useSelector(getReleaseVersion);
  const releaseIntallationStatus = useSelector(getReleaseIntallStatus);
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateCD, setShowUpdateCD] = useState(false);
  const [startChecking, setStartChecking] = useState(false);
  const [error, setError] = useState(false);
  const [isUpdStarted, setIsUpdStarted] = useState(false);
  // const lineStatus = useSelector(getLineStatus);

  const isWorkingLine = () => {
    //return lineStatus === 'RUN' || lineStatus === 'PAUSE';
    return false;
  };

  const handleErrorAlertClose = () => setError(false);

  const showModalWloader = () => {
    if (!isWorkingLine()) {
      setShowUpdateCD(false);
      setShowAlert(true);
      setIsLoading(true);
    }
  };

  function startRetry(ms) {
    const reTry = () => {
      setStartChecking(true);
      dispatch(setShouldRetry(true));
    };
    // step 4
    retryTimeout = setTimeout(() => reTry(), ms);
  }

  // step 1
  const showModalNoLoader = () => {
    if (!isWorkingLine()) {
      setShowUpdateCD(false);
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  // step 2
  const startUpdate = async () => {
    if (!isWorkingLine()) {
      try {
        setShowAlert(true);
        setIsLoading(true);
        await releaseApi.startUpdate();
        setShowAlert(false);
        setIsLoading(false);
        setIsUpdStarted(true);
        setShowUpdateCD(true);
      } catch (e) {
        console.log('error-from-startUpdate', e);
        hideAll();
        setError(true);
      }
    }
  };

  // step 3
  const onStartLoader = async () => {
    try {
      showModalWloader();
      await releaseApi.confirmUILock();
      localStorage.setItem('UPDATE_STATUS', 'STARTED');
      startRetry(360000);
    } catch (e) {
      hideAll();
      setError(true);
    }
  };

  // step 5
  const afterUpdate = () => {
    clearTimeout(retryTimeout);
    retryTimeout = 'canceled';
    localStorage.setItem('UPDATE_STATUS', 'PAGE_REFRESHED');
    window.onbeforeunload = function () {};
    window.location.reload(false);
  };

  const hideAll = () => {
    setShowUpdateCD(false);
    setShowAlert(false);
    setIsLoading(false);
    setStartChecking(false);
    clearTimeout(retryTimeout);
    setIsUpdStarted(false);
    localStorage.setItem('UPDATE_STATUS', 'DID_NOT_START');
  };

  const hideModal = () => {
    if (isLoading) {
      return;
    } else {
      setShowAlert(false);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem('UPDATE_STATUS') === 'STARTED' ||
      localStorage.getItem('UPDATE_STATUS') === 'PAGE_REFRESHED'
    ) {
      if (releaseIntallationStatus > 6) {
        showModalWloader();
      }
    }
  }, []);

  useEffect(() => {
    if (startChecking && !shouldRetry) {
      afterUpdate();
    }
  }, [startChecking, shouldRetry]);

  const finishResUpd = async (isFirstTime) => {
    if (isFirstTime) {
      afterUpdate();
    } else {
      try {
        setStartChecking(false);
        clearTimeout(retryTimeout);
        await releaseApi.confirmFinishUpdate();
      } catch (e) {
        console.log('error-from-confirmFinishUpdate', e);
        hideAll();
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (releaseIntallationStatus > 0 && releaseIntallationStatus < 7) {
      localStorage.setItem('UPDATE_STATUS', 'DID_NOT_START');
      if (releaseIntallationStatus < 5) {
        console.log('releaseIntallationStatus < 5 fired');
        setShowAlert(false);
      }
    }
    if (releaseIntallationStatus === 6 && !isUpdStarted) {
      console.log('releaseIntallationStatus === 6 fired');
      startUpdate();
    }
    if (
      isUpdate &&
      (releaseIntallationStatus === 14 ||
        releaseIntallationStatus === 15 ||
        releaseIntallationStatus === 16)
    ) {
      if (localStorage.getItem('UPDATE_STATUS') === 'PAGE_REFRESHED') {
        finishResUpd(false);
      } else {
        finishResUpd(true);
      }
    }
    if (releaseIntallationStatus === 9) {
      hideAll();
    }
    if (
      releaseIntallationStatus === 7 ||
      releaseIntallationStatus === 8 ||
      releaseIntallationStatus === 13
    ) {
      hideAll();
      setError(true);
    }
  }, [releaseIntallationStatus]);

  return (
    <>
      {isUpdate ? (
        <main className={s.autoUpdateButton}>
          <span className={s.autoUpdateButtonText} onClick={showModalNoLoader}>
            Доступно обновление
          </span>
          {showAlert && (
            <Confirm
              text={
                isLoading
                  ? 'Идет установка новой версии ПО, пожалуйста, не перегружайте страницу!'
                  : getStatusInfoText(releaseIntallationStatus, releaseVersion)
              }
              agreeText={releaseIntallationStatus === 5 ? 'Установить' : ''}
              onAgree={releaseIntallationStatus === 5 ? startUpdate : null}
              onDisagree={() => setShowAlert(false)}
              disagreeText={'Закрыть'}
              onClose={() => setShowAlert(false)}
              isLoading={isLoading}
            >
              <EmtpyDiv
                isLoading={isLoading}
                customStyle={
                  isLoading
                    ? {
                        height: '5rem'
                      }
                    : {}
                }
              />
            </Confirm>
          )}
          {showUpdateCD && (
            <Confirm
              text={''}
              onDisagree={hideModal}
              disagreeText={'Закрыть'}
              onClose={() => {
                return;
              }}
              isLoading={true}
            >
              <CountDownModal hideModal={hideAll} onStartLoader={onStartLoader} />
            </Confirm>
          )}
          {error && (
            <Alert
              text="Не удалось запустить установку обновления"
              closeText="OK"
              onClose={handleErrorAlertClose}
            />
          )}
        </main>
      ) : null}
    </>
  );
};

export default memo(AutoUpdateButton);
