import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import s from './Pagination.module.scss';
import chevron_left from '../../assets/img/ChevronLeft.svg';
import chevron_right from '../../assets/img/ChevronRight.svg';
import { makeStyles, tokens, useId, Input, Label } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    marginLeft: '14px',
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    width: '40px',
    maxHeight: "32px",
    borderColor: 'none',

    "& input": {
      textAlign: "center",
    },

  },
});


export const Pagination = ({ pageCount, onPageChange, selectedPage }) => {
  const styles = useStyles();
  const [inputValue, setInputValue] = useState(1)

  const handlePageClick = (data) => {
    let selectedPage = data.selected + 1;
    onPageChange(selectedPage);
    setInputValue(selectedPage)
  };

  const hadleInputValue = async (e) => {
    if (e.target.value != 0) {
      let selectedPage = Number(e.target.value);
      onPageChange(selectedPage)
    }
  }

  return (
    <div className={s.paginationBlock}>
      <ReactPaginate
        previousLabel={<img src={chevron_left} alt="chevron left icon" className={s.chevronIcons} />}
        nextLabel={<img src={chevron_right} alt="chevron left icon" className={s.chevronIcons} />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        forcePage={selectedPage - 1}
        initialPage={selectedPage - 1}
        onPageChange={handlePageClick}
        containerClassName={s.pagination}
        subContainerClassName={'pages pagination'}
        activeClassName={s.paginationActivePage}
      />

      <div className={s.paginationBlockElement}>
        <p>Страница</p>
        <Input
          type='number'
          onFocus={() =>
            setInputValue('')}
          className={styles.root}
          onKeyPress={(e) => hadleInputValue(e)}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          onBlur={inputValue == 0 ? () => setInputValue(selectedPage) : (e) => hadleInputValue(e)}
        />      </div>
    </div>
  );
};

