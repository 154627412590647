import { initializeIcons } from '@fluentui/font-icons-mdl2';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import g from './assets/scss/Main.module.scss';
import { Alert } from './components/Alert/Alert';
import { Crumbs } from './components/Crumbs/Crumbs';
import { Header } from './components/Header/Header';
import { Loader } from './components/Loader/Loader';
import { Menu } from './components/Menu/Menu';
import { AppProvider } from './context/AppContext';
import { CrumbsProvider, useCrumbsContext } from './context/CrumbsContext';
import { Aggregate } from './pages/Aggregates/Aggregate/Aggregate';
import { Aggregates } from './pages/Aggregates/Aggregates';
import { Auth } from './pages/Auth/Auth';
import { Consignment } from './pages/Consignments/Consignment/Consignment';
import { Consignments } from './pages/Consignments/Consignments';
import { Debug } from './pages/Debug/Debug';
import { Line } from './pages/Line/Line';
import { Lines } from './pages/Lines/Lines';
import { CodesList } from './pages/Products/CodesList/CodesList';
import { OrderCode } from './pages/Products/OrderCode/OrderCode';
import { LogsPage } from 'pages/Logs';
import { Orders } from './pages/Products/Orders/Orders';
import { Products } from './pages/Products/Products';
import { ProductsCard } from './pages/Products/ProductsCard/ProductsCard';
import { Report } from './pages/Report/Report';
import { Reports } from './pages/Reports/Reports';
import { Settings } from './pages/Settings/Settings';
import { User } from './pages/User/User';
import { Users } from './pages/Users/Users';
import LegalFacesPage from './pages/LegalFaces';
import ProductOwners from 'pages/ProductOwners';
import AddCompany from 'pages/Company/createCompanyForm';
import AddProductOwner from 'pages/ProductOwners/AddProductOwners';
import WriteoffSettings from 'pages/WriteoffSettings';
import MainSettings from 'pages/MainSettings';
import { setGlobalErrorTextAC } from './redux/App/appReducer';
import { getGlobalErrorText, getIsInitialized, getIsAppLoading } from './redux/App/appSelectors';
import { checkLoggedAC, logoutAC, setLoadingAC, setLoggedAC } from './redux/Auth/authReducer';
import { getIsLogged } from './redux/Auth/authSelectors';
import { store } from './redux/index';
import Balance from 'pages/Balance';
import Company from './pages/Company';
import {
  requestAggregationIncluded,
  getAllBalances,
  checkIfLowBalance
} from 'redux/App/appReducer';
import { ignoreErrors } from 'functions';
import { getSurName } from './redux/Auth/authSelectors';
import { runReleaseWS } from 'redux/App/releaseActionCreators';
import { getShouldRetryWS } from './redux/App/appSelectors';
import GlobalSuccessAlert from 'globalSuccessAlert';
import { getIsDb } from './redux/App/appReducer';
import { FluentProvider, Toaster, webLightTheme } from '@fluentui/react-components';

const statuses = [];

initializeIcons();
export const handleErrorFunc = (statusCode, message) => {
  if (ignoreErrors(statusCode, message)) return;
  if (!statusCode) {
    if (message?.message?.includes('Network')) {
      store.dispatch(setGlobalErrorTextAC('Отсутствует соединение с сервером'));
    } else {
      store.dispatch(setGlobalErrorTextAC(message?.message));
    }
  } else if (statusCode === 401) {
    store.dispatch(logoutAC());
  } else {
    if (statusCode === 403) {
      store.dispatch(setLoggedAC(false));
    } else {
      store.dispatch(setGlobalErrorTextAC(message));
    }
  }
  store.dispatch(setLoadingAC(false));
};

let retryTimeout;

function App({ location }) {
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [isGlobalAlertShowed, setIsGlobalAlertShowed] = useState(false);
  const logged = useSelector(getIsLogged);
  const globalErrorText = useSelector(getGlobalErrorText);
  const initialized = useSelector(getIsInitialized);
  const dispatch = useDispatch();
  const isAppLoading = useSelector(getIsAppLoading);
  const isAuthLoading = useSelector(getSurName);
  const shouldRetryWS = useSelector(getShouldRetryWS);
  const { toasterId } = useCrumbsContext();

  useEffect(() => {
    if (globalErrorText) {
      setIsGlobalAlertShowed(true);
    }
  }, [globalErrorText]);

  useEffect(() => {
    dispatch(checkLoggedAC());
  }, [dispatch]);

  useEffect(() => {
    if (shouldRetryWS) {
      clearTimeout(retryTimeout);
      retryTimeout = setInterval(() => {
        dispatch(runReleaseWS());
      }, 5000);
    } else {
      clearTimeout(retryTimeout);
    }
  }, [shouldRetryWS]);

  useEffect(() => {
    if (logged) {
      dispatch(getAllBalances());
      dispatch(checkIfLowBalance());
      dispatch(requestAggregationIncluded());
      dispatch(runReleaseWS());
      dispatch(getIsDb());
    }
  }, [logged, dispatch]);

  const logout = () => {
    dispatch(logoutAC());
  };
  const { setCrumbs, notify } = useCrumbsContext();

  return (
    <AppProvider>
      <FluentProvider theme={webLightTheme}>
        <CrumbsProvider>
          <div className={g.app}>
            <GlobalSuccessAlert />
            {(!initialized || isAppLoading) && <Loader filled={true} />}
            {isGlobalAlertShowed && (
              <Alert
                title="Ошибка"
                type={'error'}
                text={`Произошла ошибка: ${globalErrorText}`}
                onClose={() => {
                  dispatch(setGlobalErrorTextAC(''));
                  setIsGlobalAlertShowed(false);
                }}
              />
            )}
            {!!isAuthLoading && logged ? (
              <>
                <Header logout={logout} />
                <div className={g.container}>
                  <Menu />
                  <div className={g.wrapper}>
                    <Crumbs setIsOpenFilters={setIsOpenFilters} />
                    <main className={g.content}>
                      <Switch location={location}>
                        <Route exact path="/" render={() => <Redirect to="/products" />} />
                        <Route
                          exact
                          path="/products"
                          render={() => (
                            <Products
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/products/orderCode/:productId?"
                          render={() => <OrderCode />}
                        />
                        <Route
                          exact
                          path="/products/codesList"
                          render={() => (
                            <CodesList
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/products/productCard/:productId?"
                          render={() => <ProductsCard />}
                        />
                        <Route exact path="/products/orders/:id?" render={() => <Orders />} />
                        <Route
                          exact
                          path="/reports/printing"
                          render={() => (
                            <Reports
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                              type="printing"
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/reports/aggregation"
                          render={() => (
                            <Reports
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                              type="aggregation"
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/reports/entering"
                          render={() => (
                            <Reports
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                              type="entering"
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/reports/:reportType/:reportId"
                          render={() => <Report />}
                        />
                        <Route
                          exact
                          path="/reports/rejection"
                          render={() => (
                            <Reports
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                              type="rejection"
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/reports/sets"
                          render={() => (
                            <Reports
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                              type="sets"
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/reports/withrdIntrnlConsmpt"
                          render={() => (
                            <Reports
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                              type="withrdIntrnlConsmpt"
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/reports/writeOff"
                          render={() => (
                            <Reports
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                              type="writeOff"
                            />
                          )}
                        />
                        <Route exact path="/balance" render={() => <Balance />} />
                        <Route
                          exact
                          path="/lines"
                          render={() => (
                            <Lines
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                            />
                          )}
                        />
                        <Route exact path="/lines/:lineID?" render={() => <Line />} />
                        <Route
                          exact
                          path="/users"
                          render={() => (
                            <Users
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                            />
                          )}
                        />
                        <Route exact path="/users/:userID?" render={() => <User />} />
                        <Route exact path="/debug" render={() => <Debug />} />
                        <Route exact path="/settings/legal" render={() => <LegalFacesPage />} />
                        <Route exact path="/settings/legal/add" render={() => <AddCompany />} />
                        <Route exact path="/settings/legal/:faceID?" render={() => <Company />} />
                        <Route exact path="/settings/productowners" render={() => <ProductOwners />} />
                        <Route exact path="/settings/productowners/add" render={() => <AddProductOwner />} />
                        <Route exact path="/settings/writeoffSettings" render={() => <WriteoffSettings />} />
                        <Route exact path="/settings/mainSettings" render={() => <MainSettings />} />
                        <Route
                          exact
                          path="/settings/suz"
                          render={() => <Settings settingsType={'suz'} />}
                        />
                        <Route
                          exact
                          path="/settings/gismt"
                          render={() => <Settings settingsType={'gismt'} />}
                        />
                        <Route
                          exact
                          path="/settings/catalog"
                          render={() => <Settings settingsType={'catalog'} />}
                        />
                        <Route
                          exact
                          path="/settings/ftp"
                          render={() => <Settings settingsType={'ftp'} />}
                        />
                        <Route
                          exact
                          path="/settings/database"
                          render={() => <Settings settingsType={'database'} />}
                        />
                        <Route exact path="/settings/logs" render={() => <LogsPage />} />
                        <Route
                          exact
                          path="/consignments"
                          render={() => (
                            <Consignments
                              status={'NEW,IN PROGRESS'}
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/consignments/:consignmentId"
                          render={() => <Consignment />}
                        />
                        <Route
                          exact
                          path="/aggregates"
                          render={() => (
                            <Aggregates
                              statusParamName={'status'}
                              status={'NEW,FORMED,DISAGGREGATED'}
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/aggregates/:aggregationCode"
                          render={() => <Aggregate />}
                        />
                        <Route
                          exact
                          path="/orders"
                          render={() => (
                            <Orders
                              statusParamName={'orderStatus'}
                              status={statuses.toString()}
                              isOpenFilters={isOpenFilters}
                              setIsOpenFilters={setIsOpenFilters}
                            />
                          )}
                        />
                      </Switch>
                    </main>
                  </div>
                </div>
              </>
            ) : (
              <Switch>
                <Route exact path="/debug" render={() => <Debug />} />
                <Auth />
              </Switch>
            )}
          </div>
        </CrumbsProvider>
      </FluentProvider>
    </AppProvider>
  );
}

export default App;