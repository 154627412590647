import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, PrimaryButton } from '@fluentui/react';
import { Accordion } from 'components/Accardion';
import { getLogsAction, resetLogsAction, pollingStartAction } from 'redux/Logs/LogsReducer';
import { logsSelector } from 'redux/Logs/LogsSelectors';
import { useCrumbsContext } from '../../context/CrumbsContext';
import moment from 'moment';
import chevronLeft from '../../assets/img/ChevronLeft.svg';
import chevronRight from '../../assets/img/ChevronRight.svg';
import s from './index.module.scss';

const ELEMS_BY_PAGE = 25;
export const LogsPage = () => {
  const logData = useSelector(logsSelector);
  const { setCrumbs } = useCrumbsContext();

  const logs = logData?.logs?.list ? logData?.logs?.list : [];
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  useEffect(() => {
    setCrumbs([]);
    dispatch(pollingStartAction());
    dispatch(getLogsAction({ pageNumber: 1, ...elementsCol }));
    return () => {
      dispatch(resetLogsAction());
    };
  }, []);
  const disableNext = logs.length < ELEMS_BY_PAGE;
  const gotElements = logs && logs.length > 0;
  const elementsCol = { quantity: ELEMS_BY_PAGE };
  const handleNext = () => {
    const newPage = page + 1;
    dispatch(getLogsAction({ pageNumber: newPage, ...elementsCol }));
    setPage(newPage);
  };
  const handlePrev = () => {
    const newPage = page - 1;
    dispatch(getLogsAction({ pageNumber: newPage, ...elementsCol }));
    setPage(newPage);
  };
  const handleInStart = () => {
    setPage(1);
    dispatch(getLogsAction({ pageNumber: 1, ...elementsCol }));
  };
  const dateTimeRequest = (date) => moment(date).format('DD.MM.YYYY HH:mm');
  const generateTitle = ({ method, url, date, statusCode }) =>
    `${method || ''} ${url || ''} ${date || ''} ${statusCode ? '( ' + statusCode + ' )' : ''}`;
  const generateBodyTitle = ({ type }) => (type === 'REQUEST' ? 'Запроса' : 'Ответа');
  return (
    <div>
      <div className={s.pagination}>
        <PrimaryButton className={s.paginationStart} text={'В начало'} onClick={handleInStart} />
        <div className={s.pageNumber}>
          <button disabled={page === 1} onClick={handlePrev}>
            <img src={chevronLeft} alt="chevron left icon" className={s.chevronIcons} />
          </button>
          <span>{page}</span>
          <button disabled={disableNext} onClick={handleNext}>
            <img src={chevronRight} alt="chevron left icon" className={s.chevronIcons} />
          </button>
        </div>
      </div>
      {gotElements ? (
        logs?.map((item, idx) => (
          <Accordion
            key={`log-${idx}`}
            title={generateTitle({
              method: item?.method,
              url: item?.uri,
              date: dateTimeRequest(item?.dateTimeRequest),
              statusCode: item?.statusCode
            })}
          >
            <div className={s.logContainer}>
              <div className={s.logTitle}>Дата отправки:</div>
              <div>{dateTimeRequest(item?.dateTimeRequest)}</div>
            </div>
            {item?.statusCode ? (
              <div className={s.logContainer}>
                <div className={s.logTitle}>Код ответа:</div>
                <div>{item?.statusCode}</div>
              </div>
            ) : null}
            <div className={s.logContainer}>
              <div className={s.logTitle}>Заголовки:</div>
              <div>
                <pre>{JSON.stringify(item?.headers, null, 2)}</pre>
              </div>
            </div>

            <div className={s.logContainer}>
              <div className={s.logTitle}>Дополнительные параметры запроса:</div>
              <div>
                <pre>{JSON.stringify(item?.queryParams, null, 2)}</pre>
              </div>
            </div>
            <div className={s.logContainer}>
              <div className={s.logTitle}>Тело {generateBodyTitle(item)}:</div>
              <div>
                <pre>{item?.body && JSON.stringify(item?.body, null, 2)}</pre>
              </div>
            </div>
          </Accordion>
        ))
      ) : (
        <div>Список пуст</div>
      )}
    </div>
  );
};
