import { Icon, Stack, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ProductsAPI from '../../../api/productsAPI';
import g from '../../../assets/scss/Main.module.scss';
import { Alert } from 'components/Alert/Alert';
import { BottomButtons } from 'components/BottomButtons/BottomButtons';
import { GrayPanel } from 'components/GrayPanel/GrayPanel';
import { Loader } from 'components/Loader/Loader';
import { TableAction } from 'components/TableAction/TableAction';
import { PreliminaryPrintForm } from 'pages/Consignments/PreliminaryPrintForm';
import { listCodesIcon, orderCodesIcon, ordersCodesIcon } from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import { useAlert, useSendRequest } from '../../../hooks';
import s from './ProductsCard.module.scss';
import consignmentsAPI from 'api/consignmentsAPI';
import AutoCreateOrders from './AutoCreateOrders/AutoCreateOrders';
import RemCodesTooltip from './RemCodesTooltip';
import Permits from './Permits';
import { validatePermits } from './permits.validation';
import { paymentTypes } from './AutoCreateOrders/AutoCreateOrders.constants';
import {
  initialValues,
  productGroupRefObj,
  expirationDateTypeRefObj
} from '../AddSideProduct/addSideProducts.constants';
// import { providersResponse } from './AutoCreateOrders/mockData';
import SideProductEditButton from './SideProductEdit';

const isDisabled = (errorObj) =>
  !!Object.values(errorObj).find((errorString) => errorString.length > 0);

export const certificateTypeDropdown = [
  { key: 'SELECT_TYPE', text: 'Выберите тип разрешительной документации' },
  { key: 'CONFORMITY_CERTIFICATE', text: 'Сертификат соответствия' },
  { key: 'CONFORMITY_DECLARATION', text: 'Декларация о соответствии' },
  { key: 'STATE_REGISTRATION_CERTIFICATE', text: 'Свидетельство о государственной регистрации' }
];

const findKey = (text) => {
  return certificateTypeDropdown.find((options) => options.text === text)?.key;
};

export const initialErrorState = {
  certificateNumber: '',
  certificateDate: '',
  certificateType: ''
};

//const hostStyles = { calloutMain: { position: 'absolute', display: 'flex', top: '100%' } };

export const ProductsCard = () => {
  const { setCrumbs } = useCrumbsContext();
  const { productId } = useParams();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [criticalCodesRemains, setCriticalCodesRemains] = useState(50000);
  const [isShowedMainInfo, setIsShowedMainInfo] = useState(false);
  const [catalogInfo, setCatalogInfo] = useState(null);
  const [sideProduct, setSideProduct] = useState(initialValues);
  const [certificateField, setCertificateFields] = useState({
    id: productId,
    certificateType: findKey(certificateTypeDropdown[0]?.text),
    certificateNumber: '',
    certificateDate: '',
    wellNumber: null
  });
  const [formErrors, setFormErrors] = useState(initialErrorState);
  const { alertTitle, alertText, isAlertShowed, showAlert, hideAlert } = useAlert();
  const { afterSending } = useSendRequest(setLoading);
  // useBackBtn('/products')
  const [remainingMarkCodes, setRemainingMarkCodes] = useState(null);
  const [autoCreateOrderParameters, setAutoCreateOrderParameters] = useState(null);

  useEffect(() => {
    if (productId) {
      getProduct(productId);
    }
  }, [productId]);

  const handleCloseAlert = () => {
    if (alertText === 'Продукт успешно изменен') history.push('/products');
    else hideAlert();
  };

  useEffect(() => {
    if (product) {
      getCatalogInfo();
      const sideProd = {
        Name: product?.name || '',
        TnVed: product?.tnVed || '',
        Gtin: product?.gtin || '',
        productGroup: product?.productGroup || productGroupRefObj[0].key,
        expirationDateType: product?.expirationDateType || expirationDateTypeRefObj[0].key,
        expirationDate: Number(product?.expirationDate) || null,
        companyId: product?.companyId || '',
        productOwnerId: product?.productOwnerId || ''
      };

      setSideProduct(sideProd);
    }
  }, [product?.id]);

  useEffect(() => {
    setCrumbs(['/products', 'Карточка продукта']);
  }, []);

  const getCatalogInfo = async () => {
    const res = await ProductsAPI.getProductCatalogInfo(product?.gtin);
    setCatalogInfo(JSON.stringify(res, null, 2));
  };

  const getProduct = async (productId) => {
    setLoading(true);
    let res = await ProductsAPI.getProductById(productId);
    const remMarkCodes = await ProductsAPI.getRemainingMarcCodes(productId);

    if (!!res?.id) {
      setProduct(res);
      setCriticalCodesRemains(res.minimumRemainderMarkingCodes);
      if (!!res.certificateDocuments.length) {
        delete res.certificateDocuments[0].id;
        setCertificateFields({
          ...res.certificateDocuments[0],
          certificateDate: res.certificateDocuments[0]?.certificateDate
            ? new Date(res.certificateDocuments[0]?.certificateDate)
            : '',
          certificateType: res.certificateDocuments[0]?.certificateType
            ? res.certificateDocuments[0]?.certificateType
            : findKey(certificateTypeDropdown[0]?.text)
        });
      }
      const initState = {
        active: res?.createAutoOrderParameters?.active || false,
        minimumRemainder: res?.createAutoOrderParameters?.minimumRemainder || 0,
        quantity: res?.createAutoOrderParameters?.quantity || 1,
        createMethodType: res?.createAutoOrderParameters?.createMethodType || 'SELF_MADE',
        releaseMethodType: res?.createAutoOrderParameters?.releaseMethodType || 'PRODUCTION',
        paymentType: res?.createAutoOrderParameters?.paymentType || 'UTILISATION',
        serviceProviderId: res?.createAutoOrderParameters?.serviceProviderId || '',
        serialNumberType: res?.createAutoOrderParameters?.serialNumberType || 'OPERATOR',
        cisType: res?.createAutoOrderParameters?.cisType || 'UNIT'
      };
      setAutoCreateOrderParameters(initState);
    }
    setLoading(false);
    setRemainingMarkCodes(remMarkCodes);
  };

  const updateMinRemains = async () => {
    let certificateDocs;
    const onSuccess = () => {
      showAlert('Изменение продукта', 'Продукт успешно изменен');
    };
    certificateDocs = validatePermits(certificateField, setFormErrors, showAlert);

    if (certificateDocs === false) return;

    const productData = {
      name: sideProduct.Name,
      tnVed: sideProduct.TnVed,
      gtin: sideProduct.Gtin,
      productGroup: sideProduct.productGroup,
      expirationDateType: sideProduct.expirationDateType,
      expirationDate: Number(sideProduct.expirationDate),
      companyId: sideProduct.companyId,
      productOwnerId: sideProduct.productOwnerId
    };

    const res = await ProductsAPI.setMinRemains(
      product?.id,
      criticalCodesRemains,
      certificateDocs,
      autoCreateOrderParameters,
      product?.isSubAccountProduct && !!product?.companyId ? productData : null
    );

    if (res?.status === 200 || res?.statusCode === 200) {
      setAutoCreateOrderParameters(null);
    }
    afterSending(res, onSuccess);
  };

  return (
    <div className={g.contentWrapper}>
      {isAlertShowed && <Alert title={alertTitle} text={alertText} onClose={handleCloseAlert} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={g.titleWrapper}>
            <h1 className={g.title}>{product?.name}</h1>
            {product && (
              <div className={s.actions}>
                <TableAction
                  link={`/products/orderCode`}
                  params={{
                    id: product.id,
                    productName: product.name,
                    templateId: product.category?.templateId,
                    productGtin: product.gtin,
                  }}
                  tooltipText={'Заказать КМ'}
                  icon={orderCodesIcon}
                />

                <TableAction
                  link={`/products/codesList`}
                  params={{
                    id: product?.id,
                    productName: product?.name,
                    templateId: product?.category?.templateId,
                    productGtin: product?.gtin
                  }}
                  icon={listCodesIcon}
                  tooltipText={'Список КМ'}
                />
                <TableAction
                  link={`/products/orders`}
                  params={{
                    id: product?.id,
                    productName: product?.name,
                    templateId: product?.category?.templateId,
                    productGtin: product?.gtin
                  }}
                  icon={ordersCodesIcon}
                  tooltipText={'Список заказов'}
                />
              </div>
            )}
          </div>
          <div className={s.mainInfo}>
            <span className={s.code}>{`GTIN: ${product?.gtin}`}</span>
            {/* ******* TOOL-TIP HOST ****** */}

            {typeof product?.remainderMarkingCodes !== 'undefined' && (
              <RemCodesTooltip
                remainderMarkingCodes={product?.remainderMarkingCodes}
                codesData={remainingMarkCodes}
              />
            )}
          </div>
          <GrayPanel className={s.panel}>
            <div className={s.inputsContainer}>
              <div className={s.mainInputs}>
                {product && (
                  <Stack tokens={{ childrenGap: 25 }}>
                    <div className={s.infoRow}>
                      <span className={s.infoKey}>Бренд:</span>
                      <span className={s.infoValue}>{product?.brandName}</span>
                    </div>
                    <div className={s.infoRow}>
                      <span className={s.infoKey}>Категория:</span>
                      <span className={s.infoValue}>{product?.categoryName}</span>
                    </div>
                    <div className={s.infoRow}>
                      <span className={s.infoKey}>Подкатегория:</span>
                      <span className={s.infoValue}>{product?.subCategoryName}</span>
                    </div>
                    <TextField
                      label="Сигнализировать при остатке КМ менее"
                      required
                      value={criticalCodesRemains}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => setCriticalCodesRemains(+e.target.value)}
                    />

                    <SideProductEditButton
                      showButton={product?.isSubAccountProduct && !!product?.companyId}
                      sideProduct={sideProduct}
                      setSideProduct={setSideProduct}
                    />

                    <AutoCreateOrders
                      initFormState={autoCreateOrderParameters}
                      setterFunc={setAutoCreateOrderParameters}
                      productId={productId}
                    />
                    {product.showCertificateDocuments && (
                      <>
                        <Permits
                          certificateField={certificateField}
                          setCertificateFields={setCertificateFields}
                          formErrors={formErrors}
                          setFormErrors={setFormErrors}
                          findKey={findKey}
                        />
                      </>
                    )}
                  </Stack>
                )}
              </div>
              {
                <PreliminaryPrintForm
                  id={productId}
                  submitCallback={consignmentsAPI.preliminaryProductPrint}
                  isProduct
                  gtin={product?.gtin}
                  productId={product?.id}
                  setRemainingMarkCodes={setRemainingMarkCodes}
                />
              }
            </div>
            <div className={s.info}>
              <button className={s.infoHeader} onClick={() => setIsShowedMainInfo((prev) => !prev)}>
                <span className={s.infoTitle}>Информация из национального каталога</span>
                {isShowedMainInfo ? (
                  <Icon iconName="ChromeMinimize" className={s.infoIcon} />
                ) : (
                  <Icon iconName="Add" className={s.infoIcon} />
                )}
              </button>
              <div className={`${s.infoContent} ${isShowedMainInfo ? s.infoContentShowed : ''}`}>
                <pre>{catalogInfo}</pre>
              </div>
            </div>
          </GrayPanel>
        </>
      )}
      <div className={s.buttons}>
        <BottomButtons
          disabledButtons={[isDisabled(formErrors)]}
          okText={'Сохранить'}
          closeText="Назад"
          okHandler={() => {
            updateMinRemains();
          }}
          closeHandler={() => history.goBack()}
        />
      </div>
    </div>
  );
};
