import { PrimaryButton } from '@fluentui/react';
import { useState } from 'react';
import EditSiteProductModal from './modal-form';

const SideProductEditButton = ({ showButton, setSideProduct, sideProduct }) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      {showButton ? (
        <PrimaryButton
          disabled={false}
          text="Редактирование стороннего продукта"
          onClick={openModal}
          style={{ marginTop: '2rem', width: '100%' }}
        />
      ) : null}

      {showModal ? (
        <EditSiteProductModal
          onDismiss={closeModal}
          isOpen={showModal}
          setSideProduct={setSideProduct}
          sideProduct={sideProduct}
        />
      ) : null}
    </>
  );
};

export default SideProductEditButton;
