import { Spinner } from '@fluentui/react';
import React from 'react';
import s from './Table.module.scss';
import g from '../../assets/scss/Main.module.scss';
import p from '../../pages/Products/Products.module.scss';
import clsx from 'clsx';
import { ArrowDownRegular, ArrowUpRegular } from '@fluentui/react-icons';

const spinnerStyles = {
  circle: {
    height: 60,
    width: 60,
    borderWidth: 4
  }
};

export const Table = React.memo(
  ({
    headerItems,
    maxHeight,
    isFixedHeight = true,
    loading,
    children,
    onSort,
    sortState,
    isReport = false,
    customStyles = null,
    customHeaderClass = null
  }) => {
    const getLenghtOfTheRightChild = (arr) =>
      arr.filter((child) => child !== false)[0]?.length || 0;
    const renderSortIcon = (columnId) => {
      const sortDirection = sortState.getSortDirection(columnId);
      const iconProps = {
        style: {
          marginRight: '0.5rem',
          height: '12px',
          width: '12px',
          color: sortDirection ? '#0078D4' : 'inherit'
        }
      };

      return sortDirection === 'ascending'
        ? <ArrowUpRegular  {...iconProps} />
        : <ArrowDownRegular  {...iconProps} />;
    };

    return (
      <div className={clsx(s.table, g.contentComponent, customStyles && customStyles)} style={{ maxHeight: maxHeight, margin: '0.5rem 1rem' }}>
        <div className={clsx(s.tableHeader, customHeaderClass && customHeaderClass)}>
          {headerItems?.map((headerItem, i) => {
            return (
              <div
                key={i}
                className={clsx(s.tableHeaderItem, {
                  [s.sortable]: headerItem.sortable
                })}
                onClick={() => headerItem.sortable && onSort?.(headerItem.columnId)}
                style={{
                  cursor: headerItem.sortable ? 'pointer' : 'default',
                  width: `calc((100vw - 180px - 3.75rem) * ${headerItem.width})`,
                  ...headerItem.headerStyles
                }}
              >
                <span>{headerItem.title}</span>
                {headerItem.sortable && sortState && (
                  <span className={s.sortIcon}>
                    {renderSortIcon(headerItem.columnId)}
                  </span>
                )}
              </div>
            );
          })}
        </div>
        <div
          className={s.tableContent}
          style={
            isFixedHeight
              ? { height: `calc(${maxHeight} - 3.75rem` }
              : { maxHeight: `calc(${maxHeight} - 3.75rem` }
          }
        >
          {!loading ? (
            <div className={s.searchNothing}>
              {(!children?.length && !isReport) ||
                (isReport && !getLenghtOfTheRightChild(children)) ? (
                <span >Список результатов пуст</span>
              ) : (
                children
              )}
            </div>
          ) : (
            <div className={s.loaderContainer} style={{ height: `calc(${maxHeight} - 3.75rem` }}>
              <Spinner styles={spinnerStyles} />
            </div>
          )}
        </div>
      </div>
    );
  }
);
// style={{ padding: "00.9375rem  8rem" }}

export const ProductsTable = React.memo(
  ({ headerItems, maxHeight, isFixedHeight = true, loading, children, onSort, sortState }) => {
    const renderSortIcon = (columnId) => {
      const sortDirection = sortState.getSortDirection(columnId);

      if (sortDirection === 'ascending') {
        return (
          <ArrowUpRegular
            style={{
              height: '12px',
              width: '12px',
              color: '#0078D4' // активный синий цвет
            }}
          />
        );
      } else if (sortDirection === 'descending') {
        return (
          <ArrowDownRegular
            style={{
              height: '12px',
              width: '12px',
              color: '#0078D4' // активный синий цвет
            }}
          />
        );
      } else {
        return (
          <div>
            <ArrowDownRegular style={{ height: '12px', width: '12px' }} />
          </div>
        );
      }
    };

    return (
      <div className={clsx(s.table, g.contentComponent)} style={{ maxHeight: maxHeight, margin: '1rem' }}>
        <div className={s.tableHeader}>
          <div className={p.firsthThreeRows}>
            {headerItems?.map((headerItem, i) => {
              if (i < 3) {
                return (
                  <div
                    key={i}
                    className={clsx(`${p.tableHeaderItem}`, {
                      [p.firstRow]: i === 0,
                      [p.middleRows]: i === 1 || i === 2,
                      [p.middleTableHeaderItem]: i === 1 || i === 2,
                      [s.sortable]: headerItem.sortable
                    })}
                    onClick={() => headerItem.sortable && onSort(headerItem.columnId)}
                    style={{ cursor: headerItem.sortable ? 'pointer' : 'default' }}
                  >
                    <span>{headerItem.title}</span>
                    {headerItem.sortable && (
                      <span className={s.sortIcon}>
                        {renderSortIcon(headerItem.columnId)}
                      </span>
                    )}
                  </div>
                );
              }
            })}
          </div>

          {headerItems?.map((headerItem, i) => {
            if (i === 3) {
              return (
                <div key={i} className={clsx(`${p.lastTableHeaderItem} `, p.lastRow)}>
                  {headerItem.title}
                </div>
              );
            }
          })}
        </div>
        <div
          className={s.tableContent}
          style={
            isFixedHeight
              ? { height: `calc(${maxHeight} - 3.75rem` }
              : { maxHeight: `calc(${maxHeight} - 3.75rem` }
          }
        >
          {!loading ? (
            <div>
              {!children?.length ? (
                <span className={s.searchNothing}>Список результатов пуст</span>
              ) : (
                children
              )}
            </div>
          ) : (
            <div className={s.loaderContainer} style={{ height: `calc(${maxHeight} - 3.75rem` }}>
              <Spinner styles={spinnerStyles} />
            </div>
          )}
        </div>
      </div>
    );
  }
);
