export const correctionFields = [
  {
    fieldName: 'correctVsd',
    russFieldName: 'ВСД'
  },
  {
    fieldName: 'correctProductionDate',
    russFieldName: 'Дата производства'
  },
  {
    fieldName: 'correctExpirationDate',
    russFieldName: 'Дата окончания срока годности'
  },
  {
    fieldName: 'correctVariableExpiration',
    russFieldName: 'Вариативный срок годности'
  },
  {
    fieldName: 'correctWeight',
    russFieldName: 'Вес'
  },
  {
    fieldName: 'correctTnVed',
    russFieldName: 'ТН ВЭД'
  },
  {
    fieldName: 'correctPermitDocs',
    russFieldName: 'Разрешительная документация'
  },
  {
    fieldName: 'correctLicenses',
    russFieldName: 'Лицензия на пользования недрами'
  },
  {
    fieldName: 'correctEmissionType',
    russFieldName: 'Тип эмиссии'
  }
];

export const realInitValues = {
  correctVsd: false,
  correctProductionDate: false,
  correctExpirationDate: false,
  correctVariableExpiration: false,
  correctWeight: false,
  correctTnVed: false,
  correctPermitDocs: false,
  correctLicenses: false,
  correctEmissionType: false
};

export const compareTwoObjects = (initVals, data) => {
  const keys = Object.keys(initVals);
  for (const key of keys) {
    if (initVals[key] !== data[key]) {
      return false;
    }
  }
  return true;
};
