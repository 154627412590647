import React from 'react';
import { Field } from 'formik';
import { TextField } from '@fluentui/react';

const InputWithProps = (props) => {
  const { label = '', type, name, className, disabled, omsConnectionProp, isOmsConnection } = props;

  return (
    <Field {...props}>
      {({ field, meta }) => {
        const isError = !!(meta.error && meta.touched);
        return (
          <TextField
            {...field}
            value={isOmsConnection ? omsConnectionProp : field.value || ''}
            className={className}
            label={label}
            name={name}
            autoComplete="off"
            errorMessage={isError && meta.error}
            disabled={disabled}
          />
        );
      }}
    </Field>
  );
};

export default InputWithProps;
