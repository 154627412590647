import { useState } from 'react';
import { Modal } from '@fluentui/react';
import { NEW_SIDEPRODUCT_VALIDATION_SCHEMA, initialValues } from './addSideProducts.constants';
import FormContainer from 'components/Forms/FormContainer';
import { Loader } from 'components/Loader/Loader';
import productsAPI from 'api/productsAPI';
import s from './AddSideProduct.module.scss';
import AddForm from './AddForm';
import useGetData from './UseGetData';
import { Alert } from 'components/Alert/Alert';

const AddSiteProductModal = ({ onDismiss, isOpen, getProducts }) => {
  const [loading, setLoading] = useState(false);
  const [companies, owners] = useGetData(setLoading);
  const [showAlert, setShowAlert] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await productsAPI.addSideProduct(data);
    setLoading(false);
    if (res.id) {
      setShowAlert(true);
    } else {
      onDismiss();
    }
  };

  const onClose = () => {
    setShowAlert(false);
    onDismiss();
    getProducts();
  };

  return (
    <Modal containerClassName={s.modal} isOpen={isOpen} onDismiss={onDismiss} isBlocking={false}>
      {showAlert && (
        <Alert
          title={'Создание стороннего продукта'}
          text={'Сторонний продукт успешно создан'}
          onClose={onClose}
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div>
            <h4 className={s.label}>Добавить сторонний продукт</h4>
          </div>

          <FormContainer
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            className={s.formWrapper}
            validationSchema={NEW_SIDEPRODUCT_VALIDATION_SCHEMA}
          >
            {companies &&
              owners &&
              ((formik) => {
                const { isValid } = formik;
                return <AddForm isValid={isValid} companies={companies} owners={owners} />;
              })}
          </FormContainer>
        </>
      )}
    </Modal>
  );
};

export default AddSiteProductModal;
