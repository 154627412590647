import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import s from './MiniLoader.module.scss';

export const MiniLoader = () => {
  return (
    <div className={s.loaderContainer}>
      <Spinner size={SpinnerSize.medium} />
    </div>
  );
};
