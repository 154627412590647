import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { DatePicker, DayOfWeek, Label } from '@fluentui/react';
import { Button, Combobox, makeStyles, Option, tokens, useId } from '@fluentui/react-components';
import { Dismiss12Regular } from "@fluentui/react-icons";
import { DayPickerStrings, reportStatuseForPrinting } from '../../../constants';
import {
  checkDateError,
  getDateFromTimestamp,
  mapItemsForDropdown,
} from '../../../functions';
import { setFilteredData } from 'redux/App/appReducer';
import { useDispatch } from 'react-redux';
import LegalApi from '../../../api/legalAPI';
import g from '../../../assets/scss/Main.module.scss';
import s from './ReportsFilterNew.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '12px',
    margin: '8px 0'
  },
  scrollableMenuList: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    flexWrap: "wrap",
    gridGap: tokens.spacingHorizontalXXS,
  },
  combobox: {
    minWidth: "200px",
    maxWidth: "300px",
    "::after": {
      display: "none"
    },
    border: 'none',
    "> input": {
      width: "calc(11.688rem - 35px)",
      cursor: 'pointer'
    },
    "> span:nth-child(4) ": {
      display: 'none'
    }
  },
  tag: {
    "& button": {
      borderRadius: '4px',
      backgroundColor: '#DEECF9',
      height: '32px',
    }
  },
  datePickerRow: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const ReportsFilterNew = ({ query, setQuery, selectedOptions, setSelectedOptions, type, companiesForDropdown }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const comboId = useId("id");
  const selectedListId = `${comboId}-selection`;

  const labelledBy =
    selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;


  const dataFilter = [
    {
      key: 'companyId',
      label: 'Юридические лица',
      type: 'combobox',
      options: companiesForDropdown,
    },
    {
      key: 'status',
      label: 'Статус',
      type: 'combobox',
      options: reportStatuseForPrinting,
    },
    {
      key: 'minCreationDate',
      label: 'Дата создания от',
      type: 'datepicker',
    },
    {
      key: 'maxCreationDate',
      label: 'Дата создания до',
      type: 'datepicker',
    },
    {
      key: 'minSendingDate',
      label: 'Дата отправки от',
      type: 'datepicker',
    },
    {
      key: 'maxSendingDate',
      label: 'Дата отправки до',
      type: 'datepicker',
    },
    {
      key: 'minProductCreationDate',
      label: 'Дата производства от',
      type: 'datepicker',
    },
    {
      key: 'maxProductCreationDate',
      label: 'Дата производства до',
      type: 'datepicker',
    },
  ];

  const onOptionSelect = (data) => {
    const updatedFilterObj = { [data.optionValue.category]: data.optionValue.key };
    const category = data?.optionValue?.category;
    const key = data?.optionValue?.key;

    if (category && key) {
      const updatedOptions = selectedOptions?.filter(option => option?.optionValue?.category !== category);
      setSelectedOptions([...updatedOptions, data]);
    }

    setQuery(
      (prev) => ({
        ...prev,
        ...updatedFilterObj,
        pageNumber: 1
      }),
      'replace'
    );
    dispatch(setFilteredData(updatedFilterObj));
  }

  const formatDateRange = (start, end) => {
    if (start && end) {
      return `${getDateFromTimestamp(new Date(start), false, { delimiter: '.', withoutTime: true })} - ${getDateFromTimestamp(new Date(end), false, { delimiter: '.', withoutTime: true })}`;
    } else if (start) {
      return `с ${getDateFromTimestamp(new Date(start), false, { delimiter: '.', withoutTime: true })}`;
    } else if (end) {
      return `по ${getDateFromTimestamp(new Date(end), false, { delimiter: '.', withoutTime: true })}`;
    }
    return '';
  };

  const dateRanges = useMemo(() => [
    { label: 'Дата создания', min: 'minCreationDate', max: 'maxCreationDate' },
    { label: 'Дата отправки', min: 'minSendingDate', max: 'maxSendingDate' },
    { label: 'Дата производства', min: 'minProductCreationDate', max: 'maxProductCreationDate' },
  ], []);

  const combinedDateTags = useMemo(() => {
    return dateRanges.map(range => {
      const start = query[range.min];
      const end = query[range.max];
      if (start || end) {
        return {
          label: range.label,
          value: formatDateRange(start, end),
          keys: [range.min, range.max]
        };
      }
      return null;
    }).filter(Boolean);
  }, [query]);

  const displayTags = useMemo(() => {
    const nonDateTags = selectedOptions.filter(option =>
      !option.optionValue.category.includes('Date')
    );
    return [...nonDateTags, ...combinedDateTags];
  }, [selectedOptions, combinedDateTags]);

  const onTagClick = (option) => {
    let updatedFilterObj = {};
    if (Array.isArray(option.keys)) {
      option.keys.forEach(key => {
        updatedFilterObj[key] = undefined;
      });
    } else {
      updatedFilterObj[option.optionValue?.category] = undefined;
    }

    setSelectedOptions(selectedOptions.filter((o) =>
      o.optionValue?.category !== option.optionValue?.category &&
      !option.keys?.includes(o.optionValue?.category)
    ));

    setQuery(
      (prev) => ({
        ...prev,
        ...updatedFilterObj
      }),
      'replace'
    );
    dispatch(setFilteredData(updatedFilterObj));
  };

  const resetAllHandler = () => {
    setQuery({
      pageNumber: 1
    }, 'replace');
    setSelectedOptions([]);
  };

  const renderCombobox = (title, category, options) => (
    <Combobox
      className={styles.combobox}
      aria-labelledby={labelledBy}
      placeholder={title}
      readOnly
      value={title}
      onOptionSelect={(_, item) => onOptionSelect(item)}
    >
      <div className={styles.scrollableMenuList}>
        {options.map((option) => (
          <Option
            value={{ key: option.key, text: option.text, category: category }}
            key={option.key}
          >
            {option.text}
          </Option>
        ))}
      </div>
    </Combobox>
  );

  const CustomDatePicker = ({
    label,
    placeholder,
    dateKey,
    minDate,
    maxDate
  }) => {
    const handleDateSelect = useCallback((date) => {
      if (date) {
        onOptionSelect({
          optionValue: {
            key: date.getTime(),
            text: getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true }),
            category: dateKey
          }
        });
      }
    }, [dateKey]);

    return (
      <div className={styles.datePickerContainer}>
        <DatePicker
          placeholder={placeholder}
          firstDayOfWeek={DayOfWeek.Monday}
          isRequired={false}
          allowTextInput={true}
          formatDate={(date) => {
            return !date
              ? null
              : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
          }}
          strings={DayPickerStrings}
          value={query[dateKey] ? new Date(query[dateKey]) : null}
          onSelectDate={handleDateSelect}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    );
  };

  return (
    <div>
      <div className={styles.root}>
        {dataFilter
          .filter(filter => filter.type === 'combobox')
          .map((filter) => renderCombobox(filter.label, filter.key, filter.options))}
      </div>
      <div className={styles.datePickerRow}>
        {dataFilter
          .filter(filter => filter.type === 'datepicker')
          .map((filter) => (
            <CustomDatePicker
              key={filter.key}
              label={filter.label}
              placeholder={filter.label}
              dateKey={filter.key}
              minDate={filter.minDate}
              maxDate={filter.maxDate}
            />
          ))}
      </div>

      {displayTags.length ? (
        <ul id={selectedListId} className={styles.tagsList}>
          <span id={`${comboId}-remove`} hidden>
            Remove
          </span>
          {displayTags.map((option, i) => (
            <li className={styles.tag} key={i}>
              <Button
                size="small"
                shape="circular"
                icon={<Dismiss12Regular />}
                iconPosition="after"
                onClick={() => onTagClick(option)}
                id={`${comboId}-remove-${i}`}
                aria-labelledby={`${comboId}-remove${comboId}-remove-${i}`}
              >
                {console.log(option)}
                {option.optionValue ?
                  `${option.optionValue.label || ''}: ${option.optionValue.text}` :
                  `${option.label}: ${option.value}`
                }
              </Button>
            </li>
          ))}
          <LinkButton isButton={true} text={'Сбросить всё'} onClick={() => resetAllHandler()} />
        </ul>
      ) : null}
    </div>
  );
};
