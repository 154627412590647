import clsx from 'clsx';
import { SearchBox } from '@fluentui/react';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ProductsAPI from '../../api/productsAPI';
import g from '../../assets/scss/Main.module.scss';
import { ProductsFilterNew } from '../../components/Filters/ProductsFilterNew/ProductsFilterNew';
import { SyncStatus } from '../../components/SyncStatus/SyncStatus';
import { ProductsTable } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { WaitingScreen } from '../../components/WaitingScreen/WaitingScreen';
import { mapItemsForDropdown } from '../../functions';
import LegalApi from '../../api/legalAPI';
import {
  listCodesIcon,
  orderCodesIcon,
  ordersCodesIcon,
  productCardIcon,
  searchBoxStyles,
  syncIcon
} from '../../constants';
import { usePaginationAndSearch, useDataFilter } from '../../hooks';
import { getPrevRoute } from '../../redux/App/appSelectors';
import s from './Products.module.scss';
import { getGlobalErrorText } from 'redux/App/appSelectors';
import { useDispatch } from 'react-redux';
import { getProductChZBalance } from 'redux/App/appReducer';
import RemainingCosedWCallout from './ProductsCard/Callout';
import AddSiteProductButton from './AddSideProduct/AddSideProduct';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import { AddRegular, MoreHorizontalRegular } from '@fluentui/react-icons';
import { LinkELement } from 'components/LinkELement/LinkELement';
import { useCrumbsContext } from 'context/CrumbsContext';
// import { FluentTable } from 'components/FluentTable/FluentTable';
import { Tooltip } from '@fluentui/react-components';
import { useTableFeatures } from '@fluentui/react-table';
import { useTableSort } from '@fluentui/react-table';
import { Pagination } from 'components/Pagination/Pagination';
const tableHeader = [
  {
    label: 'label',
    title: 'Название',
    width: '15%',
    columnId: 'name',
    sortable: true
  },
  {
    label: 'minRemainderOfMarkingCodes',
    title: 'Остаток КМ',
    width: '10%',
    columnId: 'markingCodesRemainder',
    sortable: false
  },
  {
    label: 'gtin',
    title: 'Gtin',
    width: '10%',
    columnId: 'gtin',
    sortable: false
  },
  {
    label: 'actions',
    title: 'Действия',
    width: '200px',
    sortable: false
  }
];

export const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const prevRoute = useSelector(getPrevRoute);
  const globalErrorText = useSelector(getGlobalErrorText);
  const dispatch = useDispatch();
  const [calledProduct, setCalledProduct] = useState(false);
  const { setCrumbs, notify, toasterId } = useCrumbsContext();
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [brandsForDropdown, setBrandsForDropdown] = useState([]);
  const [categoriesForDropdown, setCategoriesForDropdown] = useState([]);
  const [subCategoriesForDropdown, setSubCategoriesForDropdown] = useState([]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  const hideCallout = (e) => {
    if (e.target.nodeName !== 'SPAN') {
      e.stopPropagation();
      setCalledProduct('');
    }
  };

  const getProductBalance = (productId) => {
    dispatch(getProductChZBalance(productId));
  };

  const mouseDownHandler = (e, params) => {
    localStorage.setItem('params', JSON.stringify(params));
  };

  const [query, setQuery] = useDataFilter('products');
  useEffect(() => {
    if (globalErrorText) {
      setSyncStatus(null);
    }
  }, [globalErrorText]);


  const abortControllerRef = useRef(null);

  const getProducts = async (query) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setLoading(true);

    try {
      let res = await ProductsAPI.getProducts(query, abortController.signal);

      if (!res?.statusCode) {
        const { list, quantity } = res;
        setProducts(list);
        setTotalItems(quantity);
      }
    } catch (error) {
      if (error?.name !== 'AbortError') {
        console.error('Error fetching products:', error);
      }
    } finally {
      if (abortControllerRef.current === abortController) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setCrumbs(['/products']);
  }, [setCrumbs]);

  const syncProducts = async (type) => {
    setSyncStatus(`${type}InProgress`);
    let syncRes;
    if (type === 'fullSuz') {
      syncRes = await ProductsAPI.setSignedOrdersFull();
    }

    if (type === 'catalog') {
      syncRes = await ProductsAPI.syncProducts();
    }

    if (type === 'suz') {
      syncRes = await ProductsAPI.setSignedOrders();
    }

    if (syncRes.statusCode === 200) {
      await getProducts(query);
      // setSyncStatus('success');
      notify({ title: 'Синхронизировать нац. каталог прошла успешно', intent: 'success' });
    } else {
      notify({ title: 'При синхронизации нац. каталога произошла ошибка', intent: 'error' });
      // setSyncStatus('error');
    }
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({
      callback: getProducts,
      query,
      setQuery,
      headerHeight: 150
    });
  useEffect(() => {
    ProductsAPI.getAllBrands().then((res) => setBrandsForDropdown(mapItemsForDropdown(res.list)));
    ProductsAPI.getCategories().then((res) => {
      setCategoriesForDropdown(mapItemsForDropdown(res.list));
    });
    LegalApi.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  useEffect(() => {
    if (query.categoryId) {
      ProductsAPI.getSubCategories(query.categoryId).then((res) => {
        setSubCategoriesForDropdown(mapItemsForDropdown(res?.list))
      });
    }
  }, [query.categoryId]);
  useEffect(() => {
    const filterData = [
      { label: 'Компания', key: 'companyId', options: companiesForDropdown },
      { label: 'Бренд', key: 'brandId', options: brandsForDropdown },
      { label: 'Категория', key: 'categoryId', options: categoriesForDropdown },
      { label: 'Подкатегория', key: 'subCategoryId', options: subCategoriesForDropdown },
      {
        label: 'Остаток КМ от',
        key: 'minRemainderOfMarkingCodes',
        getValue: (value) => value,
        getText: (value) => `От ${value}`
      },
      {
        label: 'Остаток КМ до',
        key: 'maxRemainderOfMarkingCodes',
        getValue: (value) => value,
        getText: (value) => `${value}`
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: {
                key: option.key,
                text: option.text,
                categoryId: filter.key,
                label: filter.label
              }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              categoryId: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, brandsForDropdown, categoriesForDropdown, subCategoriesForDropdown]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const {
    getRows,
    sort: { getSortDirection, toggleColumnSort, sort },
  } = useTableFeatures(
    {
      columns: tableHeader,
      items: products,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'name', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    // Если сортировка уже включена, инвертируем её
    if (currentDirection) {
      toggleColumnSort(event, columnId); // Инвертируем направление
      const sortedProducts = [...products].sort((a, b) => {
        const direction = currentDirection === 'ascending' ? -1 : 1;
        if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
          return (a[columnId] - b[columnId]) * direction;
        }
        return String(a[columnId] || '').localeCompare(String(b[columnId] || '')) * direction;
      });
      setProducts(sortedProducts);
      return;
    }

    // Если сортировка была выключена, включаем её и сортируем по возрастанию
    toggleColumnSort(event, columnId);
    const sortedProducts = [...products].sort((a, b) => {
      if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
        return a[columnId] - b[columnId];
      }
      return String(a[columnId] || '').localeCompare(String(b[columnId] || ''));
    });
    setProducts(sortedProducts);
  };

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            iconProps={''}
            styles={searchBoxStyles}
            value={query.searchValue}
            placeholder="Поиск по названию или Gtin"
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => {
              onSearchTermChanged(newValue);
            }}
          />
        </div>
        <div className={clsx(`${s.tableActionsButtons}`)}>
          <AddSiteProductButton query={query} getProducts={getProducts} />
          <ButtonMenu
            title={'Синхронизация'}
            syncStatus={syncStatus}
            dataArray={[
              {
                text: 'Синхронизировать нац.каталог',
                onClick: () => syncProducts('catalog'),
                disabled: syncStatus === 'catalogInProgress'
              },
              {
                text: 'Полная синхронизация с СУЗ',
                onClick: () => syncProducts('fullSuz'),
                disabled: syncStatus === 'fullSuzInProgress'
              }
            ]}
            optionSize={0}
          />
          <ButtonToggle
            text="Фильтры"
            value={isOpenFilters}
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            optionSize={selectedOptions.length}
          />
        </div>
      </div>
      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <ProductsFilterNew
            query={query}
            setQuery={setQuery}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            brandsForDropdown={brandsForDropdown}
            categoriesForDropdown={categoriesForDropdown}
            subCategoriesForDropdown={subCategoriesForDropdown}
          />
        </div>
      )}
      <div className={s.mainContent}>
        <div className={s.tableWrapper}>
          {!products.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
          ) : (
            <ProductsTable
              headerItems={tableHeader}
              loading={loading}
              isProductsPage={true}
              onSort={handleSort}
              sortState={{ getSortDirection }}
            >
              {Array.isArray(products) &&
                products?.map((item, ind) => {
                  return (
                    <div
                      key={item.id}
                      className={clsx(
                        g.tableItem,
                        item.markingCodesRemainder <= item.minRemainderOfMarkingCodes &&
                        g.errorTableItem
                      )}
                    >
                      <div className={s.firsthThreeRows}>
                        <LinkELement
                          addStyles={clsx(g.tableRow, s.firstRow)}
                          link={`/products/productCard/${item.id}`}
                          text={item.name}
                          params={{
                            id: item.id,
                            productName: item.name,
                            templateId: item.category?.templateId,
                            productGtin: item.gtin
                          }}
                          appearance={'subtle'}
                        >
                          {item.name}
                        </LinkELement>
                        <RemainingCosedWCallout
                          markingCodesRemainder={item.markingCodesRemainder}
                          productId={item.id}
                          isCallout={calledProduct === item.id}
                          setCalledProduct={setCalledProduct}
                          index={ind}
                          hideCallout={hideCallout}
                        />
                        <div className={clsx(s.tableCell, s.middleRows)}>{item.gtin}</div>
                      </div>

                      <div className={clsx(`${s.doButtons}`, s.lastRow)}>
                        <div
                          onMouseDown={(e) =>
                            mouseDownHandler(e, {
                              id: item.id,
                              productName: item.name,
                              templateId: item.category?.templateId,
                              productGtin: item.gtin,
                              prevRoute: prevRoute
                            })
                          }
                        >
                          <LinkButton
                            addStyles={clsx(s.linkButton)}
                            link={`/products/orderCode/${item.id}`}
                            onClick={() => getProductBalance(item.id)}
                            isButton={true}
                            text="Заказать КМ"
                            params={{
                              id: item.id,
                              productName: item.name,
                              templateId: item.category?.templateId,
                              productGtin: item.gtin,
                              prevRoute: prevRoute
                            }}
                          />
                        </div>
                        <ButtonMenu
                          isButton={true}
                          iconName={<MoreHorizontalRegular />}
                          optionSize={0}
                          dataArray={[
                            {
                              link: `/products/codesList`,
                              params: {
                                status: 'NEW,UTILISATED,AGGREGATED,INROLLOUT,SCANED,INVALID',
                                statusParamName: 'status',
                                productId: item.id
                              },
                              text: 'Список КМ',
                              icon: listCodesIcon
                            },
                            {
                              link: `/products/orders`,
                              params: {
                                orderId: item.id,
                                statusParamName: 'status',
                                id: item.id,
                                status:
                                  'CREATED,SENDED,PENDING,DECLINED,APPROVED,READY,CLOSED,EXPIRED'
                              },
                              text: 'Заказы КМ',
                              icon: ordersCodesIcon
                            },
                            {
                              link: `/products/productCard/${item.id}`,
                              params: {
                                id: item.id,
                                productName: item.name,
                                templateId: item.category?.templateId,
                                productGtin: item.gtin
                              },
                              text: 'Информация о продукте',
                              icon: productCardIcon
                            }
                          ]}
                        />
                      </div>
                      <div className={s.edgeContainer} />
                    </div>
                  );
                })}
            </ProductsTable>
          )}
        </div>
      </div>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
      {/* {syncStatus?.includes('InProgress') && (
          <WaitingScreen
            title={'Выполняется синхронизация'}
            text={
              'Пожалуйста, подождите. Это может занять несколько минут. Не закрывайте эту страницу.'
            }
          />
        )} */}
      {/* {console.log(syncStatus)}
        {['success', 'error'].includes(syncStatus) && (
          <SyncStatus syncStatus={syncStatus} hide={() => setSyncStatus(null)} />
        )} */}
    </div>
  );
};
