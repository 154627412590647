import s from './Consignment.module.scss';

import { PrimaryButton } from '@fluentui/react';
import { useState } from 'react';
import linesAPI from 'api/linesAPI';
import { useHistory } from 'react-router-dom';
import ConfirmWComponent from 'components/Confirm/ConfirmWcomponent';

const DeleteTestConsigment = ({ consId }) => {
  const [isModal, setShowModal] = useState(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const openModal = () => setShowModal(true);
  const handleSend = async () => {
    setIsLoading(true);
    if (Number(consId) > 0) {
      await linesAPI.deleteConsigment(Number(consId));
    }
    setIsLoading(false);
    setShowModal(false);
    history.go(-1);
  };

  return (
    <>
      <main className={s.deleteConsSection}>
        <PrimaryButton
          className={s.deleteButton}
          disabled={false}
          text="Удалить задание"
          onClick={openModal}
          // style={{ marginTop: '2rem', width: '100%' }}
        />

        {isModal ? (
          <ConfirmWComponent
            title={'Удаление задания'}
            btnText={'ОК'}
            setIsShow={setShowModal}
            onClose={handleSend}
            isLoading={isLoading}
            isOnDismissButton
          >
            <span className={s.warningText}>
              Внимание! При удалении задания произойдет откат статусов кодов маркировки, попавших в
              задание. Также, вместе с заданием будут удалены все агрегаты и отчеты созданные в
              рамках задания
            </span>
          </ConfirmWComponent>
        ) : null}
      </main>
    </>
  );
};

export default DeleteTestConsigment;
