import { Icon } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { getIsFilters } from '../../redux/App/appSelectors';
import filter_empt from '../../assets/img/filter_empty.svg';
import filter_filled from '../../assets/img/filter_filled.svg';
import s from './Crumbs.module.scss';
import { MenuItem, MenuList, makeStyles, tokens } from '@fluentui/react-components';
import { lightBlue } from 'constants';
import { accent } from 'constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getFilteredNav } from 'redux/Nav/NavSelectors';
import { pathWithState } from 'components/Menu/navLinkGroups';
import { green } from 'constants';

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    display: 'flex',
    height: '5rem'
  },
  content: {
    flex: '1',
    padding: '1rem',
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  field: {
    display: 'flex',
    marginTop: '0.25rem',
    marginLeft: '0.5rem',
    flexDirection: 'column',
    gridRowGap: tokens.spacingVerticalS
  },
  selectedMenuItem: {
    backgroundColor: accent,
    borderBottom: `0.20rem solid ${green}`,
    borderRadius: '0px',
    color: accent,
    minHeight: '5px',

    '& div:hover': {
      backgroundColor: { accent },
      color: 'white'
    }
  },
  menuBlock: {
    display: 'flex',
    flexDirection: 'row',

    '& div': {
      backgroundColor: 'transparent',
      minWidth: '80px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      gap: 0,
      color: 'white',
      ':hover': {
        backgroundColor: 'transparent',
        color: 'white',
        borderColor: 'transparent'
      },
      '& :active :hover': {
        backgroundColor: 'transparent',
        color: 'white',
        borderColor: 'transparent'
      }
    }
  }
});

export const Crumbs = () => {
  const { renderedCrumbs, currentRenderedCrumb, isFiltersDisplayed, crumbs, pageTitle } =
    useCrumbsContext();
  const location = useLocation();
  const [checkedValues, setCheckedValues] = useState({ font: ['calibri'] });
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState('/products');
  const filteredNavRed = useSelector(getFilteredNav);

  const styles = useStyles();
  const onChange = (e, { name, checkedItems }) => {
    setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
  };

  const onLinkClick = useCallback(
    (e, item) => {
      setSelectedKey(item?.key);
      console.log(item?.key, 'item?.key');
      console.log(pathWithState, 'pathWithState');
      
      if (pathWithState?.includes(`${item?.key}`)) {
        history.replace({ pathname: item?.key, state: { prevPath: location.pathname } });
      } else {
        history.replace(item?.key);
      }
    },
    [history, location.pathname, filteredNavRed]
  );

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItem = filteredNavRed?.filter(item => item.key === currentPath);
    if (matchingItem?.length) {
      onLinkClick(null, ...matchingItem);
    }
  }, [filteredNavRed]);

  return (
    <>
      <div className={`${s.container} ${!filteredNavRed?.length ? '' : s.containeFlexSwitch}`}>
        <div className={s.title}>
          {pageTitle.map((item, index) => (
            <React.Fragment key={item.path}>
              <NavLink
                className={s.title}
                to={{ pathname: item.path, state: { prevPath: location.pathname } }}
              >
                {item.title}
              </NavLink>
              {index < pageTitle?.length - 1 && (
                <Icon className={s.crumbIcon} iconName="ChevronRight" />
              )}
            </React.Fragment>
          ))}
          {crumbs.length > 1 && pageTitle[1]?.title !== crumbs[1] ? (
            <React.Fragment>
              <Icon className={s.crumbIcon} iconName="ChevronRight" />
              {crumbs[1]}
            </React.Fragment>
          ) : null}
        </div>
        <div className={s.menuBar}>
          <MenuList
            className={styles.menuBlock}
            checkedValues={checkedValues}
            onCheckedValueChange={onChange}
          >
            {filteredNavRed?.map((item, i) => {
              return (
                <MenuItem
                  key={item.key}
                  onClick={(e) => onLinkClick(e, item)}
                  value={i}
                  className={selectedKey === item.key ? styles.selectedMenuItem : ''}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
        {/* <VersionInfo /> */}
      </div>
    </>
  );
};