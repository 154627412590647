import * as React from 'react';
import s from './Alert.module.scss';
import { PrimaryButton } from '@fluentui/react';

export const AlertIframe = ({ onClose, source, closeText }) => {
  return (
    <>
      <div className={s.overlay} onClick={onClose} />
      <div className={s.alert}>
        <iframe className={s.iframe} src={source} />

        <PrimaryButton className={s.btn} text={closeText} onClick={onClose} />
      </div>
    </>
  );
};
