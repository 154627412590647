import { DatePicker, DayOfWeek, Label } from '@fluentui/react';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import g from '../../../assets/scss/Main.module.scss';
import { DayPickerStrings } from '../../../constants';
import {
  checkDateError,
  getDateFromTimestamp,
  convertToUTCDate,
  getStatusById,
} from '../../../functions';
import s from './AggregatesFilterNew.module.scss';
import { Button, Combobox, makeStyles, Option, tokens, useId } from '@fluentui/react-components';
import { Dismiss12Regular } from "@fluentui/react-icons";
import { LinkButton } from 'components/LinkButton/LinkButton';
import { aggregateStatuses } from '../../../constants';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '12px',
    margin: '8px 0',
    flexWrap: 'wrap',
  },
  scrollableMenuList: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
    flexWrap: 'wrap',
  },
  combobox: {
    minWidth: "200px",
    maxWidth: "300px",
    "::after": {
      display: "none"
    },
    border: 'none',
    "> input": {
      width: "calc(11.688rem - 35px)",
      cursor: 'pointer'
    },
    "> span:nth-child(4) ": {
      display: 'none'
    }
  },

  tag: {
    "& button": {
      borderRadius: '4px',
      backgroundColor: '#DEECF9',
      height: '32px',
    }
  }
});

export const AggregatesFilterNew = ({ query, setQuery, selectedOptions, setSelectedOptions, productsForDropdown, levelsForDropdown, companiesForDropdown }) => {
  const styles = useStyles();

  const comboId = useId("id");
  const selectedListId = `${comboId}-selection`;
  const selectedListRef = useRef(null);
  const comboboxInputRef = useRef(null);

  const labelledBy =
    selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

  const [filterObj, setFilterObj] = useState(
    {
      productId: query.productId,
      level: query.level,
      minCreationDate: query.minCreationDate,
      maxCreationDate: query.maxCreationDate,
      companyId: query.companyId,
      status: query.status
    }
  );

  const dataFilter = [
    {
      key: 'companyId',
      label: 'Юридические лица',
      type: 'combobox',
      options: companiesForDropdown,
    },
    {
      key: 'level',
      label: 'Уровень агрегации',
      type: 'combobox',
      options: levelsForDropdown,
    },
    {
      key: 'productId',
      label: 'Продукт',
      type: 'combobox',
      options: productsForDropdown,
    },
    {
      key: 'minCreationDate',
      label: 'Дата создания от',
      type: 'datepicker',
    },
    {
      key: 'maxCreationDate',
      label: 'Дата создания до',
      type: 'datepicker',
    },
  ];

  const onOptionSelect = (data) => {
    const category = data?.optionValue?.category;
    const key = data?.optionValue?.key;

    if (category && key) {
      const updatedFilterObj = { ...filterObj, [category]: key };
      setFilterObj(updatedFilterObj);

      const updatedOptions = selectedOptions?.filter(option => option?.optionValue?.category !== category);
      setSelectedOptions([...updatedOptions, data]);

      setQuery(
        (prev) => ({
          ...prev,
          [category]: key,
          pageNumber: 1
        }),
        'replace'
      );
    }
  };

  const formatDateRange = (start, end) => {
    if (start && end) {
      return `${getDateFromTimestamp(new Date(start), false, { delimiter: '.', withoutTime: true })} - ${getDateFromTimestamp(new Date(end), false, { delimiter: '.', withoutTime: true })}`;
    } else if (start) {
      return `с ${getDateFromTimestamp(new Date(start), false, { delimiter: '.', withoutTime: true })}`;
    } else if (end) {
      return `по ${getDateFromTimestamp(new Date(end), false, { delimiter: '.', withoutTime: true })}`;
    }
    return '';
  };

  const dateRanges = useMemo(() => [
    { label: 'Дата создания', min: 'minCreationDate', max: 'maxCreationDate' },
  ], [filterObj]);

  const combinedDateTags = useMemo(() => {
    return dateRanges.map(range => {
      const start = filterObj[range.min];
      const end = filterObj[range.max];
      if (start || end) {
        return {
          label: range.label,
          value: formatDateRange(start, end),
          keys: [range.min, range.max]
        };
      }
      return null;
    }).filter(Boolean);
  }, [filterObj]);

  const displayTags = useMemo(() => {
    const nonDateTags = selectedOptions.filter(option =>
      !option.optionValue.category.includes('Date')
    );

    const statusTags = query.status ?
      query.status.split(',').map(statusKey => {
        const statusObj = aggregateStatuses.find(s => s.key === statusKey);
        return {
          optionValue: {
            category: 'status',
            key: statusKey,
            text: statusObj?.text || statusKey,
            name: 'Статус'
          }
        };
      }) : [];

    return [...nonDateTags, ...combinedDateTags, ...statusTags];
  }, [selectedOptions, combinedDateTags, query.status]);

  const onTagClick = (option) => {
    let updatedFilterObj = { ...filterObj };
    if (Array.isArray(option.keys)) {
      option.keys.forEach(key => {
        updatedFilterObj[key] = undefined;
      });
    } else {
      const category = option.optionValue?.category;
      if (category === 'status') {
        const currentStatuses = query.status?.split(',') || [];
        const updatedStatuses = currentStatuses.filter(s => s !== option.optionValue.key);
        updatedFilterObj.status = updatedStatuses.length ? updatedStatuses.join(',') : undefined;
      } else {
        updatedFilterObj[category] = undefined;
      }
    }

    setSelectedOptions(selectedOptions.filter((o) =>
      o.optionValue?.category !== option.optionValue?.category &&
      !option.keys?.includes(o.optionValue?.category)
    ));

    setFilterObj(updatedFilterObj);
    setQuery(
      (prev) => ({
        ...prev,
        ...updatedFilterObj
      }),
      'replace'
    );
  };

  const resetAllHandler = () => {
    setQuery({
      ...query,
      pageNumber: 1,
      level: undefined,
      companyId: undefined,
      productId: undefined,
      minCreationDate: undefined,
      maxCreationDate: undefined,
      status: undefined
    }, 'replace');
    setFilterObj({ ...filterObj, companyId: undefined, level: undefined, productId: undefined, maxCreationDate: undefined, minCreationDate: undefined, status: undefined });
    setSelectedOptions([]);
  };
  console.log(selectedOptions, 'selectedOptions');
  console.log(filterObj, 'filterObj');
  console.log(query, 'query');

  const renderCombobox = (title, category, options) => (
    <Combobox
      value={title}
      className={styles.combobox}
      aria-labelledby={labelledBy}
      selectedOptions={selectedOptions.filter(o => o.optionValue.category === category)}
      onOptionSelect={(_, item) => onOptionSelect(item)}
      ref={comboboxInputRef}
      readOnly={true}
    >
      <div className={styles.scrollableMenuList}>
        {options.map((option) => (
          <Option
            value={{ key: option.key, text: option.text, category: category }}
            key={option.key}
          >
            {option.text}
          </Option>
        ))}
      </div>
    </Combobox>
  );

  const CustomDatePicker = ({
    label,
    placeholder,
    dateKey,
    filterObj,
    onOptionSelect,
    minDate,
    maxDate
  }) => {
    const handleDateSelect = useCallback((date) => {
      if (date) {
        onOptionSelect({
          optionValue: {
            key: convertToUTCDate(date),
            text: getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true }),
            category: dateKey
          }
        });
      }
    }, [onOptionSelect, dateKey]);

    return (
      <DatePicker
        className={styles.datePicker}
        placeholder={placeholder}
        firstDayOfWeek={DayOfWeek.Monday}
        isRequired={false}
        allowTextInput={true}
        formatDate={(date) => {
          return !date
            ? null
            : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
        }}
        strings={DayPickerStrings}
        value={filterObj[dateKey] ? new Date(filterObj[dateKey]) : null}
        onSelectDate={handleDateSelect}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
      />
    );
  };

  return (
    <div>
      <div className={styles.root + ' ' + g.filtersMainWrapper}>
        {dataFilter.map((filter) => {
          switch (filter.type) {
            case 'combobox':
              return renderCombobox(filter.label, filter.key, filter.options);
            case 'datepicker':
              return (
                <CustomDatePicker
                  key={filter.key}
                  placeholder={filter.label}
                  dateKey={filter.key}
                  filterObj={filterObj}
                  onOptionSelect={onOptionSelect}
                  minDate={filter.key === 'maxCreationDate' ? filterObj.minCreationDate : undefined}
                  maxDate={filter.key === 'minCreationDate' ? filterObj.maxCreationDate : undefined}
                />
              );
            default:
              return null;
          }
        })}
      </div>

      {displayTags.length > 0 && (
        <ul
          id={selectedListId}
          className={styles.tagsList}
          ref={selectedListRef}
        >
          <span id={`${comboId}-remove`} hidden>
            Remove
          </span>
          {displayTags.map((option, i) => (
            <li className={styles.tag} key={i}>
              <Button
                size="small"
                shape="circular"
                icon={<Dismiss12Regular />}
                iconPosition="after"
                onClick={() => onTagClick(option)}
                id={`${comboId}-remove-${i}`}
                aria-labelledby={`${comboId}-remove ${comboId}-remove-${i}`}
              >
                {option.optionValue ?
                  `${option.optionValue.name || ''}: ${option.optionValue.text}` :
                  `${option.label}: ${option.value}`
                }
              </Button>
            </li>
          ))}
          <LinkButton isButton={true} text={'Сбросить всё'} onClick={() => resetAllHandler()} />
        </ul>
      )}
    </div>
  );
};
