import axios from 'axios';
import { handleErrorFunc } from '../App';
import { LOCAL_STORAGE_DATA_NAME } from '../constants';
import { errorHandlerByStatus } from './core';
// import { store } from '../redux/index';

class API {
  constructor(localStorageData = LOCAL_STORAGE_DATA_NAME) {
    const baseUrlFromLS = localStorage.getItem('BASE_URL');
    this.url = baseUrlFromLS || process.env.REACT_APP_BASE_URL || '';
    //this.url = 'http://api.up-line-dev.asap-lp.ru';
    this.localStorageData = localStorageData;
  }

  handleSuccess = (response) => {
    const res = response.data;

    if (res?.statusCode && res?.statusCode !== 200) {
      handleErrorFunc(res?.statusCode, res?.message);
    }

    return response;
  };

  handleError = (error) => {
    if (error.toString().includes('Network')) {
      errorHandlerByStatus(503, handleErrorFunc);
    }
    if (!!error.response?.status) {
      if (error.response.status === 401) {
        handleErrorFunc(401, '');
      } else {
        errorHandlerByStatus(error.response.status, handleErrorFunc);
      }
    }

    return Promise.reject(error.message);
  };

  create = async (headers) => {
    const localStorageData = JSON.parse(localStorage.getItem(this.localStorageData));
    let token = localStorageData?.accessToken;

    const headerAuth = token && { Authorization: token ? `Bearer ${token}` : '' };
    const service = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
        ...headerAuth
      }
    });

    service.interceptors.response.use(this.handleSuccess, this.handleError);
    service.interceptors.request.use((request) => {
      return request;
    });

    service.interceptors.request.use((request) => {
      return request;
    });

    service.interceptors.response.use((response) => {
      return response;
    });

    service.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.toString().includes('Network')) {
          this.handleError({
            message: 'Отсутствует соединение с сервером'
          });
        }
        if (!!error?.response) {
          if (!!error.response?.status) {
            errorHandlerByStatus(error.response.status, this.handleError, error.response?.data);
          }
          // if (typeof error?.response?.data?.errors === 'object') {
          //   Object.entries(error?.response?.data?.errors).forEach(
          //     (errorArr) => console.log('single error arr', errorArr[1])
          //     // console.log('error messages', errorArr[1])
          //   );
          // }
        }
      }
    );

    return service;
  };

  get = async (path = '', headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'GET',
        url: `${this.url}${path}`
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };

  post = async (path = '', data = {}, headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'POST',
        url: `${this.url}${path}`,
        data
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };

  put = async (path = '', data = {}, headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'PUT',
        url: `${this.url}${path}`,
        data
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };

  patch = async (path = '', data = {}, headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'PATCH',
        url: `${this.url}${path}`,
        data
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  delete = async (path = '', headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'DELETE',
        url: `${this.url}${path}`
      })
      .then((res) => res?.data)
      .catch((err) => this.handleError(err));
  };
}

export class API2 {
  constructor(localStorageData = LOCAL_STORAGE_DATA_NAME) {
    const baseUrlFromLS = localStorage.getItem('BASE_URL');
    this.url = baseUrlFromLS || process.env.REACT_APP_BASE_URL || '';
    this.localStorageData = localStorageData;
  }

  handleSuccess = (response) => {
    const res = response.data;

    if (res?.statusCode && res?.statusCode !== 200) {
      if (res?.statusCode !== 401) {
        handleErrorFunc(res?.statusCode, res?.message);
      }
    }

    return response;
  };

  handleError = (error) => {
    handleErrorFunc(null, error);
    return Promise.reject(error);
  };

  create = async (headers) => {
    const localStorageData = JSON.parse(localStorage.getItem(this.localStorageData));
    let token = localStorageData?.accessToken;

    const headerAuth = token && { Authorization: token ? `Bearer ${token}` : '' };
    const service = axios.create({
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
        ...headerAuth
      }
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    service.interceptors.request.use((request) => {
      return request;
    });

    service.interceptors.response.use((response) => {
      return response;
    });

    return service;
  };

  get = async (path = '', headers) => {
    const service = await this.create(headers);
    return service
      .request({
        method: 'GET',
        url: `${this.url}${path}`
      })
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  };

  post = async (path = '', data = {}, headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'POST',
        url: `${this.url}${path}`,
        data
      })
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  };

  put = async (path = '', data = {}, headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'PUT',
        url: `${this.url}${path}`,
        data
      })
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  };

  patch = async (path = '', data = {}, headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'PATCH',
        url: `${this.url}${path}`,
        data
      })
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  };

  delete = async (path = '', headers) => {
    const service = await this.create(headers);

    return service
      .request({
        method: 'DELETE',
        url: `${this.url}${path}`
      })
      .then((res) => res.data)
      .catch((err) => this.handleError(err));
  };
}

export default new API();
