import React, { useEffect, useState } from 'react';
import AggregatesAPI from '../../api/AggregatesAPI';
import g from '../../assets/scss/Main.module.scss';
import s from './Aggregates.module.scss';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { aggregateStatuses, moreInfoIcon } from '../../constants';
import { getDateFromTimestamp, getStatusById, mapItemsForDropdown, mapProductsForDropdown } from '../../functions';
import { useCheckboxFilter, usePaginationAndSearch, useDataFilter, useSelect } from '../../hooks';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { useCrumbsContext } from 'context/CrumbsContext';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { ButtonMenu } from 'components/ButtonMenu/ButtonMenu';
import { LinkELement } from 'components/LinkELement/LinkELement';
import { AggregatesFilterNew } from 'components/Filters/AggregatesFilterNew/AggregatesFilterNew';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import productsAPI from 'api/productsAPI';
import legalAPI from 'api/legalAPI';
import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';
import { useTableFeatures, useTableSort } from '@fluentui/react-components';

const tableHeader = [
  {
    title: 'Код агрегата',
    width: '0.17',
    columnId: 'unitSerialNumber',
    sortable: false
  },
  {
    title: 'Дата создания',
    width: '0.13',
    columnId: 'creationDate',
    sortable: true
  },
  {
    title: 'Ёмкость',
    width: '0.1',
    columnId: 'aggregationUnitCapacity',
    sortable: true
  },
  {
    title: 'Уровень',
    width: '0.1',
    columnId: 'level',
    sortable: true
  },
  {
    title: 'Статус',
    width: '0.13',
    columnId: 'status',
    sortable: true
  },
  {
    title: 'Продукт',
    width: '0.25',
    columnId: 'product.name',
    sortable: true
  },
];

const defaultIntitVals = {
  brand: null,
  category: null,
  subCategory: null,
  maxRemainderOfMarkingCodes: null,
  minRemainderOfMarkingCodes: null,
  companyId: null
};

export const Aggregates = ({ status, statusParamName }) => {
  const [aggregates, setAggregates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('aggregates');
  const { setCrumbs } = useCrumbsContext();
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);


  useEffect(() => {
    if (status && !query.status) {
      setQuery((prev) => ({ ...prev, status: status }), 'replace');
    }
  }, [status]);

  const getAggregates = async (query) => {
    setLoading(true);
    let res = await AggregatesAPI.getAggregates(query);

    if (!res?.statusCode) {
      const { list, quantity } = res;
      setAggregates(list);
      setTotalItems(quantity);
    }
    setLoading(false);
    return res;
  };

  const [productsForDropdown, getProductsForDropdown] = useSelect(
    productsAPI.getAllProducts,
    null,
    mapProductsForDropdown
  );
  const [levelsForDropdown] = useState([
    { key: 1, text: 1 },
    { key: 2, text: 2 }
  ]);
  const [companiesForDropdown, setCompaniesForDropdown] = useState([]);
  useEffect(() => {
    getProductsForDropdown();
    legalAPI.getCompanies().then((res) => setCompaniesForDropdown(mapItemsForDropdown(res)));
  }, []);

  useEffect(() => {
    const filterData = [
      { name: 'Продукт', key: 'productId', options: productsForDropdown },
      { name: 'Уровень', key: 'level', options: levelsForDropdown },
      { name: 'Компания', key: 'companyId', options: companiesForDropdown },
      {
        name: 'Дата создания от',
        key: 'minCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      },
      {
        name: 'Дата создания до',
        key: 'maxCreationDate',
        getValue: (date) => new Date(date).getTime(),
        getText: (date) => getDateFromTimestamp(new Date(date), false, { delimiter: '.', withoutTime: true })
      }
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, name: filter.name }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              name: filter.name
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, companiesForDropdown, productsForDropdown, levelsForDropdown]);

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getAggregates, query, setQuery, statusParamName, status, headerHeight: 180 });
  const [selectedFilters, onChangeFilter] = useCheckboxFilter(
    aggregateStatuses,
    'status',
    setQuery,
    query,
  );

  const {
    sort: { getSortDirection, toggleColumnSort },
  } = useTableFeatures(
    {
      columns: tableHeader,
      items: aggregates,
    },
    [
      useTableSort({
        defaultSortState: { sortColumn: 'creationDate', sortDirection: 'ascending' },
      }),
    ]
  );

  const handleSort = (columnId) => {
    const event = { preventDefault: () => { } };
    const currentDirection = getSortDirection(columnId);

    toggleColumnSort(event, columnId);

    const direction = currentDirection === 'ascending' ? -1 : 1;

    const sortedAggregates = [...aggregates].sort((a, b) => {
      if (columnId.includes('.')) {
        const [parent, child] = columnId.split('.');
        const valueA = a[parent]?.[child] ?? '';
        const valueB = b[parent]?.[child] ?? '';
        return String(valueA).localeCompare(String(valueB)) * direction;
      }

      if (columnId === 'creationDate') {
        return (new Date(a[columnId] || 0) - new Date(b[columnId] || 0)) * direction;
      }

      if (typeof a[columnId] === 'number' && typeof b[columnId] === 'number') {
        return (a[columnId] - b[columnId]) * direction;
      }

      const valueA = a[columnId] ?? '';
      const valueB = b[columnId] ?? '';
      return String(valueA).localeCompare(String(valueB)) * direction;
    });

    setAggregates(sortedAggregates);
  };

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            className={g.search}
            value={query.searchValue}
            placeholder="Поиск по коду агрегата"
            onClear={() => onSearchTermChanged('')}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={clsx(g.headerFilters)} >
          <ButtonMenu
            title={'Статус'}
            dataArray={aggregateStatuses}
            selectedFilters={selectedFilters}
            onChangeFilter={onChangeFilter}
            isCheckbox={true}
            optionSize={selectedFilters.size}
          />
          <ButtonToggle
            text="Фильтры"
            value={isOpenFilters}
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            optionSize={selectedOptions.length}
          />
        </div>
      </div>
      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <AggregatesFilterNew
            productsForDropdown={productsForDropdown}
            levelsForDropdown={levelsForDropdown}
            companiesForDropdown={companiesForDropdown}
            query={query}
            setQuery={setQuery}
            setIsOpenFilters={setIsOpenFilters}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
      )}
      <div className={s.mainContent}>
        <div className={s.tableWrapper}>
          {!aggregates.length && selectedOptions.length ? (
            <div className={g.searchWithFiltersVoid}>
              Не найдено. Измените параметры фильтрации
            </div>
          ) : (
            <Table
              headerItems={tableHeader}
              loading={loading}
              onSort={handleSort}
              sortState={{ getSortDirection }}
            >
              {aggregates?.map((item) => {
                return (
                  <div key={item.id} className={s.tableItem}>
                    <div
                      className={s.tableCell}
                      style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})` }}
                    >
                      <TooltipHost
                        content={item.unitSerialNumber}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <LinkELement
                          addStyles={clsx(s.tableRow, s.firstRow)}
                          link={`/aggregates/${item.unitSerialNumber}`}
                          text={item.unitSerialNumber}
                          appearance={'subtle'}
                        />
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})` }}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {getDateFromTimestamp(item.creationDate, null, { withoutTime: true })}
                      </div>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})` }}
                    >
                      <TooltipHost
                        content={item.aggregationUnitCapacity}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item.aggregationUnitCapacity}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})` }}
                    >
                      <TooltipHost
                        content={item.level}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item.level}
                        </div>
                      </TooltipHost>
                    </div>
                    <div
                      className={s.tableCell}
                      style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[4].width})` }}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {getStatusById(item.status, aggregateStatuses)}
                      </div>
                    </div>
                    <div
                      className={clsx(s.tableCellProduct)}
                      style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[5].width})` }}
                    >
                      <TooltipHost
                        content={item?.product?.name}
                        delay={TooltipDelay.medium}
                        directionalHint={DirectionalHint.topCenter}
                      >
                        <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {item?.product?.name}
                        </div>
                      </TooltipHost>
                    </div>

                    {/* <LinkButton link={`/aggregates/${item.unitSerialNumber}`} /> */}
                    {/* <TableAction
                      link={`/aggregates/${item.unitSerialNumber}`}
                      text={'Подробнее'}
                      tooltipText={'Подробнее'}
                      iconName={moreInfoIcon}
                    />            */}
                  </div>
                );
              })}
            </Table>
          )}
        </div>
      </div>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};
