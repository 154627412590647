import { Dropdown } from '@fluentui/react';
import { Field, useField } from 'formik';
import { expirationDateTypeRefObj } from './addSideProducts.constants';
import Input from 'components/Forms/Input';
import ComboSelect from 'components/Forms/customSelect/comboSelect';
import s from './Expiration.module.scss';

const Expiration = () => {
  const [expField, __, typeHelpers] = useField('expirationDateType');
  const newExpirationDateTypeRefObj = expirationDateTypeRefObj?.map((comp) => ({
    value: comp.key,
    label: comp.text
  }));
  const onChange = (arg) => typeHelpers.setValue(arg.value);

  return (
    <section className={s.expiration}>
      <Field name={'expirationDateType'}>
        {(props) => {
          return (
            <ComboSelect
              options={newExpirationDateTypeRefObj}
              onChange={onChange}
              label="Единица измерения срока годности"
              placeholder={
                newExpirationDateTypeRefObj.find((exp) => exp.value === expField.value)?.label ||
                newExpirationDateTypeRefObj[0]?.label ||
                ''
              }
              isSearchable={false}
              customFieldStyles={{ marginBottom: '0.5rem' }}
            />
          );
        }}
      </Field>

      <Input name="expirationDate" type="number" label="Срок годности" className={s.textInput} />
    </section>
  );
};

export default Expiration;
