import { wideScreen } from '../../constants';

export const printingTableHeader = [
  { name: 'productName', title: 'Продукт', maxLength: 15 },
  { name: 'partNumber', title: 'Задание' },
  { name: 'creationDate', title: 'Дата создания' },
  { name: 'sendingDate', title: 'Дата отправки' },
  { name: 'productCreationDate', title: 'Дата производства' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ' },
  { name: 'productGtin', title: 'Gtin' },
  { name: 'status', title: 'Статус' },
  { name: 'suzId', title: 'Код отчёта', maxLength: 10 },
  { name: 'serialNumber', title: 'Серия' }
];

export const aggregationTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10 },
  { name: 'partNumber', title: 'Задание' },
  { name: 'unitSerialNumber', title: 'Код агрегата', maxLength: 10 },
  { name: 'creationDate', title: 'Дата создания' },
  { name: 'sendingDate', title: 'Дата отправки' },
  { name: 'status', title: 'Статус' },
  { name: 'productName', title: 'Продукт', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ' },
  { name: 'serialNumber', title: 'Серия' }
];

export const enteringTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10 },
  { name: 'partNumber', title: 'Задание' },
  { name: 'creationDate', title: 'Дата создания' },
  { name: 'sendingDate', title: 'Дата отправки' },
  { name: 'productCreationDate', title: 'Дата производства' },
  { name: 'status', title: 'Статус' },
  { name: 'productName', title: 'Продукт', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin' },
  { name: 'vsd', title: 'ВСД' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ' },
  { name: 'serialNumber', title: 'Серия' }
];

export const rejectTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10 },
  { name: 'partNumber', title: 'Задание' },
  { name: 'creationDate', title: 'Дата создания' },
  { name: 'sendingDate', title: 'Дата отправки' },
  { name: 'status', title: 'Статус' },
  { name: 'dropoutReason', title: 'Причины отсева' },
  { name: 'productName', title: 'Продукт' },
  { name: 'productGtin', title: 'Gtin' },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ' },
  { name: 'serialNumber', title: 'Серия' }
];

export const setsTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10 },
  { name: 'partNumber', title: 'Задание' },
  { name: 'creationDate', title: 'Дата создания' },
  { name: 'sendingDate', title: 'Дата отправки' },
  { name: 'productCreationDate', title: 'Дата производства' },
  { name: 'status', title: 'Статус' },
  { name: 'productName', title: 'Продукт', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', maxLength: 10 },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', headerStyles: { padding: '0 0.5rem' } },
  { name: 'serialNumber', title: 'Серия' }
];

export const withdrInternConsupmTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10 },
  { name: 'partNumber', title: 'Задание' },
  { name: 'creationDate', title: 'Дата создания' },
  { name: 'sendingDate', title: 'Дата отправки' },
  { name: 'actionDate', title: 'Дата вывода', headerStyles: { padding: '0 3px' } },
  { name: 'status', title: 'Статус' },
  { name: 'productName', title: 'Продукт', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', maxLength: 10 },
  { name: 'action', title: 'Причина выбытия', headerStyles: { padding: '0 0.5rem' } },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', headerStyles: { paddingRight: '1rem', textAlign: 'center' } },
];

export const writeOffTableHeader = [
  { name: 'suzId', title: 'Код отчёта', maxLength: 10 },
  { name: 'creationDate', title: 'Дата создания' },
  { name: 'sendingDate', title: 'Дата отправки' },
  { name: 'sourceDocDate', title: 'Дата вывода', headerStyles: { padding: '0 3px' } },
  { name: 'status', title: 'Статус' },
  { name: 'productName', title: 'Продукт', maxLength: 15 },
  { name: 'productGtin', title: 'Gtin', maxLength: 10 },
  { name: 'dropoutReason', title: 'Причина выбытия', headerStyles: { padding: '0 0.5rem' } },
  { name: 'numberMarkingCodes', title: 'Кол-во КМ', headerStyles: { paddingRight: '1rem', textAlign: 'center' } },
];

export const isChkboxDisbabled = (status) => status !== 'CREATED' && status !== 'REJECTED';
export const isChkboxDisbabledEnt = (status) => status !== 'CREATED' && status !== 'REJECTED';

export const mockSetsReport = {
  quantity: 3,
  list: [
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    },
    {
      creationDate: 1708010882000,
      errorMessage: null,
      id: 1068,
      numberMarkingCodes: 1,
      partNumber: 'xMark2590',
      productCreationDate: 1708009200000,
      productGtin: '00000090876127',
      productName: 'Вода',
      sendingDate: null,
      status: 'CREATED'
    }
  ]
};

export const getTypesInfo = () => ({
  printing: {
    title: 'нанесения',
    tableHeader: printingTableHeader
  },
  aggregation: {
    title: 'агрегации',
    tableHeader: aggregationTableHeader
  },
  entering: {
    title: 'ввода в оборот',
    tableHeader: enteringTableHeader
  },
  rejection: {
    title: 'отбраковка',
    tableHeader: rejectTableHeader
  },
  sets: {
    title: 'наборы',
    tableHeader: setsTableHeader
  },
  withrdIntrnlConsmpt: {
    title: 'вывод из оборота',
    tableHeader: withdrInternConsupmTableHeader
  },
  writeOff: {
    title: 'списания',
    tableHeader: writeOffTableHeader
  }
});
