import React from 'react';
import s from './SyncStatus.module.scss';
import g from '../../assets/scss/Main.module.scss';
import { Icon, PrimaryButton } from '@fluentui/react';
import clsx from 'clsx';

export const SyncStatus = ({
  syncStatus,
  hide,
  errorText = 'Синхронизация завершилась неудачно',
  successText = 'Синхронизация прошла успешно'
}) => {
  return (
    <div className={g.overlay} onClick={hide}>
      <div className={s.syncRes}>
        {syncStatus === 'success' && (
          <Icon iconName={'SkypeCircleCheck'} className={clsx(s.icon, s.successIcon)} />
        )}
        {syncStatus === 'error' && (
          <Icon iconName={'StatusErrorFull'} className={clsx(s.icon, s.errorIcon)} />
        )}
        <span className={clsx(s.syncStatusText, syncStatus === 'error' && s.syncStatusErrorText)}>
          {syncStatus === 'error' ? errorText : syncStatus === 'success' ? successText : ''}
        </span>
        <PrimaryButton className={s.syncResBtn} onClick={hide}>
          Закрыть
        </PrimaryButton>
      </div>
    </div>
  );
};
