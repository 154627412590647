import { useId } from '@fluentui/react-hooks';
import { Icon, TooltipHost } from '@fluentui/react';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, makeStyles, tokens, Tooltip, mergeClasses } from '@fluentui/react-components';
import { MoreHorizontalRegular } from '@fluentui/react-icons';
import clsx from 'clsx';

const useClasses = makeStyles({
  target: {
    color: tokens.colorNeutralForeground3,
    ':hover': {
      backgroundColor: tokens.colorPaletteBlueBorderActive,
      color: tokens.colorNeutralBackground1,
    },
  },
  primaryOn: {
    color: tokens.colorNeutralForegroundInverted, // Инвертированный цвет текста
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1,
      color: tokens.colorNeutralForeground2,
      border: '1px solid tokens.colorNeutralForeground2',
    },
  },
});

export const LinkButton = ({
  link,
  iconName,
  text,
  isButton = false,
  onClick,
  params,
  disabled,
  tooltipText,
  type = 'default',
  addStyles,
  getProductBalance,
  primary
}) => {
  const classes = useClasses();
  const tooltipId = useId('tooltip');

  return (
    <div style={{ maxWidth: 'max-content' }}>
      {/* <Tooltip content={null} relationship="label" showDelay={800}  > */}
      <NavLink to={{
        pathname: link,
        state: params
      }}>
        <Button
          disabled={disabled}
          appearance={primary ? "primary" : undefined}
          className={!disabled && mergeClasses(
            classes.target,
            primary && classes.primaryOn,
          )}
          onClick={onClick}
          icon={isButton ? null : iconName ?? <MoreHorizontalRegular />}
        >
          {text}
        </Button>
      </NavLink>
      {/* </Tooltip> */}
    </div>
  );
};
