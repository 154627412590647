import React from 'react';
import { Toggle } from '@fluentui/react';
import { Field } from 'formik';

const SwitchCheckbox = (props) => {
  const {
    label = '',
    type = 'checkbox',
    name,
    className,
    onText = 'вкл',
    offText = 'выкл'
  } = props;

  return (
    <Field {...props}>
      {({ field, form }) => {
        const handleChange = (e, checked) => {
          form.setFieldValue(name, checked);
        };

        return (
          <Toggle
            {...field}
            role="switch"
            type={type}
            label={label}
            onText={onText}
            offText={offText}
            name={name}
            onChange={handleChange}
            checked={field.value}
          />
        );
      }}
    </Field>
  );
};

export default SwitchCheckbox;
