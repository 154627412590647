import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import s from './index.module.scss';
import g from '../../assets/scss/Main.module.scss';
import { SearchBox } from '@fluentui/react';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { Pagination } from '../../components/Pagination/Pagination';
import { useCrumbsContext } from '../../context/CrumbsContext';
import LegalAPI from '../../api/legalAPI';
import { usePaginationAndSearch } from '../../hooks';
import { Checkbox } from '@fluentui/react';
import { addIcon } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getCompanyInfo } from 'redux/LegalFaces/legalFacesSelectors';
import clsx from 'clsx';
import { setCompanyAC, setErrorAC } from 'redux/LegalFaces/legalFacesReducer';
import productsAPI from 'api/productsAPI';
import { LinkButton } from 'components/LinkButton/LinkButton';

const tableHeader = [
  {
    title: 'Название',
    width: '0.4'
  },
  {
    title: 'ИНН',
    width: '0.3'
  },
  {
    title: 'Количество продуктов',
    width: '0.15'
  }
  //   {
  //     title: 'Действия',
  //     width: '0.15'
  //   }
];

const ProductOwners = () => {
  const { setCrumbs } = useCrumbsContext();
  const [legalFaces, setLegalFaces] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    pageNumber: NumberParam,
    searchValue: StringParam,
    quantityOnPage: NumberParam,
    roleName: StringParam
  });
  const dispatch = useDispatch();
  const { id: companyId } = useSelector(getCompanyInfo);
  const history = useHistory();

  useEffect(() => {
    setCrumbs([]);
    setQuery(query, 'replace');
  }, []);

  const getLegalFaces = async (isActive, isArchived) => {
    setLoading(true);
    //let res = await LegalAPI.getCompanies(query);
    let res = await productsAPI.getAllProductOwners();
    console.log('RES-OWNERS', res);
    setLegalFaces(res);
    setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, currentPage } = usePaginationAndSearch({
    callback: () => getLegalFaces(true, false),
    query,
    setQuery
  });

  //   const handleDetailsClick = async (id) => {
  //     setLoading(true);
  //     try {
  //       const legalFace = await LegalAPI.getCompanyById2(id);
  //       dispatch(setCompanyAC(legalFace));
  //       history.push(`/legal/${legalFace.id}`);
  //     } catch (e) {
  //       dispatch(setErrorAC(e?.message));
  //     }
  //     setLoading(false);
  //   };

  return (
    <div>
      <div className={g.titleWrapper}>
        <h1 className={g.title}>Владельцы продуктов</h1>
        {/* <SearchBox
          styles={searchBoxStyles}
          placeholder="Поиск по Названию компании"
          value={query.searchValue}
          onClear={() => {
            onSearchTermChanged('');
          }}
          onSearch={(newValue) => onSearchTermChanged(newValue)}
        /> */}
      </div>

      <div className={g.footer} style={{ margin: ' 0.75rem 0  0.75rem 1rem' }}>
        <LinkButton
          isButton={true}
          text="Добавить владельца продукта"
          primary={true}
          onClick={() => history.push(`productowners/add`)}
        />
      </div>

      <div className={s.checkBoxWrapper}></div>
      <Table headerItems={tableHeader} maxHeight="50vh" loading={loading}>
        {legalFaces?.map((item) => {
          return (
            <div key={item.id} className={g.tableItem}>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})` }}
              >
                {item.name}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})` }}
              >
                {item.inn}
              </div>
              <div
                className={g.tableCell}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})` }}
              >
                {item.productNumber}
              </div>
              {/* <div
                className={clsx(g.tableCell, `${s.productsActionsItems}`)}
                style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})` }}
              >
                <TableAction
                  iconName={moreInfoIcon}
                  text="Подробнее"
                  onClick={() => handleDetailsClick(item.id)}
                  isButton
                />
              </div> */}
            </div>
          );
        })}
      </Table>
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>
    </div>
  );
};

export default ProductOwners;
