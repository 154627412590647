import { all } from 'redux-saga/effects';
import { AuthSaga } from './Auth/authSaga';
import { LogsSaga } from './Logs/LogsSaga';
import { printersSaga } from './Printers/PrintersSaga';
import { LegalSaga } from './LegalFaces/legalFacesSaga';
import { AppSaga } from './App/appSagas';
import { NavSaga } from './Nav/NavSaga';

export default function* rootSaga() {
  yield all([AuthSaga(), LogsSaga(), printersSaga(), LegalSaga(), AppSaga(), NavSaga()]);
}
