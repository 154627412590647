import { SET_FILTERED_NAV_RED } from './NavAction.js';

const initialState = {
    filteredNavRed: [],
};

const NavReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTERED_NAV_RED:
            return {
                ...state,
                filteredNavRed: action.payload,
            };
        default:
            return state;
    }
};

export default NavReducer;