import { useId } from '@fluentui/react-hooks';
import { Icon, TooltipHost } from '@fluentui/react';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, makeStyles, tokens, Tooltip, Link } from '@fluentui/react-components';
import { MoreHorizontalRegular } from '@fluentui/react-icons';

const useClasses = makeStyles({
  target: {
  },
});
export const LinkELement = ({
  link,
  iconName,
  text,
  isButton = false,
  onClick,
  params,
  disabled,
  tooltipText,
  type = 'default',
  addStyles,
  getProductBalance,
  appearance
}) => {
  const classes = useClasses()
  const tooltipId = useId('tooltip');

  return (
    <div className={addStyles}>
      {/* <Tooltip content={null} relationship="label" showDelay={800}  > */}
      <NavLink to={{
        pathname: link,
        state: params
      }}>
        <Link
          appearance={appearance}
          onClick={onClick}
          icon={isButton ? null : iconName ?? <MoreHorizontalRegular />}
        >
          {text}
        </Link>
      </NavLink>
      {/* </Tooltip> */}
    </div>
  );
};
