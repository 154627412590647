import { memo } from 'react';
import { Checkbox } from '@fluentui/react';
import { useField } from 'formik';
import s from '../index.module.scss';

const Archive = () => {
  const [field, _, helpers] = useField('isArchived');

  const handleChange = () => {
    if (field.value) {
      helpers.setValue(false);
    } else {
      helpers.setValue(true);
    }
  };

  return (
    <main className={s.fieldWrapper}>
      <span className={s.fieldTitle}>Архивация юридического лица</span>
      <section className={s.singleCheckbox}>
        <Checkbox onChange={handleChange} disabled={false} checked={field.value} />
        <span className={s.checkboxText}>{'Занести юрлицо в архив'}</span>
      </section>
    </main>
  );
};

export default memo(Archive);
