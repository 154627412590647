import React, { useEffect, useRef, useState } from 'react';
import UsersAPI from '../../../api/usersAPI';
import { mapItemsForDropdown } from '../../../functions';
import { setFilteredData } from 'redux/App/appReducer';
import { useDispatch } from 'react-redux';
import { Button, Combobox, makeStyles, Option, tokens, useId } from '@fluentui/react-components';
import { Dismiss12Regular } from "@fluentui/react-icons";
import g from '../../../assets/scss/Main.module.scss';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: "space-between",
    gap: '12px',
    margin: '8px 0'
  },
  scrollableMenuList: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
  },
  otherInputWidth: {
    minWidth: "auto",
    "::after": {
      display: "none"
    },
    border: 'none',
    "> input": {
      width: "calc(13.9rem)",
      cursor: 'pointer'
    },
    "> span:nth-child(4)": {
      display: 'none'
    }
  },
  tag: {
    "& button": {
      borderRadius: '4px',
      backgroundColor: '#DEECF9',
      height: '32px',
    }
  }
});

export const UsersFilterNew = ({ query, setQuery, selectedOptions, setSelectedOptions }) => {
  const styles = useStyles();
  const [rolesForFilter, setRolesForFilter] = useState([]);
  const dispatch = useDispatch();

  const comboId = useId("id");
  const selectedListId = `${comboId}-selection`;
  const selectedListRef = useRef(null);
  const comboboxInputRef = useRef(null);

  const labelledBy =
    selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

  useEffect(() => {
    UsersAPI.getRoles().then((res) => {
      if (res) {
        setRolesForFilter(mapItemsForDropdown(res));
      }
    });
  }, []);

  const onOptionSelect = (data) => {
    const updatedFilterObj = { roleName: data.optionValue.text };
    setQuery((prev) => ({ ...prev, ...updatedFilterObj, pageNumber: 1 }), 'replace');
    dispatch(setFilteredData(updatedFilterObj));
    setSelectedOptions([data]);
  };

  const onTagClick = (option, index) => {
    const updatedFilterObj = { roleName: undefined };
    setQuery((prev) => ({ ...prev, ...updatedFilterObj }), 'replace');
    dispatch(setFilteredData(updatedFilterObj));
    setSelectedOptions([]);

    const indexToFocus = index === 0 ? 0 : index - 1;
    const optionToFocus = selectedListRef.current?.querySelector(
      `#${comboId}-remove-${indexToFocus}`
    );
    if (optionToFocus) {
      optionToFocus.focus();
    } else {
      comboboxInputRef.current?.focus();
    }
  };

  const renderCombobox = (title, category, options) => (
    <Combobox
      className={styles.otherInputWidth}
      aria-labelledby={labelledBy}
      value={title}
      readOnly
      selectedOptions={selectedOptions}
      onOptionSelect={(_, item) => onOptionSelect(item)}
      ref={comboboxInputRef}
    >
      <div className={styles.scrollableMenuList}>
        {options.map((option) => (
          <Option
            value={{ key: option.key, text: option.text, category: category }}
            key={option.key}
          >
            {option.text}
          </Option>
        ))}
      </div>
    </Combobox>
  );

  return (
    <div>
      <div className={styles.root + ' ' + g.filtersMainWrapper}>
        {renderCombobox('Роль', 'roleName', rolesForFilter)}
      </div>

      {selectedOptions.length ? (
        <ul
          id={selectedListId}
          className={styles.tagsList}
          ref={selectedListRef}
        >
          <span id={`${comboId}-remove`} hidden>
            Remove
          </span>
          {selectedOptions.map((option, i) => (
            <li className={styles.tag} key={option}>
              <Button
                size="small"
                shape="circular"
                icon={<Dismiss12Regular />}
                iconPosition="after"
                onClick={() => onTagClick(option, i)}
                id={`${comboId}-remove-${i}`}
                aria-labelledby={`${comboId}-remove${comboId}-remove-${i}`}
              >
                {option?.optionValue ?
                  `${option.optionValue.label || ''}: ${option.optionValue.text}` :
                  `${option.label}: ${option.value}`
                }
              </Button>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};
