import { DatePicker, DayOfWeek, Label } from '@fluentui/react';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import g from '../../../assets/scss/Main.module.scss';
import { DayPickerStrings } from '../../../constants';
import {
  checkDateError,
  getDateFromTimestamp,
  convertToUTCDate,
} from '../../../functions';
import s from './ConsignmentsFilterNew.module.scss';
import { Button, Combobox, makeStyles, Option, tokens, useId } from '@fluentui/react-components';
import { Dismiss12Regular } from "@fluentui/react-icons";
import { setFilteredData } from 'redux/App/appReducer';
import { useDispatch } from 'react-redux';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { consignmentStatuses } from '../../../constants';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '12px',
    margin: '8px 0',
    flexWrap: 'wrap',

  },
  scrollableMenuList: {
    maxHeight: '500px',
    overflowY: 'auto',
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
    flexWrap: 'wrap',
  },
  tag: {
    "& button": {
      borderRadius: '4px',
      backgroundColor: '#DEECF9',
      height: '32px',
    }
  },
  otherInputWidth: {
    "::after": {
      display: "none"
    },
    border: 'none',
    "> input": {
      cursor: 'pointer'
    },
    "> span:nth-child(4) ": {
      display: 'none'
    }
  }
});

export const ConsignmentsFilterNew = ({ query, setQuery, selectedOptions, setSelectedOptions, productsForDropdown, linesForDropdown, companiesForDropdown }) => {
  const styles = useStyles();
  const [dateErrors, setDateErrors] = useState(new Set([]));
  const dispatch = useDispatch();

  const comboId = useId("id");
  const selectedListId = `${comboId}-selection`;

  const labelledBy =
    selectedOptions.length > 0 ? `${comboId} ${selectedListId}` : comboId;

  const [filterObj, setFilterObj] = useState({
    productId: query.productId,
    lineId: query.lineId,
    minProductCreationDate: query.minProductCreationDate,
    maxProductCreationDate: query.maxProductCreationDate,
    companyId: query.companyId
  });

  const dataFilter = [
    {
      key: 'companyId',
      label: 'Юридические лица',
      type: 'combobox',
      options: companiesForDropdown,
    },
    {
      key: 'lineId',
      label: 'Производственная линия',
      type: 'combobox',
      options: linesForDropdown,
    },
    {
      key: 'productId',
      label: 'Продукт',
      type: 'combobox',
      options: productsForDropdown,
    },
    {
      key: 'minProductCreationDate',
      label: 'Дата производства от',
      type: 'datepicker',
    },
    {
      key: 'maxProductCreationDate',
      label: 'Дата производства до',
      type: 'datepicker',
    },
  ];

  useEffect(() => {
    checkDateError(
      { productCreationDate: [filterObj.minProductCreationDate, filterObj.maxProductCreationDate] },
      dateErrors,
      setDateErrors
    );
  }, [filterObj.minProductCreationDate, filterObj.maxProductCreationDate]);

  const onOptionSelect = (data) => {
    const updatedFilterObj = { ...filterObj, [data.optionValue.category]: data.optionValue.key };
    setFilterObj(updatedFilterObj);

    const updatedOptions = selectedOptions.filter(
      option => option.optionValue.category !== data.optionValue.category
    );
    setSelectedOptions([...updatedOptions, data]);

    setQuery(
      prev => ({
        ...prev,
        [data.optionValue.category]: data.optionValue.key,
        pageNumber: 1
      }),
      'replace'
    );
    dispatch(setFilteredData({ [data.optionValue.category]: data.optionValue.key }));
  };

  const formatDateRange = (start, end) => {
    const formatDate = (date) => {
      if (!date) return '';
      const dateObj = new Date(date);
      return getDateFromTimestamp(dateObj, false, { delimiter: '.', withoutTime: true });
    };

    if (start && end) {
      return `${formatDate(start)} - ${formatDate(end)}`;
    } else if (start) {
      return `с ${formatDate(start)}`;
    } else if (end) {
      return `по ${formatDate(end)}`;
    }
    return '';
  };

  const dateRanges = useMemo(() => [
    { label: 'Дата производства', min: 'minProductCreationDate', max: 'maxProductCreationDate' },
  ], []);

  const combinedDateTags = useMemo(() => {
    return dateRanges.map(range => {
      const start = query[range.min];
      const end = query[range.max];
      if (start || end) {
        return {
          label: range.label,
          value: formatDateRange(start, end),
          keys: [range.min, range.max]
        };
      }
      return null;
    }).filter(Boolean);
  }, [query]);

  const displayTags = useMemo(() => {
    // Get non-date filter tags
    const nonDateTags = selectedOptions.filter(option =>
      !option.optionValue.category.includes('Date')
    );

    // Get status tags - handle comma-separated string
    const statusTags = query.status ? query.status.split(',').map(statusId => {
      const status = consignmentStatuses.find(s => s.key === statusId);
      return status ? {
        label: 'Статус',
        optionValue: {
          category: 'status',
          key: status.key,
          text: status.text,
          label: 'Статус'
        }
      } : null;
    }).filter(Boolean) : [];

    console.log('Status Tags:', statusTags); // Для отладки
    console.log('Query Status:', query.status); // Для отладки
    console.log('Combined Tags:', [...nonDateTags, ...combinedDateTags, ...statusTags]); // Для отладки

    return [...nonDateTags, ...combinedDateTags, ...statusTags];
  }, [selectedOptions, combinedDateTags, query.status]);

  const onTagClick = (option) => {
    let updatedFilterObj = {};
    if (Array.isArray(option.keys)) {
      option.keys.forEach(key => {
        updatedFilterObj[key] = undefined;
      });
    } else if (option.optionValue?.category === 'status') {
      // Handle status tag removal for comma-separated string
      const currentStatuses = query.status ? query.status.split(',') : [];
      const newStatuses = currentStatuses.filter(s => s !== option.optionValue.key);
      updatedFilterObj.status = newStatuses.length > 0 ? newStatuses.join(',') : undefined;
    } else {
      updatedFilterObj[option.optionValue?.category] = undefined;
    }

    setSelectedOptions(selectedOptions.filter((o) =>
      o.optionValue?.category !== option.optionValue?.category &&
      !option.keys?.includes(o.optionValue?.category) &&
      o.optionValue?.category !== 'status'
    ));

    setFilterObj(prev => ({ ...prev, ...updatedFilterObj }));
    setQuery(
      (prev) => ({
        ...prev,
        ...updatedFilterObj
      }),
      'replace'
    );
    dispatch(setFilteredData(updatedFilterObj));
    setDateErrors(new Set());
  };

  const resetAllHandler = () => {
    setFilterObj({
      companyId: undefined,
      lineId: undefined,
      productId: undefined,
      minProductCreationDate: undefined,
      maxProductCreationDate: undefined,
      status: undefined
    });
    setQuery({
      companyId: undefined,
      lineId: undefined,
      productId: undefined,
      minProductCreationDate: undefined,
      maxProductCreationDate: undefined,
      status: undefined
    });
    setSelectedOptions([]);
    dispatch(setFilteredData({}));
  };
  console.log(filterObj, query, selectedOptions);
  const renderCombobox = (title, category, options) => (
    <Combobox
      className={styles.otherInputWidth}
      aria-labelledby={labelledBy}
      value={title}
      selectedOptions={selectedOptions.filter(o => o.optionValue.category === category)}
      onOptionSelect={(_, item) => onOptionSelect(item)}
      readOnly={true}
    >
      <div className={styles.scrollableMenuList}>
        {options.map((option) => (
          <Option
            value={{ key: option.key, text: option.text, category: category }}
            key={option.key}
          >
            {option.text}
          </Option>
        ))}
      </div>
    </Combobox>
  );

  const CustomDatePicker = ({
    label,
    placeholder,
    dateKey,
    filterObj,
    onOptionSelect,
    minDate,
    maxDate
  }) => {
    const handleDateSelect = useCallback((date) => {
      if (date) {
        onOptionSelect({
          optionValue: {
            key: convertToUTCDate(date),
            text: getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true }),
            category: dateKey
          }
        });
      }
    }, [onOptionSelect, dateKey]);

    return (
      <DatePicker
        placeholder={placeholder}
        firstDayOfWeek={DayOfWeek.Monday}
        isRequired={false}
        allowTextInput={true}
        formatDate={(date) => {
          return !date
            ? null
            : getDateFromTimestamp(date, false, { delimiter: '.', withoutTime: true });
        }}
        strings={DayPickerStrings}
        value={filterObj[dateKey] ? new Date(filterObj[dateKey]) : null}
        onSelectDate={handleDateSelect}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
      />
    );
  };

  return (
    <div>
      <div className={styles.root + ' ' + g.filtersMainWrapper}>
        {dataFilter.map((filter) => {
          switch (filter.type) {
            case 'combobox':
              return renderCombobox(filter.label, filter.key, filter.options);
            case 'datepicker':
              return (
                <CustomDatePicker
                  key={filter.key}
                  placeholder={filter.label}
                  dateKey={filter.key}
                  filterObj={filterObj}
                  onOptionSelect={onOptionSelect}
                  minDate={filter.key === 'maxProductCreationDate' ? filterObj.minProductCreationDate : undefined}
                  maxDate={filter.key === 'minProductCreationDate' ? filterObj.maxProductCreationDate : undefined}
                />
              );
            default:
              return null;
          }
        })}
      </div>

      {displayTags.length > 0 && (
        <ul
          id={selectedListId}
          className={styles.tagsList}
        >
          <span id={`${comboId}-remove`} hidden>
            Remove
          </span>
          {displayTags.map((option, i) => (
            <li className={styles.tag} key={i}>
              <Button
                size="small"
                shape="circular"
                icon={<Dismiss12Regular />}
                iconPosition="after"
                onClick={() => onTagClick(option)}
                id={`${comboId}-remove-${i}`}
                aria-labelledby={`${comboId}-remove${comboId}-remove-${i}`}
              >
                {option.optionValue ?
                  `${option.optionValue.label || ''}: ${option.optionValue.text}` :
                  `${option.label}: ${option.value}`
                }
              </Button>
            </li>
          ))}
          <LinkButton isButton={true} text={'Сбросить всё'} onClick={() => resetAllHandler()} />
        </ul>
      )}

      {!!dateErrors.has('productCreationDate') && (
        <span className={s.error}>Неправильный диапазон дат</span>
      )}
    </div>
  );
};
