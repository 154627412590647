import { memo, useState, useEffect } from 'react';
import s from './places.module.scss';
import st from '../options.module.scss';
import { useField } from 'formik';
import SingleOption from './SingleOption';
// import { mockOptions } from './SingleOption';
import SelectedPlace from './SelectedPlace';
import { Alert } from 'components/Alert/Alert';
import { useCallback } from 'react';

const cutString = (string) => {
  const res = string.slice(0, 95);
  return res;
};

const getInitialPOB = (arr, grNum) => {
  const rightPrGroup = arr?.find((group) => group.productGroup === grNum);
  const suppliedPlaceOfBusinessId = rightPrGroup?.placeOfBusinessId;
  if (suppliedPlaceOfBusinessId) {
    const rightPOB = rightPrGroup?.allPlaceOfBusinesses?.find(
      (pl) => pl.id === suppliedPlaceOfBusinessId
    );
    return rightPOB;
  } else {
    return null;
  }
};

const getPob = (arr, grNum) => (selectedPOBid) => {
  const rightPrGroup = arr?.find((group) => group.productGroup === grNum);
  return rightPrGroup?.allPlaceOfBusinesses?.find((pl) => pl.id === selectedPOBid);
};

const SingleRow = ({ groupName, groupNumber, pob }) => {
  const cutName = cutString(groupName);
  const isCut = cutName.length < groupName.length;
  const [showOption, setShowOption] = useState(false);
  const [field] = useField('initPlacesOfBusiness');
  const [POBfield, __, helpers] = useField('placeOfBusinesses');
  const [selectedPOB, setPOB] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleClick = useCallback(() => {
    if (pob?.length) setShowOption((p) => !p);
    else setShowAlert(true);
  }, [pob?.length]);

  const onCloseAlert = useCallback(() => setShowAlert(false), []);

  useEffect(() => {
    const selPOB = getInitialPOB(field.value, groupNumber);
    if (selPOB?.id) {
      setPOB(selPOB);
    }
  }, [field.value]);

  const pickPOB = (pobId) => {
    const curriedSelectorFunc = getPob(field.value, groupNumber);
    const selectedPOB = curriedSelectorFunc(pobId);
    setPOB(selectedPOB);
  };

  function handleSelect(placeOfBusinessId) {
    const prevVals = POBfield.value.filter((pob) => pob.productGroup !== groupNumber);
    const res = {
      productGroup: groupNumber,
      placeOfBusinessId
    };

    helpers.setValue([...prevVals, res]);
    setShowOption(false);
  }

  return (
    <section className={s.singleRowWrapper}>
      <div className={s.singleRow}>
        <span className={s.groupName}>{`${cutString(groupName)} ${isCut ? '...' : ''}`}</span>
        {selectedPOB?.id ? (
          <SelectedPlace
            kpp={selectedPOB.kpp}
            fiasId={selectedPOB.fiasId}
            vetisGuid={selectedPOB.vetisGuid}
          />
        ) : (
          <span className={s.noModSelected}>{'МОД не выбран для данной ТГ'}</span>
        )}
        <button className={st.addModButton} onClick={handleClick} type="button">
          {showOption ? 'свернуть МОД' : 'посмотреть МОД'}
        </button>
      </div>
      {showOption && (
        <SingleOption allPlacesOfBusinesses={pob} handleSelect={handleSelect} pickPOB={pickPOB} />
      )}
      {showAlert && (
        <Alert
          type="alert"
          title="Детали МОД"
          text={`Список МОД для таварной группы "${groupName}" пуст.`}
          onClose={onCloseAlert}
        />
      )}
    </section>
  );
};

// ***************** PLACES OF BUSINESS START *************************************

const PlacesOfBusiness = () => {
  const [field, _, helpers] = useField('initPlacesOfBusiness');
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    if (field.value?.length) {
      setPlaces(field.value);
    }
  }, [field.value?.length]);

  return (
    <main className={s.componentMainWrapper}>
      <div className={s.fieldTitle}>Места осуществления деятельности для товарных групп</div>
      {places?.length ? (
        <main className={s.placesMainWrapper}>
          {places?.map((pl) => (
            <SingleRow
              groupName={pl.name}
              groupNumber={pl.productGroup}
              pob={pl.allPlaceOfBusinesses}
            />
          ))}
        </main>
      ) : (
        <span className={s.emptyFieldMsg}>Не выбраны подходящие товарные группы</span>
      )}
    </main>
  );
};

export default memo(PlacesOfBusiness);
