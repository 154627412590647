import {
  Checkbox,
  DatePicker,
  DayOfWeek,
  Dropdown,
  MaskedTextField,
  Stack,
  TextField
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import ProductAPI from '../../../api/productsAPI';
import g from '../../../assets/scss/Main.module.scss';
import { Alert } from 'components/Alert/Alert';
import { BottomButtons } from 'components/BottomButtons/BottomButtons';
import { GrayPanel } from 'components/GrayPanel/GrayPanel';
import { Loader } from 'components/Loader/Loader';
import { DayPickerStrings } from '../../../constants';
import { useCrumbsContext } from '../../../context/CrumbsContext';
import {
  convertMillisecondsToHMS,
  formatDate,
  getDateFromTimestamp,
  validateField,
  validateForm
} from '../../../functions';
import { useAlert, useBackBtn, useSendRequest } from '../../../hooks';
import { getPrevRoute, getProductChZnakBalance } from '../../../redux/App/appSelectors';
import { getFirstName, getSurName, getIsAuthLoading } from '../../../redux/Auth/authSelectors';
import {
  servicesProviderRoles,
  releaseMethodTypes,
  cisTypes as CISTypes,
  createMethodTypes
} from '../ProductsCard/AutoCreateOrders/AutoCreateOrders.constants';
import ComboSelect from 'components/Forms/customSelect/comboSelect';
import s from './OrderCode.module.scss';

const serialNumberTypes = [
  // {
  //     key: 'SELF_MADE',
  //     text: 'Автоматически'
  // },
  {
    key: 'OPERATOR',
    text: 'Оператором ЦРПТ'
  }
];

export const OrderCode = () => {
  const [product, setProduct] = useState();
  const [isExpTime, setIsExpTime] = useState(false);
  const [isExpDate, setIsExpDate] = useState(false);
  const [providerServicesForDropdown, setProviderServicesForDropdown] = useState([
    { value: 0, label: 'Не выбрано', role: 0 }
  ]);
  const [errors, setErrors] = useState(new Set([]));
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [ordered, setOrdered] = useState(false);
  const [isDisabledRealese, setIsDisabledRealese] = useState(false);
  const [isICP, setIsICP] = useState(false);
  const firstName = useSelector(getFirstName);
  const surName = useSelector(getSurName);
  const isLoaiding = useSelector(getIsAuthLoading);
  const prevRoute = useSelector(getPrevRoute);
  const params = useLocation().state || JSON.parse(localStorage.getItem('params'));
  const { setCrumbs } = useCrumbsContext();
  const { productId } = useParams();
  let history = useHistory();
  const productChZBalance = useSelector(getProductChZnakBalance)?.currentBalance;
  useBackBtn(prevRoute);

  const { alertTitle, alertText, isAlertShowed, hideAlert, showAlert } = useAlert();
  const { afterSending } = useSendRequest(setLoading);

  const [data, setData] = useState({
    contactPerson: `${firstName} ${surName}`,
    serialNumberType: serialNumberTypes[0].key,
    createMethodType: createMethodTypes[0].key,
    releaseMethodType: releaseMethodTypes[0].key,
    cisType: CISTypes[0].key,
    quantity: '0',
    serviceProviderId: providerServicesForDropdown[0].value,
    expDate: new Date(),
    expTime: '0000',
    gtin: params?.productGtin,
    templateId: params?.templateId,
    role: providerServicesForDropdown[0].role
  });

  useEffect(() => {
    setCrumbs(['/products', 'Заказ кодов маркировки']);
    getTypos(params?.id);
    checkDigitalSignature();
  }, []);

  const getTypos = async (productId) => {
    setLoading(true);
    const res = await ProductAPI.getTypo({ productId });
    const providers = mapServiceProvidersForDropdown(res?.providers) || [];

    setProviderServicesForDropdown((prev) => [...prev, ...providers]);
    const productById = await ProductAPI.getProductById(productId);
    if (productById?.isSet) {
      setData((p) => ({ ...p, cisType: CISTypes[3].key }));
    }
    setLoading(false);
  };

  // useEffect(() => () => localStorage.removeItem('params'), []);

  useEffect(() => {
    if (data.serviceProviderId !== 0) {
      if (isICP) {
        setIsDisabledRealese(true);
      }
    } else {
      setIsDisabledRealese(false);
    }
  }, [data.serviceProviderId]);

  const checkDigitalSignature = async () => {
    const isDS = await ProductAPI.checkDigitalSignature(productId);

    if (isDS?.statusCode === 200) {
      // showAlert(
      //   'ЭЦП не обнаружена',
      //   'Электронно-цифровая подпись не обнаружена, автоматическая подпись заказа недоступна. Для подписи заказа перейдите в СУЗ'
      // );

      showAlert('Ошибка', isDS.message);
      setIsICP(false);
      setIsDisabledRealese(false);
    } else {
      setIsICP(true);
    }
  };

  const orderCodes = async () => {
    data.quantity = Number(data.quantity);
    const onSuccess = (res) => {
      setOrdered(true);
      showAlert(
        'Заказ кодов маркировки',
        `Коды маркировки успешно заказаны и будут готовы через ${convertMillisecondsToHMS(
          res?.expectedCompleteTimestamp
        )}`
      );
    };

    const onError = () => {
      setOrdered(false);
      setLoading(false);
    };

    const isCorrect = validateForm(
      { contactPerson: data.contactPerson, quantity: data.quantity },
      errors,
      setErrors
    );

    if (isCorrect) {
      const transformedData = {
        contactPerson: data.contactPerson,
        createMethodType: data.createMethodType,
        releaseMethodType: data.releaseMethodType,
        serviceProviderId: data.serviceProviderId ? data.serviceProviderId : undefined,
        products: [
          {
            cisType: data.cisType,
            gtin: data.gtin,
            quantity: +data.quantity,
            serialNumberType: data.serialNumberType,
            templateId: data.templateId
          }
        ]
      };

      if (isExpDate) {
        transformedData.products[0].expDate = getDateFromTimestamp(Date.parse(data.expDate), true);
      }

      if (isExpTime) {
        delete transformedData.products[0].expDate;
        transformedData.products[0].expDate72 = `${getDateFromTimestamp(
          Date.parse(data.expDate),
          true
        )}${data.expTime}`;
      }
      setLoading(true);
      const orderRes = await ProductAPI.orderCodes(transformedData);
      setLoading(false);
      afterSending(orderRes, onSuccess, onError);
    }
  };

  const validate = (name, value) => {
    let newErrors = new Set(errors);

    if (name === 'expTime') {
      return value ? value[0] < 24 && value[1].slice(0, 2) < 60 : true;
    }

    if (!validateField(name, value)) {
      newErrors.add(name);
    } else {
      newErrors.delete(name);
    }

    setErrors(newErrors);
  };

  const updateData = (fieldName, value) => {
    const newObj = { ...data };

    if (fieldName === 'serviceProviderId') {
      const provider = providerServicesForDropdown.find((provider) => provider.value === value);
      setData((p) => ({ ...p, role: provider.role, serviceProviderId: provider.value }));
      const role = providerServicesForDropdown.find((prov) => prov.value === value)?.role;
      const servProvederRolesEntr = Object.entries(servicesProviderRoles).find(
        (entry) => entry[1] === role
      );
      setData((p) => ({ ...p, createMethodType: servProvederRolesEntr[0] }));
      return;
    } else if (fieldName === 'quantity') {
      if (/^[0-9]+$/.test(value) || value === '') {
        validate(fieldName, value);
        setData((p) => ({ ...p, quantity: value }));
        return;
      }
    } else {
      newObj[fieldName] = value;
    }
    if (!['expTime', 'quantity'].includes(fieldName)) {
      validate(fieldName, value);
    }
    setData(newObj);
  };

  const mapServiceProvidersForDropdown = (items) => {
    return items?.map((item) => ({
      label: item.name,
      value: item.serviceProviderId,
      role: servicesProviderRoles[item.role]
    }));
  };

  useEffect(() => {
    const newErrors = new Set(errors);
    newErrors.delete('expTime');
    setErrors(newErrors);

    if (!isExpTime) {
      updateData('expTime', null);
    }
  }, [isExpTime]);

  const changeExpTime = (value) => {
    const splittedTime = value.split(':');
    const newErrors = new Set(errors);

    if (splittedTime.length > 1) {
      if (splittedTime[0] < 24 && splittedTime[1].slice(0, 2) < 60) {
        newErrors.delete('expTime');
        updateData('expTime', `${splittedTime[0]}${splittedTime[1].slice(0, 2)}`);
      } else {
        newErrors.add('expTime');
      }
      setErrors(newErrors);
    }
  };

  console.log('PROVIDERS', providerServicesForDropdown);

  return (
    <>
      {loading && <Loader />}
      {!redirect ? (
        <>
          {isAlertShowed && (
            <Alert
              title={alertTitle}
              text={alertText}
              onClose={() => {
                if (ordered) {
                  setRedirect(true);
                }
                hideAlert();
              }}
              btnText="Ок"
            />
          )}
          {params ? (
            <div className={g.contentWrapper}>
              <div className={g.titleWrapper}>
                <h1 className={g.title}>Заказ кодов маркировки</h1>
                <h4 className={s.chestZnBalance}>
                  {!!productChZBalance
                    ? `Баланс: ${productChZBalance} рублей`
                    : 'Не удалось получить баланс из системы Честный знак'}
                </h4>
              </div>
              <GrayPanel title={params.productName}>
                <div>
                  <span className={s.code}>{`GTIN: ${params.productGtin}`}</span>
                  <Stack vertical tokens={{ childrenGap: 25 }}>
                    <div className={s.row}>
                      <TextField
                        label="Контактное лицо"
                        disabled={true}
                        value={data.contactPerson}
                        className={s.rowInput}
                        onChange={(e) => updateData('contactPerson', e.target.value)}
                      />
                      <Dropdown
                        className={s.rowDropdown}
                        label="Генерация серийного номера"
                        disabled={true}
                        selectedKey={data.serialNumberType}
                        options={serialNumberTypes}
                        onChange={(_, item) => updateData('serialNumberType', item.key)}
                      />
                      <Dropdown
                        className={s.rowDropdown}
                        label="Способ производства"
                        disabled={true} // isDisabledRealese
                        selectedKey={data.createMethodType}
                        options={createMethodTypes}
                        onChange={(_, item) => updateData('createMethodType', item.key)}
                      />
                    </div>
                    <div className={s.row}>
                      <TextField
                        key={'Количество КМ'}
                        type="text"
                        label="Количество КМ"
                        value={data.quantity}
                        onChange={(e) => updateData('quantity', e.target.value)}
                        errorMessage={
                          errors.has('quantity') && 'Количество должно быть больше нуля'
                        }
                        className={s.rowInput}
                      />
                      <Dropdown
                        className={s.rowDropdown}
                        disabled
                        label="Способ нанесение кодов"
                        selectedKey={data.cisType}
                        options={CISTypes}
                        onChange={(_, item) => updateData('cisType', item.key)}
                      />

                      {/* 
                      <Dropdown
                        className={s.rowDropdown}
                        label="Сервис-провайдер"
                        selectedKey={data.serviceProviderId}
                        onChange={(_, item) => updateData('serviceProviderId', item.key)}
                        options={providerServicesForDropdown}
                      /> */}

                      <ComboSelect
                        options={providerServicesForDropdown}
                        onChange={(arg) => updateData('serviceProviderId', arg.value)}
                        label="Сервис-провайдер"
                        placeholder={providerServicesForDropdown[0]?.label || ''}
                        customFieldStyles={{ width: '27%' }}
                      />
                    </div>
                    <div className={s.row}>
                      <Dropdown
                        className={s.rowDropdown}
                        disabled
                        label="Способ выпуска товаров в оборот"
                        selectedKey={data.releaseMethodType}
                        options={releaseMethodTypes}
                        onChange={(_, item) => updateData('releaseMethodType', item.key)}
                      />
                    </div>
                    <Checkbox
                      className={s.checkbox}
                      disabled
                      label="Указать срок годности"
                      checked={isExpDate}
                      onChange={() => setIsExpDate((prev) => !prev)}
                    />
                    <DatePicker
                      className={s.date}
                      firstDayOfWeek={DayOfWeek.Monday}
                      label="Дата окончания срока годности"
                      isRequired={false}
                      allowTextInput={true}
                      disabled // !isExpDate
                      value={new Date(data.expDate)}
                      formatDate={(date) => {
                        return (
                          formatDate(date.getDate()) +
                          '.' +
                          formatDate(+date.getMonth() + 1) +
                          '.' +
                          date.getFullYear()
                        );
                      }}
                      onSelectDate={(date) => {
                        updateData('expDate', date, true);
                      }}
                      strings={DayPickerStrings}
                    />
                  </Stack>
                  {isExpDate && (
                    <>
                      <Checkbox
                        className={s.checkbox}
                        label="Дата окончания срока годности продукции (срок хранения менее 72 часов)"
                        checked={isExpTime}
                        onChange={() => setIsExpTime((prev) => !prev)}
                      />
                      <MaskedTextField
                        label="Время"
                        disabled={!isExpTime}
                        className={s.time}
                        placeholder="00:00"
                        value={data.expTime || '0000'}
                        mask="99:99"
                        maskChar=""
                        errorMessage={errors.has('expTime') && 'Неправильный формат времени'}
                        onChange={(e) => changeExpTime(e.target.value)}
                      />
                    </>
                  )}
                </div>
              </GrayPanel>
              <BottomButtons
                okText="Заказать"
                okHandler={orderCodes}
                closeHandler={() => history.goBack()}
                disabledButtons={errors.size && ['Заказать']}
              />
            </div>
          ) : (
            <Redirect to="/products" />
          )}
        </>
      ) : (
        <Redirect
          to={{
            pathname: `/products/orders`,
            params: {
              id: params.id,
              productName: params.productName,
              templateId: params.templateId,
              productGtin: params.productGtin,
              status: 'CREATED,DECLINED,APPROVED,READY,CLOSED,EXPIRED,PENDING,SENDED'
            }
          }}
        />
      )}
    </>
  );
};
