import { memo } from 'react';
import s from './places.module.scss';

const optionFields = {
  kpp: 'Код причины постановки на учет',
  fiasId: 'Код ФИАС',
  vetisGuid: 'Идентификатор ВЕТИС'
};

const SelectedPlace = ({ kpp, fiasId, vetisGuid }) => {
  const props = { kpp, fiasId, vetisGuid };
  const propNames = ['kpp', 'fiasId', 'vetisGuid'];

  return (
    <main className={s.selectedPlaceWrapper}>
      {propNames?.map((name) => {
        return (
          <div className={s.selectedPlaceProp}>
            <span className={s.POBfieldName}>{`${optionFields[name]}:`}</span>
            {props[name] ? <span>{props[name]}</span> : <div className={s.crossLine} />}
          </div>
        );
      })}
    </main>
  );
};

export default memo(SelectedPlace);
