import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { navLinkGroups } from '../components/Menu/navLinkGroups';
import { pagesWithFilters } from '../constants';
import {
  useId,
  Link,
  useToastController,
  Toast,
  ToastTitle,
  ToastBody,
  ToastFooter,
  Toaster,
  makeStyles
} from '@fluentui/react-components';
import ErrorIcon from '../assets/img/ErrorIcon.svg';
import SuccessIcon from '../assets/img/SuccessIcon.svg';

const CrumbsContext = React.createContext();
const useStyles = makeStyles({
  toasterError: {
    backgroundColor: '#EE5F2B',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0px',
    '& div': {
      color: 'white'
    },
    '& div:first-child': {
      height: '50px',
      width: '50px',
      '& svg': {
        width: '100%',
        height: '100%'
      }
    },
    '& img': {
      marginRight: '10px'
    }
  },
  toasterSuccess: {
    backgroundColor: '#107C10',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0px',
    '& div': {
      color: 'white'
    },
    '& div:first-child': {
      height: '50px',
      width: '50px',
      '& svg': {
        height: '100%'
      }
    },
    '& img': {
      marginRight: '10px'
    }
  }
});

export const CrumbsProvider = ({ children }) => {
  const [crumbs, setCrumbsState] = useState([]);
  const [renderedCrumbs, setRenderedCrumbs] = useState([]);
  const [currentRenderedCrumb, setCurrentRenderedCrumb] = useState('');
  const [isFiltersDisplayed, setIsFiltersDisplayed] = useState(false);
  const [pageTitle, setPageTitle] = useState([]);
  const location = useLocation();

  const styles = useStyles();

  const flatArr = (arr, resArr = []) => {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i].links)) {
        flatArr(arr[i].links, resArr);
      }
      resArr.push(arr[i]);
    }
    return resArr;
  };

  const getPageTitle = (pathname) => {
    const flatLinks = flatArr(navLinkGroups[0].links, []);
    const pathParts = pathname.split('/').filter((part) => part);
    let titleParts = [];

    // Special case for reports - always redirect to /reports/printing but show "Отчеты"
    if (pathParts[0] === 'reports') {
      return [{ title: 'Отчеты', path: '/reports/printing' }];
    }

    // Special case for settings - always redirect to /settings/legal but show "Настройки"
    if (pathParts[0] === 'settings') {
      return [{ title: 'Настройки', path: '/settings/legal' }];
    }

    // Handle other paths normally - but skip if we're in reports or settings
    if (pathParts[0] !== 'reports' && pathParts[0] !== 'settings') {
      for (let i = 0; i < pathParts.length; i++) {
        const partialPath = `/${pathParts.slice(0, i + 1).join('/')}`;
        const partialItem = flatLinks.find((item) => item.key === partialPath);
        if (partialItem) {
          titleParts.push({ title: partialItem.name, path: partialPath });
        }
      }

      // Remove duplicate parent element
      if (titleParts.length > 1 && titleParts[0].title === titleParts[1].title) {
        titleParts.shift();
      }
    }

    return titleParts;
  };

  //Toast
  const toasterId = useId('toaster');
  const { dispatchToast } = useToastController(toasterId);
  const [horizontal, setHorizontal] = useState(0);
  const [vertical, setVertical] = useState(35);

  const notify = React.useCallback(
    (options = {}) => {
      const {
        title = 'Уведомление',
        body,
        intent = 'success',
        action,
        position = 'top-end'
      } = options;

      switch (intent) {
        case 'error':
          dispatchToast(
            <Toast className={styles.toasterError}>
              <img src={ErrorIcon} alt="Error" />
              <ToastTitle media={null}>{title}</ToastTitle>
              {body && <ToastBody>{body}</ToastBody>}
              {action && (
                <ToastFooter>
                  <Link onClick={action.onClick}>{action.text}</Link>
                </ToastFooter>
              )}
            </Toast>,
            { intent, position }
          );
          break;
        case 'success':
          dispatchToast(
            <Toast className={styles.toasterSuccess}>
              <img src={SuccessIcon} alt="Success" />
              <ToastTitle media={null}>{title}</ToastTitle>
              {body && <ToastBody>{body}</ToastBody>}
              {action && (
                <ToastFooter>
                  <Link onClick={action.onClick}>{action.text}</Link>
                </ToastFooter>
              )}
            </Toast>,
            { intent, position }
          );
          break;
        case 'info':
        case 'warning':
          dispatchToast(
            <Toast>
              <ToastTitle>Toast intent: {intent}</ToastTitle>
            </Toast>,
            { intent }
          );
          break;
      }
    },
    [dispatchToast]
  );

  const setCrumbs = useCallback((newCrumbs) => {
    setCrumbsState((prevCrumbs) => {
      if (JSON.stringify(prevCrumbs) !== JSON.stringify(newCrumbs)) {
        return newCrumbs;
      }
      return prevCrumbs;
    });
  }, []);

  useEffect(() => {
    const resArr = [];
    const newRenderedCrumbs = [];
    const flatLinks = flatArr(navLinkGroups[0].links, resArr);
    if (crumbs.length > 1) {
      setCurrentRenderedCrumb(crumbs[crumbs.length - 1]);
    } else {
      setCurrentRenderedCrumb([]);
    }

    for (let i = 0; i < flatLinks.length; i++) {
      for (let j = 0; j < crumbs.length; j++) {
        if (flatLinks[i].key === crumbs[j]) {
          newRenderedCrumbs.push(flatLinks[i]);
          break;
        }
      }
    }
    setRenderedCrumbs(newRenderedCrumbs);
  }, [crumbs]);

  useEffect(() => {
    if (pagesWithFilters.includes(location.pathname)) {
      setIsFiltersDisplayed(true);
    } else {
      setIsFiltersDisplayed(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const title = getPageTitle(location.pathname);

    setPageTitle(title);
  }, [location.pathname]);

  return (
    <CrumbsContext.Provider
      value={{
        crumbs,
        setCrumbs,
        renderedCrumbs,
        currentRenderedCrumb,
        isFiltersDisplayed,
        setIsFiltersDisplayed,
        pageTitle,
        notify,
        toasterId
      }}
    >
      <Toaster toasterId={toasterId} offset={{ horizontal, vertical }} timeout={3000} />
      {children}
    </CrumbsContext.Provider>
  );
};

export const useCrumbsContext = () => {
  return useContext(CrumbsContext);
};
