import { useState } from 'react';
import { TableAction } from '../../../components/TableAction/TableAction';
import AddSiteProductModal from './AddModal';
import s from './AddSideProduct.module.scss';
import { addIcon } from '../../../constants';
import { AddRegular } from '@fluentui/react-icons';
import { LinkButton } from 'components/LinkButton/LinkButton';

const AddSiteProductButton = ({ query, getProducts }) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const getAllProducts = async () => getProducts(query);

  return (
    <>
      <div>
        <LinkButton onClick={openModal} iconName={<AddRegular />} text={'Добавить продукт'} />
      </div>

      {showModal && (
        <AddSiteProductModal
          isOpen={showModal}
          onDismiss={closeModal}
          getProducts={getAllProducts}
        />
      )}
    </>
  );
};

export default AddSiteProductButton;
