import { useState, useEffect } from 'react';
import productsAPI from 'api/productsAPI';
import legalAPI from 'api/legalAPI';

const useGetData = (setLoading) => {
  const [companies, setCompanies] = useState([]);
  const [owners, setOwners] = useState([]);

  const getData = async () => {
    if (!companies.length) {
      setLoading(true);
      //let res = await LegalAPI.getCompanies(query);
      let res = await legalAPI.getCompanies2(true, false);

      if (res?.length) {
        const comps = res?.map((com) => ({
          key: com.id,
          text: com.name
        }));
        setCompanies(comps);
        setLoading(false);
      }
    }
    if (!owners.length) {
      const resProdOwn = await productsAPI.getAllProductOwners();
      if (resProdOwn?.length) {
        setLoading(true);
        const owners = resProdOwn?.map((com) => ({
          key: com.id,
          text: com.name
        }));
        setOwners(owners);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [companies, owners]);

  return [companies, owners];
};

export default useGetData;
