import React, { useEffect, useState } from 'react';
import LinesAPI from '../../api/linesAPI';
import g from '../../assets/scss/Main.module.scss';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import { formatLineStatus, cellWidth, mapItemsForDropdown } from '../../functions';
import { usePaginationAndSearch, useDataFilter } from '../../hooks';
import s from './Lines.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import { LinesFilterNew } from 'components/Filters/LinesFilterNew/LinesFilterNew';
import { lineStatuses } from '../../constants';
import productsAPI from 'api/productsAPI';
import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';


const tableHeader = [
  {
    title: 'Название',
    width: '0.3'
  },
  {
    title: 'Статус',
    width: '0.25'
  },
  {
    title: 'Продукт',
    width: '0.3'
  },
  {
    title: 'Действия',
    width: '0.15'
  }
];

export const Lines = () => {
  const [lines, setLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('lines');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [productsForDropdown, setProductsForDropdown] = useState([]);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const getLines = async (query) => {
    setLoading(true);
    let res = await LinesAPI.getLines(query);
    const { list, quantity } = res;
    setLines(list);
    setTotalItems(quantity);
    setLoading(false);
  };

  useEffect(() => {
    const filterData = [
      { label: 'Продукт', key: 'productId', options: productsForDropdown },
      { label: 'Статус', key: 'status', options: lineStatuses },
    ];

    const initialOptions = filterData.reduce((acc, filter) => {
      if (query[filter.key]) {
        if (filter.options) {
          const option = filter.options.find(opt => opt.key === query[filter.key]);
          if (option) {
            acc.push({
              optionValue: { key: option.key, text: option.text, category: filter.key, label: filter.label }
            });
          }
        } else if (filter.getValue && filter.getText) {
          acc.push({
            optionValue: {
              key: filter.getValue(query[filter.key]),
              text: filter.getText(query[filter.key]),
              category: filter.key,
              label: filter.label
            }
          });
        }
      }
      return acc;
    }, []);

    setSelectedOptions(initialOptions);
  }, [query, lineStatuses, productsForDropdown]);

  useEffect(() => {
    productsAPI.getAllProductsNoMarking().then((res) =>
      setProductsForDropdown(mapItemsForDropdown(res))
    );
  }, []);

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getLines, query, setQuery, headerHeight: 170 });

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            styles={searchBoxStyles}
            value={query.searchValue}
            placeholder="Поиск по названию"
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => {
              onSearchTermChanged(newValue);
            }}
          />
        </div>
        <ButtonToggle
          text="Фильтры"
          value={isOpenFilters}
          onClick={() => setIsOpenFilters(!isOpenFilters)}
          optionSize={selectedOptions.length}
        />
      </div>
      <div style={{ margin: '0.75rem 0 0.75rem 1rem' }}>
        <LinkButton
          link={`lines/add`}
          isButton={true}
          text="Добавить линию"
          primary={true}
        />

      </div>
      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <LinesFilterNew
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            query={query}
            setQuery={setQuery}
            lineStatuses={lineStatuses}
            productsForDropdown={productsForDropdown}
          />
        </div>
      )}
      {!lines.length && selectedOptions.length ? (
        <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
      ) : (
        <Table headerItems={tableHeader} maxHeight="67vh" loading={loading}>
          {!!lines?.length &&
            lines?.map((item) => {
              return (
                <div key={item.id} className={s.tableItem}>
                  <div
                    className={g.tableCell}
                    style={{
                      width: cellWidth(tableHeader, 0),
                      justifyContent: 'flex-start',
                      marginLeft: '2rem'
                    }}
                  >
                    <TooltipHost
                      content={`${item?.name} (№${item?.number})`}
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {`${item?.name} (№${item?.number})`}
                      </div>
                    </TooltipHost>
                  </div>
                  <div
                    className={g.tableCell}
                    style={{
                      width: cellWidth(tableHeader, 1),
                      ...formatLineStatus(item.status).style
                    }}
                  >
                    <TooltipHost
                      content={formatLineStatus(item.status).status}
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {formatLineStatus(item.status).status}
                      </div>
                    </TooltipHost>
                  </div>
                  <div className={g.tableCell} style={{ width: cellWidth(tableHeader, 2) }}>
                    <TooltipHost
                      content={item?.selectedProductName}
                      delay={TooltipDelay.medium}
                      directionalHint={DirectionalHint.topCenter}
                    >
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {item?.selectedProductName}
                      </div>
                    </TooltipHost>
                  </div>
                  <div className={s.tableActionCell} style={{ width: cellWidth(tableHeader, 3) }}>
                    {item.status === 'STOP' && (
                      <TableAction
                        link={`/lines/${item.id}`}
                        iconName={moreInfoIcon}
                        text="Подробнее"
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </Table>
      )}
      <div className={g.footer}>
        {totalPages > 1 && (
          <Pagination
            pageCount={totalPages}
            onPageChange={onPageChanged}
            selectedPage={currentPage}
          />
        )}
      </div>

    </div>
  );
};
