import s from './places.module.scss';

const optionFields = [
  { field: 'name', inRussion: 'Название' },
  { field: 'fiasId', inRussion: 'Код ФИАС' },
  { field: 'kpp', inRussion: 'Код причины постановки на учет' },
  { field: 'address', inRussion: 'Адрес места производства' },
  { field: 'vetisGuid', inRussion: 'Идентификатор ВЕТИС' }
];

const SingleOption = ({ allPlacesOfBusinesses, handleSelect, pickPOB }) => {
  const handleClick = (id) => {
    handleSelect(id);
    pickPOB(id);
  };

  return (
    <main
      className={allPlacesOfBusinesses.length > 1 ? s.optionMainWrapper : s.optionMainWrapperOne}
    >
      {allPlacesOfBusinesses?.map((place, ind) => {
        return (
          <section
            className={!!(ind % 2) ? s.singleOption_gray : s.singleOption_blue}
            onClick={() => handleClick(place.id)}
          >
            {optionFields?.map((field) => (
              <div className={s.optionRow}>
                <span className={s.optionFieldName}> {field.inRussion} </span>
                <span className={s.optionFieldValue}> {place[field.field]}</span>
              </div>
            ))}
          </section>
        );
      })}
    </main>
  );
};

export default SingleOption;
