import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import s from '../Alert/Alert.module.scss';

export const ConfirmWComponent = ({
  type = 'success',
  title,
  children,
  onClose,
  btnText = 'Закрыть',
  isOnDismissButton = false,
  setIsShow,
  declineText
}) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: title
  };

  return (
    <div className={s.container}>
      <div className={s.confirm}>
        <Dialog
          hidden={false}
          dialogContentProps={dialogContentProps}
          onDismiss={() => setIsShow(false)}
          maxWidth={'33rem'}
        >
          {children}
          <DialogFooter>
            <PrimaryButton onClick={onClose} text={btnText} />
            {isOnDismissButton && (
              <DefaultButton onClick={() => setIsShow(false)} text={declineText || 'Отмена'} />
            )}
          </DialogFooter>
        </Dialog>
      </div>
    </div>
  );
};

export default ConfirmWComponent;
