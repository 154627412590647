import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/Loader/Loader';
import g from '../../assets/scss/Main.module.scss';
import s from './index.module.scss';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetSuccessCreate } from '../../redux/LegalFaces/legalFacesReducer';
import { PrimaryButton } from '@fluentui/react';
import { Alert } from '../../components/Alert/Alert';
import { useCrumbsContext } from '../../context/CrumbsContext';
import { getSuccessCreate, getCompanyInfo } from '../../redux/LegalFaces/legalFacesSelectors';
import { TextField } from '@fluentui/react';
import productsAPI from 'api/productsAPI';

const initState = {
  name: '',
  inn: ''
};

const AddProductOwner = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { setCrumbs } = useCrumbsContext();
  const [showAlert, setShowAlert] = useState(false);
  const isSuccessCreate = useSelector(getSuccessCreate);
  const companyInfo = useSelector(getCompanyInfo);
  const history = useHistory();
  const [formState, setFormState] = useState(initState);
  const [formErrors, setFormErrors] = useState(initState);

  useEffect(() => {
    if (isSuccessCreate) setShowAlert(true);
  }, [isSuccessCreate]);

  useEffect(() => {
    setCrumbs([
      location.pathname.slice(0, location.pathname.lastIndexOf('/')),
      'Добавить владельца продукта'
    ]);
    validateForm();
  }, []);

  const handleChange = (fName, value) => {
    const writeVal = () => {
      setFormState((p) => ({
        ...p,
        [fName]: value
      }));
    };

    const nulifyError = () => {
      setFormErrors((p) => ({
        ...p,
        [fName]: ''
      }));
    };

    if (fName === 'name') {
      writeVal();
      if (validateName(value)) nulifyError();
    }
    if (fName === 'inn') {
      writeVal();
      if (validateInn(value)) nulifyError();
    }
  };

  const validateInn = (val) => {
    if (val && (val.length === 10 || val.length === 12)) {
      return true;
    }
    setFormErrors((p) => ({
      ...p,
      inn: 'Указан некорректный ИНН, проверьте количество введеных символов и отсутствие букв и знаков препинания'
    }));
    return false;
  };

  const validateName = (val) => {
    if (val.length < 4) {
      setFormErrors((p) => ({
        ...p,
        name: 'Название организации не должно быть короче четырех символов'
      }));
      return false;
    }
    return true;
  };

  const validateForm = () => {
    if (validateName(formState.name) && validateInn(formState.inn)) return true;
    else return false;
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (validateForm()) {
      await productsAPI.addProductOwner(formState);
      setIsLoading(false);
      history.push('/productowners');
    }
    setIsLoading(false);
  };

  const handleCloseSuccess = () => {
    setShowAlert(false);
    dispatch(resetSuccessCreate());
    if (companyInfo.id) history.push(`/legal/${companyInfo.id}`);
  };

  return (
    <div className={g.contentWrapper}>
      {showAlert && (
        <Alert
          title="Добавление владельца продукта"
          text="Владелец продукта сохранен успешно"
          onClose={handleCloseSuccess}
        />
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={g.titleWrapper}>
            <h1 className={g.title}>Добавить владельца продукта</h1>
          </div>
          <section className={s.formContainer}>
            <TextField
              key={'Наименование организации'}
              label="Наименование организации"
              required
              value={formState.name}
              type="text"
              onChange={(e) => handleChange('name', e.target.value)}
              errorMessage={formErrors.name}
            />
            <TextField
              key={'ИНН организации'}
              label="ИНН организации"
              required
              value={formState.inn}
              type="text"
              onChange={(e) => {
                if (!/[^0-9]/.test(e.target.value)) {
                  handleChange('inn', e.target.value);
                }
              }}
              errorMessage={formErrors.inn}
            />
          </section>
          <PrimaryButton
            type="button"
            className={s.btn}
            disabled={formErrors.name || formErrors.inn}
            text="Сохранить"
            onClick={onSubmit}
          />
        </>
      )}
    </div>
  );
};

export default AddProductOwner;
