import React, { useState, useEffect } from 'react';
import g from '../../assets/scss/Main.module.scss';
import { Pagination } from '../../components/Pagination/Pagination';
import { Table } from '../../components/Table/Table';
import { TableAction } from '../../components/TableAction/TableAction';
import { moreInfoIcon, searchBoxStyles } from '../../constants';
import UsersAPI from '../../api/usersAPI';
import { usePaginationAndSearch, useDataFilter } from '../../hooks';
import s from './Users.module.scss';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { SearchBlock } from 'components/SearchBlock/SearchBlock';
import { UsersFilterNew } from 'components/Filters/UsersFilterNew/UsersFilterNew';
import { ButtonToggle } from 'components/ButtonToggle/ButtonToggle';
import { DirectionalHint, TooltipDelay, TooltipHost } from '@fluentui/react';

const tableHeader = [
  { title: 'Логин', width: '0.17' },
  { title: 'ФИО', width: '0.3' },
  { title: 'Роль', width: '0.4' },
  { title: 'Действия', width: '0.13' }
];

export const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useDataFilter('users');
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const getUsers = async (query) => {
    setLoading(true);
    let res = await UsersAPI.getUsers(query);
    const { list, quantity } = res;
    setUsers(list);
    setTotalItems(quantity);
    setLoading(false);
  };

  const { onPageChanged, onSearchTermChanged, totalPages, setTotalItems, currentPage } =
    usePaginationAndSearch({ callback: getUsers, query, setQuery, headerHeight: 10 });

  const getRolesString = (user) => {
    const roles = [];
    user.roles.forEach((role) => roles.push(role.name));
    return roles.join(', ');
  };

  useEffect(() => {
    if (query.roleName) {
      setSelectedOptions([
        { optionValue: { key: query.roleName, text: query.roleName, category: 'roleName', label: 'Роль' } }
      ]);
    } else {
      setSelectedOptions([]);
    }
  }, [query.roleName]);

  return (
    <div className={s.contentWrapper}>
      <div className={g.titleWrapper}>
        <div className={g.searchBlockWrapper}>
          <SearchBlock
            styles={searchBoxStyles}
            placeholder="Поиск по ФИО или логину"
            value={query.searchValue}
            onClear={() => {
              onSearchTermChanged('');
            }}
            onSearch={(newValue) => onSearchTermChanged(newValue)}
          />
        </div>
        <div className={g.headerFilters}>
          <ButtonToggle
            text="Фильтры"
            value={isOpenFilters}
            onClick={() => setIsOpenFilters(!isOpenFilters)}
            optionSize={selectedOptions.length}
          />
        </div>
      </div>

      <div style={{ margin: '0.75rem 0 0.75rem 1rem' }}>
        <LinkButton
          link={`users/add`}
          isButton={true}
          text="Добавить пользователя"
          primary={true}
        />
      </div>

      {isOpenFilters && (
        <div className={g.filtersWrapper}>
          <UsersFilterNew
            query={query}
            setQuery={setQuery}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
      )}
      <div className={s.mainContent}>
        {!users.length && selectedOptions.length ? (
          <div className={g.searchWithFiltersVoid}>Не найдено. Измените параметры фильтрации</div>
        ) : (
          <Table headerItems={tableHeader} maxHeight="67vh" loading={loading}>
            {users?.map((item) => (
              <div key={item.id} className={s.tableItem}>
                <div className={g.tableCell} style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[0].width})` }}>
                  <TooltipHost
                    content={item.userName}
                    delay={TooltipDelay.medium}
                    directionalHint={DirectionalHint.topCenter}
                  >
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {item.userName}
                    </div>
                  </TooltipHost>
                </div>
                <div className={g.tableCell} style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[1].width})` }}>
                  <TooltipHost
                    content={`${item.firstName} ${item.surname} ${item.patronymic}`}
                    delay={TooltipDelay.medium}
                    directionalHint={DirectionalHint.topCenter}
                  >
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {`${item.firstName} ${item.surname} ${item.patronymic}`}
                    </div>
                  </TooltipHost>
                </div>
                <div className={g.tableCell} style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[2].width})` }}>
                  <TooltipHost
                    content={getRolesString(item)}
                    delay={TooltipDelay.medium}
                    directionalHint={DirectionalHint.topCenter}
                  >
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {getRolesString(item)}
                    </div>
                  </TooltipHost>
                </div>
                <div className={s.tableActionCell} style={{ width: `calc((100vw - 180px - 3.75rem) * ${tableHeader[3].width})` }}>
                  <TableAction iconName={moreInfoIcon} text="Подробнее" link={`/users/${item.id}`} />
                </div>
              </div>
            ))}
          </Table>
        )}
        <div className={g.footer}>
          {totalPages > 1 && (
            <Pagination
              pageCount={totalPages}
              onPageChange={onPageChanged}
              selectedPage={currentPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};
