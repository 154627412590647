import { useId } from '@fluentui/react-hooks';
import { TooltipHost } from '@fluentui/react';
import React from 'react';

export const TableItemWithTooltip = ({ styles, tooltipValue, children, display }) => {
  const tooltipId = useId(Date.now().toString());
  const calloutProps = { gapSpace: 0 };
  const hostStyles = {
    root: { ...styles, display: 'flex', flexDirection: 'column', justifyContent: 'center' }
  };

  return (
    <TooltipHost
      content={tooltipValue}
      id={tooltipId}
      calloutProps={calloutProps}
      styles={hostStyles}
    >
      {children}
    </TooltipHost>
  );
};
