import { combineReducers } from 'redux';
import { appReducer } from './App/appReducer';
import { authReducer } from './Auth/authReducer';
import { logsReducer } from './Logs/LogsReducer';
import { printersReducer } from './Printers/PrintersReducer';
import { legalFacesReducer } from './LegalFaces/legalFacesReducer';
import NavReducer from './Nav/NavReducer';
export const rootReducer = combineReducers({
  logs: logsReducer,
  app: appReducer,
  auth: authReducer,
  printers: printersReducer,
  legal: legalFacesReducer,
  nav: NavReducer,
});
